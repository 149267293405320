/* eslint-disable */
/**
 * This is the legacy styling CSS file for Ondernemersplein.overheid.nl
 * ==================================================================
 * If you need to change styles you are probably in the wrong place:
 * any styling should be handled by Styled-components
 *
 * If you need to override any styles it is probably a better idea
 * to simply get rid of the className on your components
 *
 * If you need to change old (tag)-styles, like <h1> it might actually
 * be good to remove it from this file and re-minify this file with
 * > yarn css:minify:op
 * (that will minify this file and places it in pkgs/op/includes)
 * Then update the cache-suffix 'last_updated' on pkgs/op/views/index.ejs 
 *
 * This file is compiled from SASS files. If for some reason you need
 * to view those old files, check out:
 * commit cb0967eb834a557c1b399d3e42d3dbe4137e9db1
 *
 **/
/*!
  *
  The original CSS version of this file is projects/op/src/styles/legacy-styles.css
  *
*/

@layer legacy {
	:where(.legacy) .mainMenu-linkList,
	:where(.legacy) .contentIndex,
	:where(.legacy) .contentIndexInTab {
		padding-left: 0;
		margin-bottom: 0;
		margin-left: 0;
		list-style: none;
	}
	:where(.legacy) .mainMenu-linkList li:before,
	:where(.legacy) .contentIndex li:before,
	:where(.legacy) .contentIndexInTab li:before {
		content: '';
		position: relative;
		display: none;
	}

	@-webkit-keyframes zoomPhoto {
		0% {
			transform: scale(1);
		}
		100% {
			transform: scale(1.1);
		}
	}

	@keyframes zoomPhoto {
		0% {
			transform: scale(1);
		}
		100% {
			transform: scale(1.1);
		}
	}

	@-webkit-keyframes rotate {
		0% {
			transform: rotate(0deg);
		}
		25% {
			transform: rotate(360deg);
		}
		50% {
			transform: rotate(0deg);
		}
	}

	@keyframes rotate {
		0% {
			transform: rotate(0deg);
		}
		25% {
			transform: rotate(360deg);
		}
		50% {
			transform: rotate(0deg);
		}
	}

	:where(.legacy) .theme--blue {
		color: #0077c0;
	}

	:where(.legacy) .theme--aqua {
		color: #00857c;
	}

	:where(.legacy) .theme--grey {
		color: #8f8f8f;
	}

	:where(.legacy) .theme--purple {
		color: #42145f;
	}

	:where(.legacy) .theme--green {
		color: #727800;
	}

	:where(.legacy) .theme--violet {
		color: #a90061;
	}

	:where(.legacy) .theme--orange {
		color: #e27000;
	}

	:where(.legacy) .u-visible-mobile {
		display: none !important;
		/* stylelint-disable-line declaration-no-important */
	}
	@media (max-width: 39.9375em) {
		:where(.legacy) .u-visible-mobile {
			display: block !important;
			/* stylelint-disable-line declaration-no-important */
		}
	}

	:where(.legacy) .u-hidden-mobile {
		display: block !important;
		/* stylelint-disable-line declaration-no-important */
	}
	@media (max-width: 39.9375em) {
		:where(.legacy) .u-hidden-mobile {
			display: none !important;
			/* stylelint-disable-line declaration-no-important */
		}
	}

	:where(.legacy) .u-displayBlock {
		display: block;
	}

	:where(.legacy) .u-noFlex {
		flex: none;
	}

	:where(.legacy) .u-unflex {
		flex: 0 0 auto;
	}

	:where(.legacy) .u-noMarginTop {
		margin-top: 0;
	}

	:where(.legacy) .u-marginTop-1 {
		margin-top: 1rem;
	}

	:where(.legacy) .u-marginTop-2 {
		margin-top: 2rem;
	}

	:where(.legacy) .u-marginBottom-1 {
		margin-bottom: 1rem;
	}

	:where(.legacy) .u-marginBottom-2 {
		margin-bottom: 2rem;
	}

	:where(.legacy) .u-noPadding {
		padding: 0;
	}

	:where(.legacy) .u-noBullets {
		padding-left: 0;
		margin-bottom: 0;
		margin-left: 0;
		list-style: none;
	}

	:where(.legacy) .u-noOutline:focus {
		outline: none;
	}

	:where(.legacy) .bold {
		font-weight: bold;
	}

	:where(.legacy) .italic {
		font-style: italic;
	}

	:where(.legacy) .verticalItemList {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	:where(.legacy) .verticalItem {
		margin: 0.3em 1rem 0.3rem 0;
	}
	:where(.legacy) .verticalItem:last-child {
		margin-right: 0;
	}
	@media (min-width: 40em) {
		:where(.legacy) .verticalItem {
			margin-right: 2rem;
		}
		:where(.legacy) .verticalItem:last-child {
			margin-right: 0;
		}
	}

	/* GRID */
	:where(.legacy) .shortRight {
		padding-right: 0;
		margin-right: 0.2rem;
	}
	@media (min-width: 24em) {
		:where(.legacy) .shortRight {
			margin-right: 3%;
		}
	}
	@media (min-width: 35em) {
		:where(.legacy) .shortRight {
			margin-right: 1.6rem;
		}
	}
	@media (min-width: 50em) {
		:where(.legacy) .shortRight {
			margin-right: 1rem;
		}
	}
	@media (min-width: 57em) {
		:where(.legacy) .shortRight {
			margin-right: 3.2rem;
		}
	}
	@media (min-width: 81em) {
		:where(.legacy) .shortRight {
			margin-right: 4.2rem;
		}
	}

	:where(.legacy) .sublayoutContainer {
		margin-left: -1rem;
		margin-right: -1rem;
	}

	:where(.legacy) .sublayoutContainerSmall {
		margin-left: -0.5rem;
		margin-right: -0.5rem;
	}

	:where(.legacy) .gridContainer {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
	}

	:where(.legacy) .gridContainer--stretchItems {
		align-items: stretch;
	}

	:where(.legacy) .gridContainer--alignItemsToEnd {
		align-items: flex-end;
	}

	:where(.legacy) .gridContainer--alignItemsCenter {
		align-items: center;
	}

	:where(.legacy) .gridContainer--centerItems {
		justify-content: center;
	}

	:where(.legacy) .gridContainer--itemsToEnd {
		justify-content: flex-end;
	}

	:where(.legacy) .gridContainer--spaceBetweenItems {
		justify-content: space-between;
	}

	:where(.legacy) .gridContainer--singleLine {
		flex-wrap: nowrap;
	}

	:where(.legacy) .gridContainer--reverse {
		flex-direction: row-reverse;
		justify-content: flex-end;
	}

	@media (min-width: 50em) {
		:where(.legacy) .gridContainer--reducedWidth {
			width: 85%;
		}
	}

	@media (min-width: 81em) {
		:where(.legacy) .gridContainer--reducedWidth {
			width: 75%;
		}
	}

	:where(.legacy) .gridItem,
	:where(.legacy) .column {
		display: block;
		margin-inline: var(--grid-item-margin-inline);
	}

	:where(.legacy) .pad {
		padding-inline: var(--grid-item-margin-inline);
	}

	:where(.legacy) .padTop {
		padding-top: 0.5rem;
	}

	:where(.legacy) .padBottom {
		padding-bottom: 0.5rem;
	}

	:where(.legacy) .padVertical {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}

	:where(.legacy) .smallMargin {
		margin-top: 1rem;
		margin-bottom: 1rem;
	}

	:where(.legacy) .extraSmallMargin {
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
	}

	:where(.legacy) .smallTopMargin {
		margin-top: 1rem;
	}

	:where(.legacy) .mediumMargin {
		margin-top: 2rem;
		margin-bottom: 2rem;
	}

	:where(.legacy) .bigMargin {
		margin-top: 3rem;
		margin-bottom: 3rem;
	}

	:where(.legacy) .bigBottomMargin {
		margin-bottom: 3rem;
	}

	:where(.legacy) .mediumBottomMargin {
		margin-bottom: 2rem;
	}

	:where(.legacy) .smallBottomMargin {
		margin-bottom: 1rem;
	}

	:where(.legacy) .extraSmallBottomMargin {
		margin-bottom: 0.5rem;
	}

	:where(.legacy) .smallSideMargin {
		margin-left: 0.5rem;
		margin-right: 0.5rem;
	}

	:where(.legacy) .column {
		flex: 0 1 auto;
	}

	:where(.legacy) .column--fit {
		flex: 1 1 auto;
	}

	:where(.legacy) .column--full {
		width: calc(100% - 2 * var(--grid-item-margin-inline) - 0.01rem);
	}

	:where(.legacy) .column--half {
		width: calc(100% - 2 * var(--grid-item-margin-inline) - 0.01rem);
	}
	@media (min-width: 40em) {
		:where(.legacy) .column--half {
			width: calc(50% - 2 * var(--grid-item-margin-inline) - 0.01rem);
		}
	}

	:where(.legacy) .column--quarter {
		width: calc(100% - 2 * var(--grid-item-margin-inline) - 0.01rem);
	}
	@media (min-width: 40em) {
		:where(.legacy) .column--quarter {
			width: calc(33.33333% - 2 * var(--grid-item-margin-inline) - 0.01rem);
		}
	}
	@media (min-width: 50em) {
		:where(.legacy) .column--quarter {
			width: calc(25% - 2 * var(--grid-item-margin-inline) - 0.01rem);
		}
	}

	:where(.legacy) .column--threeQuarter {
		width: calc(100% - 2 * var(--grid-item-margin-inline) - 0.01rem);
	}
	@media (min-width: 40em) {
		:where(.legacy) .column--threeQuarter {
			width: calc(66.66667% - 2 * var(--grid-item-margin-inline) - 0.01rem);
		}
	}
	@media (min-width: 50em) {
		:where(.legacy) .column--threeQuarter {
			width: calc(75% - 2 * var(--grid-item-margin-inline) - 0.01rem);
		}
	}

	:where(.legacy) .column--third {
		width: calc(100% - 2 * var(--grid-item-margin-inline) - 0.01rem);
	}
	@media (min-width: 40em) {
		:where(.legacy) .column--third {
			width: calc(50% - 2 * var(--grid-item-margin-inline) - 0.01rem);
		}
	}
	@media (min-width: 70em) {
		:where(.legacy) .column--third {
			width: calc(33.33333% - 2 * var(--grid-item-margin-inline) - 0.01rem);
		}
	}

	:where(.legacy) .column--fullThird {
		width: calc(100% - 2 * var(--grid-item-margin-inline) - 0.01rem);
	}
	@media (min-width: 70em) {
		:where(.legacy) .column--fullThird {
			width: calc(33.33333% - 2 * var(--grid-item-margin-inline) - 0.01rem);
		}
	}

	:where(.legacy) .column--twoThird {
		width: calc(100% - 2 * var(--grid-item-margin-inline) - 0.01rem);
	}
	@media (min-width: 70em) {
		:where(.legacy) .column--twoThird {
			width: calc(66.66667% - 2 * var(--grid-item-margin-inline) - 0.01rem);
		}
	}

	@media (max-width: 39.9375em) {
		:where(.legacy) .forceFilledGridLine:nth-child(n + 4) {
			display: none;
		}
	}

	@media (min-width: 70em) {
		:where(.legacy) .forceFilledGridLine:nth-child(n + 4) {
			display: none;
		}
	}

	:where(.legacy) .list {
		-moz-column-gap: 2rem;
		column-gap: 2rem;
	}
	@media (min-width: 35em) {
		:where(.legacy) .list--double {
			-moz-column-count: 2;
			column-count: 2;
		}
	}
	@media (min-width: 40em) {
		:where(.legacy) .list--doubleWhenMedium {
			-moz-column-count: 2;
			column-count: 2;
		}
	}
	@media (min-width: 50em) {
		:where(.legacy) .list--doubleWhenMedium {
			-moz-column-count: 1;
			column-count: 1;
		}
	}
	@media (min-width: 40em) {
		:where(.legacy) .list--triple {
			-moz-column-count: 2;
			column-count: 2;
		}
	}
	@media (min-width: 50em) {
		:where(.legacy) .list--triple {
			-moz-column-count: 3;
			column-count: 3;
		}
	}
	@media (min-width: 35em) {
		:where(.legacy) .list--quadruple {
			-moz-column-count: 2;
			column-count: 2;
		}
	}
	@media (min-width: 40em) {
		:where(.legacy) .list--quadruple {
			-moz-column-count: 3;
			column-count: 3;
		}
	}
	@media (min-width: 50em) {
		:where(.legacy) .list--quadruple {
			-moz-column-count: 4;
			column-count: 4;
		}
	}
	:where(.legacy) .list-spacedItem {
		-moz-column-break-inside: avoid;
		break-inside: avoid;
		margin-left: 1rem;
		margin-right: 1rem;
	}
	:where(.legacy) .list-item {
		-moz-column-break-inside: avoid;
		break-inside: avoid;
	}

	:where(.legacy) .singleLineList {
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
	}
	:where(.legacy) .singleLineList > div {
		margin-right: 1.5rem;
	}

	:where(.legacy) .multiLineList {
		display: flex;
		flex-wrap: wrap;
	}

	/* Z-INDEX */
	/*------------------------------------*\
	$MAIN CONTENT AREA
\*------------------------------------*/
	.legacy {
		line-height: 1.6;
		color: #333333;
	}

	:where(.legacy) .hide-svg {
		position: absolute;
		left: -999em;
		top: 0;
		height: 1px;
		overflow: hidden;
	}
	:where(.legacy) .hide-svg:focus {
		left: 1em;
		height: auto;
	}

	:where(.legacy) svg {
		fill: currentColor;
	}

	/*------------------------------------*\
	$GLOBAL CLASSES
\*------------------------------------*/
	/* Completely remove from the flow but leave available to screen readers. */
	:where(.legacy) .is-vishidden {
		position: absolute !important;
		/* stylelint-disable-line declaration-no-important */
		overflow: hidden;
		width: 1px;
		height: 1px;
		padding: 0;
		border: 0;
		clip: rect(1px, 1px, 1px, 1px);
	}

	/* Text-Align */
	:where(.legacy) .alignRight {
		text-align: right;
	}

	:where(.legacy) .alignCenter {
		text-align: center;
	}

	:where(.legacy) .alignLeft {
		text-align: left;
	}

	:where(.legacy) .smallFont {
		font-size: 90%;
	}

	:where(.legacy) .smallerFont {
		font-size: 80%;
	}

	:where(.legacy) .valid {
		color: #727800;
	}

	:where(.legacy) .error {
		color: #a90061;
	}

	:where(.legacy) .attention {
		color: #d52b1e;
	}

	:where(.legacy) .goIcon {
		display: inline-block;
		height: 1.6rem;
		width: 1.2rem;
		flex-shrink: 0;
	}
	:where(.legacy) .goIcon.sameSizeAsText {
		height: 1em;
	}

	:where(.legacy) .goIconSimple {
		display: inline-block;
		height: 1.6rem;
		width: 0.9rem;
	}

	:where(.legacy) .externalLinkIcon {
		height: 1rem;
	}
	:where(.legacy) .listBlock-list .externalLinkIcon {
		margin: 0 !important;
		/* stylelint-disable-line declaration-no-important */
	}

	/* LINKS */
	:where(.legacy) a {
		color: inherit;
		text-decoration: none;
		border-bottom: 2px solid;
		transition-property: color, fill;
		transition-duration: 400ms;
	}
	:where(.legacy) a:hover,
	:where(.legacy) a:focus,
	:where(.legacy) a:active {
		color: #0670b2;
		fill: #0670b2;
	}

	:where(.legacy) h2 a,
	:where(.legacy) h3 a,
	:where(.legacy) h4 a,
	:where(.legacy) h5 a,
	:where(.legacy) h6 a {
		text-decoration: none;
		border-bottom: none;
	}
	:where(.legacy) h2 a:hover,
	:where(.legacy) h2 a:focus,
	:where(.legacy) h2 a:active,
	:where(.legacy) h3 a:hover,
	:where(.legacy) h3 a:focus,
	:where(.legacy) h3 a:active,
	:where(.legacy) h4 a:hover,
	:where(.legacy) h4 a:focus,
	:where(.legacy) h4 a:active,
	:where(.legacy) h5 a:hover,
	:where(.legacy) h5 a:focus,
	:where(.legacy) h5 a:active,
	:where(.legacy) h6 a:hover,
	:where(.legacy) h6 a:focus,
	:where(.legacy) h6 a:active {
		text-decoration: none;
		border-bottom: 2px solid;
	}

	:where(.legacy) .interfaceLink {
		background: none;
		border: none;
		padding: 0;
	}
	:where(.legacy) .interfaceLink.interfaceLink--blue {
		color: #0077c0;
		fill: #0077c0;
	}
	:where(.legacy) .interfaceLink.interfaceLink--aqua {
		color: #00857c;
		fill: #00857c;
	}
	:where(.legacy) .interfaceLink.interfaceLink--grey {
		color: #8f8f8f;
		fill: #8f8f8f;
	}
	:where(.legacy) .interfaceLink.interfaceLink--purple {
		color: #42145f;
		fill: #42145f;
	}
	:where(.legacy) .interfaceLink.interfaceLink--green {
		color: #727800;
		fill: #727800;
	}
	:where(.legacy) .interfaceLink.interfaceLink--violet {
		color: #a90061;
		fill: #a90061;
	}
	:where(.legacy) .interfaceLink.interfaceLink--orange {
		color: #e27000;
		fill: #e27000;
	}
	:where(.legacy) .interfaceLink,
	:where(.legacy) .interfaceLinkStatic {
		text-decoration: none;
		border-bottom: none;
		color: #0670b2;
		fill: #0670b2;
		display: inline-flex;
		align-items: flex-start;
	}
	:where(.legacy) .interfaceLink,
	:where(.legacy) .interfaceLinkStatic {
		line-height: 1.6;
	}
	:where(.legacy) .interfaceLink--noFlex {
		display: inline-block;
	}
	:where(.legacy) .interfaceLink--block {
		display: block;
	}
	:where(.legacy) .interfaceLink--more {
		font-weight: bold;
	}
	:where(.legacy) .interfaceLink--white {
		color: #ffffff;
		fill: #ffffff;
	}
	:where(.legacy) .interfaceLink--asText {
		color: #333333;
	}
	:where(.legacy) .interfaceLink--add {
		margin-top: 0.75rem;
		font-weight: bold;
	}
	:where(.legacy) .interfaceLink--remove {
		margin-bottom: 1.2rem;
		font-weight: bold;
	}
	:where(.legacy) .interfaceLink-icon {
		width: 1rem;
		flex: 0 0 auto;
		margin-right: 0.9em;
	}
	:where(.legacy) .interfaceLink-icon.interfaceLink-icon--blue {
		fill: #0077c0;
	}
	:where(.legacy) .interfaceLink-icon.interfaceLink-icon--aqua {
		fill: #00857c;
	}
	:where(.legacy) .interfaceLink-icon.interfaceLink-icon--grey {
		fill: #8f8f8f;
	}
	:where(.legacy) .interfaceLink-icon.interfaceLink-icon--purple {
		fill: #42145f;
	}
	:where(.legacy) .interfaceLink-icon.interfaceLink-icon--green {
		fill: #727800;
	}
	:where(.legacy) .interfaceLink-icon.interfaceLink-icon--violet {
		fill: #a90061;
	}
	:where(.legacy) .interfaceLink-icon.interfaceLink-icon--orange {
		fill: #e27000;
	}
	:where(.legacy) .interfaceLink-icon--dark {
		fill: #033054;
	}
	:where(.legacy) .interfaceLink-text {
		margin: 0;
		flex: 1 1 auto;
		color: inherit;
		line-height: inherit;
	}
	:where(.legacy) .interfaceLink-text {
		font-size: inherit;
	}
	:where(.legacy) .interfaceLink .underliner {
		text-decoration: none;
		border-bottom: 2px solid transparent;
		transition: border-bottom-color 200ms;
	}
	:where(.legacy) .interfaceLink:hover .underliner,
	:where(.legacy) .interfaceLink:focus .underliner,
	:where(.legacy) .interfaceLink:active .underliner {
		text-decoration: none;
		border-bottom: 2px solid;
	}

	:where(.legacy) .headingLink {
		width: 100%;
		text-decoration: none;
		border-bottom: none;
		color: #033054;
		fill: #033054;
		display: inline-flex;
		align-items: center;
		position: relative;
		font-weight: bold;
	}
	:where(.legacy) .headingLink {
		line-height: 1.2;
	}
	:where(.legacy) .headingLink.headingLink--blue {
		fill: #0670b2;
	}
	:where(.legacy) .headingLink.headingLink--aqua {
		fill: #00857c;
	}
	:where(.legacy) .headingLink.headingLink--grey {
		fill: #8f8f8f;
	}
	:where(.legacy) .headingLink.headingLink--purple {
		fill: #310d47;
	}
	:where(.legacy) .headingLink.headingLink--green {
		fill: #686b00;
	}
	:where(.legacy) .headingLink.headingLink--violet {
		fill: #a90061;
	}
	:where(.legacy) .headingLink.headingLink--orange {
		fill: #e27000;
	}
	:where(.legacy) .headingLink .underliner {
		text-decoration: none;
		border-bottom: 2px solid transparent;
		transition: border-bottom-color 200ms;
	}
	:where(.legacy) .headingLink:hover,
	:where(.legacy) .headingLink:focus,
	:where(.legacy) .headingLink:active {
		text-decoration: none;
		border-bottom: none;
	}
	:where(.legacy) .headingLink:hover .underliner,
	:where(.legacy) .headingLink:focus .underliner,
	:where(.legacy) .headingLink:active .underliner {
		text-decoration: none;
		border-bottom: 2px solid;
	}
	:where(.legacy) .headingLink-bigIcon {
		width: 2em;
		height: 2em;
		margin-right: 0.5em;
		flex: 0 0 auto;
		vertical-align: middle;
	}
	:where(.legacy) .headingLink-icon {
		flex: 0 0 auto;
		height: 1.2em;
		vertical-align: middle;
		margin-left: 0.3rem;
	}
	:where(.legacy) .headingLink-leadingIcon {
		flex: 0 0 auto;
		height: 1.2em;
		margin-right: 0.3em;
		vertical-align: middle;
	}
	:where(.legacy) .headingLink-text {
		flex: 0 1 auto;
	}
	:where(.legacy) .headingLink .goIcon {
		position: relative;
		top: -1px;
	}

	:where(.legacy) .homeBlock-icon {
		width: 1.6em;
		height: 1.6em;
		margin-right: 10px;
	}
	:where(.legacy) .homeBlockMore--blue .homeBlock-icon {
		fill: #0077c0;
	}
	:where(.legacy) .homeBlockMore--aqua .homeBlock-icon {
		fill: #00857c;
	}
	:where(.legacy) .homeBlockMore--grey .homeBlock-icon {
		fill: #8f8f8f;
	}
	:where(.legacy) .homeBlockMore--purple .homeBlock-icon {
		fill: #42145f;
	}
	:where(.legacy) .homeBlockMore--green .homeBlock-icon {
		fill: #727800;
	}
	:where(.legacy) .homeBlockMore--violet .homeBlock-icon {
		fill: #a90061;
	}
	:where(.legacy) .homeBlockMore--orange .homeBlock-icon {
		fill: #e27000;
	}

	:where(.legacy) .externalLinkIcon {
		height: 0.9rem;
		width: 0.9rem;
		display: inline-block;
		vertical-align: top;
	}

	:where(.legacy) .linkAnkeiler {
		color: #333333;
	}
	:where(.legacy) .linkAnkeiler:hover,
	:where(.legacy) .linkAnkeiler:focus,
	:where(.legacy) .linkAnkeiler:active {
		color: inherit;
	}

	:where(.legacy) .linkBox {
		margin-bottom: 15px;
		padding: 10px;
		border: 1px solid #d9d9d9;
	}
	:where(.legacy) .linkBox {
		font-size: 1.1em;
	}
	@media (min-width: 57em) {
		:where(.legacy) .linkBox {
			width: 50%;
		}
	}
	:where(.legacy) .linkBox a {
		width: 100%;
		color: #000000;
	}
	:where(.legacy) .linkBox p {
		margin: 0;
	}
	:where(.legacy) .linkBox .goIcon {
		float: right;
		fill: #000000;
	}

	:where(.legacy) .blockLink .underliner,
	:where(.legacy) .link .underliner {
		text-decoration: none;
		border-bottom: 2px solid transparent;
	}

	:where(.legacy) .blockLink .linkArrow,
	:where(.legacy) .link .linkArrow {
		transition: transform 0.2s ease-in-out;
	}

	:where(.legacy) .blockLink:hover,
	:where(.legacy) .blockLink:focus,
	:where(.legacy) .blockLink:active,
	:where(.legacy) .link:hover,
	:where(.legacy) .link:focus,
	:where(.legacy) .link:active {
		border-color: inherit;
	}
	:where(.legacy) .blockLink:hover .underliner,
	:where(.legacy) .blockLink:focus .underliner,
	:where(.legacy) .blockLink:active .underliner,
	:where(.legacy) .link:hover .underliner,
	:where(.legacy) .link:focus .underliner,
	:where(.legacy) .link:active .underliner {
		text-decoration: none;
		border-bottom: 2px solid;
	}
	:where(.legacy) .blockLink:hover .linkArrow,
	:where(.legacy) .blockLink:focus .linkArrow,
	:where(.legacy) .blockLink:active .linkArrow,
	:where(.legacy) .link:hover .linkArrow,
	:where(.legacy) .link:focus .linkArrow,
	:where(.legacy) .link:active .linkArrow {
		transform: translateX(2px);
	}
	:where(.legacy) .blockLink:hover .hoverColor,
	:where(.legacy) .blockLink:focus .hoverColor,
	:where(.legacy) .blockLink:active .hoverColor,
	:where(.legacy) .link:hover .hoverColor,
	:where(.legacy) .link:focus .hoverColor,
	:where(.legacy) .link:active .hoverColor {
		color: inherit;
		fill: inherit;
	}
	:where(.legacy) .blockLink:hover .hoverIconBgColor,
	:where(.legacy) .blockLink:focus .hoverIconBgColor,
	:where(.legacy) .blockLink:active .hoverIconBgColor,
	:where(.legacy) .link:hover .hoverIconBgColor,
	:where(.legacy) .link:focus .hoverIconBgColor,
	:where(.legacy) .link:active .hoverIconBgColor {
		background-color: #0077c0;
		transition: background-color 0.25s ease-in-out;
	}

	:where(.legacy) .blockLink {
		display: block;
		border: none;
	}
	:where(.legacy) .blockLink:hover .headingEmphasized,
	:where(.legacy) .blockLink:focus .headingEmphasized,
	:where(.legacy) .blockLink:active .headingEmphasized {
		color: #033054;
		fill: #033054;
	}
	:where(.legacy) .blockLink:hover .promoBlock-figure:after,
	:where(.legacy) .blockLink:focus .promoBlock-figure:after,
	:where(.legacy) .blockLink:active .promoBlock-figure:after {
		width: 100%;
		left: 0;
	}

	:where(.legacy) .underlineLink .underliner {
		text-decoration: none;
		border-bottom: 2px solid transparent;
	}

	:where(.legacy) .underlineLink:hover .underliner,
	:where(.legacy) .underlineLink:focus .underliner,
	:where(.legacy) .underlineLink:active .underliner {
		text-decoration: none;
		border-bottom: 2px solid;
	}

	/* LISTS */

	:where(.legacy) ul,
	:where(.legacy) ol {
		list-style: revert;
		margin-top: 0;
		margin-bottom: 1rem;
		padding-left: 1.1rem;
	}
	:where(ul, ol) :is(ul, ol) {
		margin-bottom: 0;
	}

	:where(.legacy) ::marker {
		font-weight: bold;
		color: #033054;
	}

	:where(.legacy) li {
		margin: 0;
		padding-top: 0.3em;
		padding-bottom: 0.3em;
	}

	:where(.legacy) #article ol > li {
		list-style-type: auto;
	}

	:where(.legacy) #article ul > li {
		list-style-type: disc;
	}

	:where(.legacy) dt {
		font-weight: bold;
	}

	:where(.legacy) dd {
		margin-left: 0;
		margin-bottom: 0.3em;
	}

	/* Headings */
	/* stylelint-disable selector-no-qualifying-type */
	:where(.legacy) h1,
	:where(.legacy) .h1,
	:where(.legacy) h2,
	:where(.legacy) .h2,
	:where(.legacy) .menuBar--title,
	:where(.legacy) .search-header-title,
	:where(.legacy) h3,
	:where(.legacy) .h3,
	:where(.legacy) h4,
	:where(.legacy) .h4,
	:where(.legacy) h5,
	:where(.legacy) .h5,
	:where(.legacy) h6,
	:where(.legacy) .h6 {
		color: #033054;
		fill: #033054;
		font-weight: bold;
	}
	:where(.legacy) h1,
	:where(.legacy) .h1,
	:where(.legacy) h2,
	:where(.legacy) .h2,
	:where(.legacy) .menuBar--title,
	:where(.legacy) .search-header-title,
	:where(.legacy) h3,
	:where(.legacy) .h3,
	:where(.legacy) h4,
	:where(.legacy) .h4,
	:where(.legacy) h5,
	:where(.legacy) .h5,
	:where(.legacy) h6,
	:where(.legacy) .h6 {
		line-height: 1.2;
	}

	:where(.legacy) h1,
	:where(.legacy) .h1 {
		color: #0077c0;
		fill: #0077c0;
		margin-top: 0;
		margin-bottom: 0.3em;
	}
	:where(.legacy) h1,
	:where(.legacy) .h1 {
		font-size: 1.7rem;
	}
	@media (min-width: 35em) {
		:where(.legacy) h1,
		:where(.legacy) .h1 {
			font-size: 1.9rem;
		}
	}
	@media (min-width: 55em) {
		:where(.legacy) h1,
		:where(.legacy) .h1 {
			font-size: 2.2rem;
		}
	}
	@media (max-width: 39.9375em) {
		:where(.legacy) h1,
		:where(.legacy) .h1 {
			-webkit-hyphens: auto;
			-ms-hyphens: auto;
			hyphens: auto;
		}
	}

	:where(.legacy) h2,
	:where(.legacy) .h2,
	:where(.legacy) .menuBar--title,
	:where(.legacy) .search-header-title {
		margin-top: 2em;
		margin-bottom: 0.4em;
	}
	:where(.legacy) h2,
	:where(.legacy) .h2,
	:where(.legacy) .menuBar--title,
	:where(.legacy) .search-header-title {
		font-size: 1.3rem;
	}
	@media (min-width: 35em) {
		:where(.legacy) h2,
		:where(.legacy) .h2,
		:where(.legacy) .menuBar--title,
		:where(.legacy) .search-header-title {
			font-size: 1.4rem;
		}
	}
	@media (min-width: 55em) {
		:where(.legacy) h2,
		:where(.legacy) .h2,
		:where(.legacy) .menuBar--title,
		:where(.legacy) .search-header-title {
			font-size: 1.5rem;
		}
	}
	:where(.legacy) h2.block-title,
	:where(.legacy) .h2.block-title,
	:where(.legacy) .block-title.menuBar--title,
	:where(.legacy) .block-title.search-header-title {
		margin-top: 0;
	}

	:where(.legacy) h3,
	:where(.legacy) .h3 {
		margin-top: 2em;
		margin-bottom: 0.4em;
	}
	:where(.legacy) h3,
	:where(.legacy) .h3 {
		font-size: 1.1rem;
	}
	:where(.legacy) h3.block-title,
	:where(.legacy) .h3.block-title {
		margin-top: 0;
	}

	:where(.legacy) h4,
	:where(.legacy) .h4,
	:where(.legacy) h5,
	:where(.legacy) .h5,
	:where(.legacy) h6,
	:where(.legacy) .h6 {
		margin-top: 2em;
		margin-bottom: 0.2em;
	}
	:where(.legacy) h4,
	:where(.legacy) .h4,
	:where(.legacy) h5,
	:where(.legacy) .h5,
	:where(.legacy) h6,
	:where(.legacy) .h6 {
		font-size: 1rem;
	}
	:where(.legacy) h4.block-title,
	:where(.legacy) .h4.block-title,
	:where(.legacy) h5.block-title,
	:where(.legacy) .h5.block-title,
	:where(.legacy) h6.block-title,
	:where(.legacy) .h6.block-title {
		margin-top: 0;
	}

	:where(.legacy) .heading.heading--blue {
		color: #0077c0;
	}

	:where(.legacy) .heading.heading--aqua {
		color: #00857c;
	}

	:where(.legacy) .heading.heading--grey {
		color: #8f8f8f;
	}

	:where(.legacy) .heading.heading--purple {
		color: #42145f;
	}

	:where(.legacy) .heading.heading--green {
		color: #727800;
	}

	:where(.legacy) .heading.heading--violet {
		color: #a90061;
	}

	:where(.legacy) .heading.heading--orange {
		color: #e27000;
	}

	:where(.legacy) .headingNoMargin {
		margin: 0;
	}

	:where(.legacy) .sideHeading {
		color: #333333;
		fill: #333333;
		margin-top: 0.5rem;
	}

	:where(.legacy) .headingPrefix {
		font-weight: normal;
	}
	:where(.legacy) .headingPrefix {
		font-size: 0.75em;
	}

	:where(.legacy) .headingEmphasized {
		color: #0077c0;
		fill: #0077c0;
	}

	:where(.legacy) h1.headingBlack,
	:where(.legacy) h2.headingBlack,
	:where(.legacy) .headingBlack {
		color: #000000;
		font-size: 1.2rem;
		margin-bottom: 1rem;
	}

	/*------------------------------------*\
	BUTTONS
\*------------------------------------*/
	:where(.legacy) .btn {
		padding: 0.75rem;
		display: inline-block;
		background-color: #0077c0;
		border: 0;
		text-align: center;
		transition: background-color 0.3s ease-in-out;
	}
	:where(.legacy) .btn {
		line-height: 1;
	}
	:where(.legacy) .btn.btn--blue {
		background-color: #0077c0;
	}
	:where(.legacy) .btn.btn--aqua {
		background-color: #00857c;
	}
	:where(.legacy) .btn.btn--grey {
		background-color: #8f8f8f;
	}
	:where(.legacy) .btn.btn--purple {
		background-color: #42145f;
	}
	:where(.legacy) .btn.btn--green {
		background-color: #727800;
	}
	:where(.legacy) .btn.btn--violet {
		background-color: #a90061;
	}
	:where(.legacy) .btn.btn--orange {
		background-color: #e27000;
	}
	:where(.legacy) .btn:hover,
	:where(.legacy) .btn:focus {
		background-color: #033054;
		color: #ffffff;
		fill: #ffffff;
	}
	:where(.legacy) .btn:hover.btn--blue,
	:where(.legacy) .btn:focus.btn--blue {
		background-color: #0670b2;
	}
	:where(.legacy) .btn:hover.btn--aqua,
	:where(.legacy) .btn:focus.btn--aqua {
		background-color: #00857c;
	}
	:where(.legacy) .btn:hover.btn--grey,
	:where(.legacy) .btn:focus.btn--grey {
		background-color: #8f8f8f;
	}
	:where(.legacy) .btn:hover.btn--purple,
	:where(.legacy) .btn:focus.btn--purple {
		background-color: #310d47;
	}
	:where(.legacy) .btn:hover.btn--green,
	:where(.legacy) .btn:focus.btn--green {
		background-color: #686b00;
	}
	:where(.legacy) .btn:hover.btn--violet,
	:where(.legacy) .btn:focus.btn--violet {
		background-color: #a90061;
	}
	:where(.legacy) .btn:hover.btn--orange,
	:where(.legacy) .btn:focus.btn--orange {
		background-color: #e27000;
	}
	:where(.legacy) .btn.disabled {
		background: #dddddd;
		color: #333333;
	}
	:where(.legacy) .btn--uc {
		text-transform: uppercase;
	}
	:where(.legacy) .btn--marginRight {
		margin-right: 0.5rem;
	}
	:where(.legacy) .btn--green {
		background-color: #39870c;
	}
	:where(.legacy) .btn--green:hover,
	:where(.legacy) .btn--green:focus {
		background: #2f700a;
	}

	:where(.legacy) .btn,
	:where(.legacy) article .btn.btn.btn {
		/* prevent override by `article a:not(.interfaceLink)` of default button color  */
		color: #ffffff;
		fill: #ffffff;
	}

	:where(.legacy) .btn-large {
		padding: 0.5rem;
		text-transform: uppercase;
		background: #0077c0;
		font-weight: normal;
	}
	:where(.legacy) .btn-large {
		font-size: 1.4rem;
	}

	:where(.legacy) .btn-asLink {
		background-color: transparent;
		border: none;
		padding: 0;
		color: #0077c0;
		text-transform: none;
	}
	:where(.legacy) .btn-asLink > span {
		border-bottom: 1px solid transparent;
		transition: 0.3s all ease-in-out;
	}
	:where(.legacy) .btn-asLink:hover,
	:where(.legacy) .btn-asLink:focus,
	:where(.legacy) .btn-asLink:active {
		background-color: transparent;
		color: #0077c0;
	}
	:where(.legacy) .btn-asLink:hover > span,
	:where(.legacy) .btn-asLink:focus > span,
	:where(.legacy) .btn-asLink:active > span {
		border-bottom-color: #0077c0;
	}
	:where(.legacy) .btn-asLink svg {
		display: inline-block;
		fill: #0077c0;
		position: relative;
		top: 3px;
	}

	:where(.legacy) .text-btn {
		font-style: italic;
	}

	:where(.legacy) .cleanButton {
		background: none;
		border: none;
		padding: 0;
		color: inherit;
		font-weight: inherit;
		line-height: inherit;
		text-align: left;
		text-transform: none;
	}

	:where(.legacy) html {
		font-size: 100%;
	}
	@media (min-width: 35em) {
		html {
			font-size: 106.25%;
		}
	}
	@media (min-width: 55em) {
		html {
			font-size: 112.5%;
		}
	}
	@media (min-width: 81em) {
		html {
			font-size: 125%;
		}
	}

	/* Text-Related Elements */
	:where(.legacy) p {
		margin-bottom: 1em;
	}

	/* Blockquote (is gelijk aan _tip.scss) */
	:where(.legacy) blockquote {
		font-style: italic;
		border-left: 3px solid #8f8f8f;
		color: #8f8f8f;
		padding-left: 1em;
		margin: 1em 0;
	}

	/* Horizontal Rule */
	:where(.legacy) hr {
		border: 0;
		height: 2px;
		background: #dddddd;
		margin: 1rem 0;
	}

	:where(.legacy) abbr {
		border-bottom: 1px dotted #8f8f8f;
		cursor: help;
	}

	:where(.legacy) .intro {
		display: block;
		clear: both;
		color: #033054;
	}
	:where(.legacy) .intro {
		font-size: 1.2rem;
	}
	:where(.legacy) .intro--alone {
		margin-bottom: 0;
	}
	:where(.legacy) .intro {
		font-style: italic;
	}

	:where(.legacy) .infoText {
		margin-bottom: 0;
		color: #696969;
		font-style: italic;
	}
	:where(.legacy) .infoText {
		font-size: 0.875rem;
	}

	:where(.legacy) .borderlineText {
		margin-top: 1rem;
		margin-bottom: 1rem;
		padding-top: 1rem;
		padding-bottom: 1rem;
		border-top: 1px solid #bdbdbd;
		border-bottom: 1px solid #bdbdbd;
	}

	:where(.legacy) .sourceBlock {
		padding: 1rem 0;
	}
	:where(.legacy) .sourceBlock ul {
		margin-left: 0;
	}
	:where(.legacy) .sourceBlock ul .verticalItem:first-child {
		margin-left: 0;
	}

	:where(.legacy) .blockTitle {
		margin-top: 0;
	}

	:where(.legacy) .highlight--blue {
		color: #0077c0;
	}

	:where(.legacy) .highlight--purple {
		color: #42145f;
	}

	:where(.legacy) .highlight--green {
		color: #727800;
	}

	:where(.legacy) .dimmed {
		color: #8f8f8f;
	}

	:where(.legacy) .yellow-marker {
		background-color: yellow;
	}

	:where(.legacy) .yellow-line {
		border-bottom: 10px yellow solid;
	}

	/*------------------------------------*\
	$MEDIA ELEMENTS
\*------------------------------------*/
	:where(.legacy) img {
		-o-object-fit: cover;
		object-fit: cover;
	}
	:where(.legacy) img[data-inview] {
		opacity: 0;
		transition: opacity 0.5s;
	}
	:where(.legacy) img[data-inview='true'] {
		opacity: 1;
	}

	/* Flexible Media */
	:where(.legacy) img,
	:where(.legacy) video,
	:where(.legacy) object {
		display: block;
		max-width: 100%;
		height: auto;
	}

	:where(.legacy) svg {
		display: block;
		max-width: 100%;
	}

	:where(.legacy) iframe {
		border: none;
	}

	:where(.legacy) figure,
	:where(.legacy) .image-figure {
		margin: 0.8rem 0;
	}
	:where(.legacy) figure img,
	:where(.legacy) figure .image,
	:where(.legacy) .image-figure img,
	:where(.legacy) .image-figure .image {
		margin-bottom: 0.5rem;
	}

	:where(.legacy) figcaption,
	:where(.legacy) .image-caption {
		color: dimgrey;
		font-style: italic;
	}
	:where(.legacy) figcaption,
	:where(.legacy) .image-caption {
		font-size: 0.875rem;
	}

	:where(.legacy) .video-container {
		position: relative;
		padding-bottom: 56.25%;
		padding-top: 30px;
		height: 0;
		overflow: hidden;
	}
	:where(.legacy) .video-container iframe,
	:where(.legacy) .video-container object,
	:where(.legacy) .video-container embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	:where(.legacy) .imageLink figure {
		margin: 0;
	}
	:where(.legacy) .imageLink figure img {
		transition: transform 0.2s;
		transform-origin: 50% 100%;
		margin-bottom: 1em;
		width: 100%;
	}
	:where(.legacy) .imageLink figure:hover,
	:where(.legacy) .imageLink figure:focus,
	:where(.legacy) .imageLink figure:active {
		transform: scale(1.025);
	}

	:where(.legacy) .fullWidthImage {
		width: 100%;
	}

	/*------------------------------------*\
	$FORMS
\*------------------------------------*/
	:where(.legacy) form ol,
	:where(.legacy) form ul {
		margin-left: 0;
	}

	:where(.legacy) fieldset {
		border: 0;
		padding: 0;
		margin: 0;
	}

	:where(.legacy) legend {
		display: inline-block;
	}

	:where(.legacy) legend.h2,
	:where(.legacy) legend.menuBar--title,
	:where(.legacy) legend.search-header-title {
		padding-top: 1em;
		margin-top: 0;
	}

	:where(.legacy) label {
		display: block;
		padding-bottom: 0.5rem;
	}

	:where(.legacy) button,
	:where(.legacy) input,
	:where(.legacy) select,
	:where(.legacy) textarea {
		font-family: inherit;
		margin: 0;
	}
	:where(.legacy) button,
	:where(.legacy) input,
	:where(.legacy) select,
	:where(.legacy) textarea {
		font-size: 100%;
	}

	:where(.legacy) input,
	:where(.legacy) textarea {
		width: 100%;
		border: 0;
		padding: 0.5rem 0.65rem;
	}
	:where(.legacy) input,
	:where(.legacy) textarea {
		line-height: 1.2;
	}

	:where(.legacy) textarea {
		height: 7rem;
		resize: vertical;
	}

	:where(.legacy) input[type='text'],
	:where(.legacy) input[type='email'],
	:where(.legacy) input[type='search'],
	:where(.legacy) input[type='url'],
	:where(.legacy) input[type='number'],
	:where(.legacy) textarea {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		color: #333333;
	}
	:where(.legacy) input[type='text']::-moz-placeholder,
	:where(.legacy) input[type='email']::-moz-placeholder,
	:where(.legacy) input[type='search']::-moz-placeholder,
	:where(.legacy) input[type='url']::-moz-placeholder,
	:where(.legacy) input[type='number']::-moz-placeholder,
	:where(.legacy) textarea::-moz-placeholder {
		color: #777772;
		opacity: 1;
	}
	:where(.legacy) input[type='text']:-ms-input-placeholder,
	:where(.legacy) input[type='email']:-ms-input-placeholder,
	:where(.legacy) input[type='search']:-ms-input-placeholder,
	:where(.legacy) input[type='url']:-ms-input-placeholder,
	:where(.legacy) input[type='number']:-ms-input-placeholder,
	:where(.legacy) textarea:-ms-input-placeholder {
		color: #777772;
		opacity: 1;
	}
	:where(.legacy) input[type='text']::placeholder,
	:where(.legacy) input[type='email']::placeholder,
	:where(.legacy) input[type='search']::placeholder,
	:where(.legacy) input[type='url']::placeholder,
	:where(.legacy) input[type='number']::placeholder,
	:where(.legacy) textarea::placeholder {
		color: #777772;
		opacity: 1;
	}
	:where(.legacy) input[type='text']::-webkit-input-placeholder,
	:where(.legacy) input[type='email']::-webkit-input-placeholder,
	:where(.legacy) input[type='search']::-webkit-input-placeholder,
	:where(.legacy) input[type='url']::-webkit-input-placeholder,
	:where(.legacy) input[type='number']::-webkit-input-placeholder,
	:where(.legacy) textarea::-webkit-input-placeholder {
		color: #777772;
		opacity: 1;
	}
	:where(.legacy) input[type='text']::-moz-placeholder,
	:where(.legacy) input[type='email']::-moz-placeholder,
	:where(.legacy) input[type='search']::-moz-placeholder,
	:where(.legacy) input[type='url']::-moz-placeholder,
	:where(.legacy) input[type='number']::-moz-placeholder,
	:where(.legacy) textarea::-moz-placeholder {
		color: #777772;
		opacity: 1;
	}
	:where(.legacy) input[type='text']:-ms-input-placeholder,
	:where(.legacy) input[type='email']:-ms-input-placeholder,
	:where(.legacy) input[type='search']:-ms-input-placeholder,
	:where(.legacy) input[type='url']:-ms-input-placeholder,
	:where(.legacy) input[type='number']:-ms-input-placeholder,
	:where(.legacy) textarea:-ms-input-placeholder {
		color: #777772;
		opacity: 1;
	}

	:where(.legacy) button,
	:where(.legacy) input[type='submit'] {
		padding: 0.5rem;
		background: #333333;
		border: 1px solid #8f8f8f;
		cursor: pointer;
		color: #ffffff;
	}

	:where(.legacy) input[type='checkbox'],
	:where(.legacy) input[type='radio'] {
		width: auto;
		margin-right: 0.3em;
	}

	:where(.legacy) input[type='search'] {
		-webkit-appearance: none;
		border-radius: 0;
	}

	:where(.legacy) input[type='search']::-webkit-search-decoration {
		-webkit-appearance: none;
	}

	:where(.legacy) input[type='search']::-webkit-search-cancel-button {
		cursor: pointer;
	}
	:where(.legacy) input[type='search']::-webkit-search-cancel-button {
		font-size: 200%;
	}

	:where(.legacy) input[type='search']::-ms-clear {
		display: inline-block;
		cursor: pointer;
	}
	:where(.legacy) input[type='search']::-ms-clear {
		font-size: 200%;
	}

	:where(.legacy) select {
		width: 100%;
		padding: 0.5rem 0.65rem;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		border: 0;
		color: #333333;
	}
	:where(.legacy) select {
		line-height: 1.2;
	}
	:where(.legacy) select::-ms-expand {
		display: none;
	}

	:where(.legacy) .field-container {
		margin-bottom: 2rem;
	}

	:where(.legacy) .inline-form fieldset,
	:where(.legacy) .inline-form .inline-container {
		position: relative;
	}

	:where(.legacy) .inline-form input[type='submit'],
	:where(.legacy) .inline-form button,
	:where(.legacy) .inline-form .btn {
		padding: 0.65rem 1.3rem;
		background: #333333;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 1;
		width: auto;
	}
	:where(.legacy) .inline-form input[type='submit']:hover,
	:where(.legacy) .inline-form input[type='submit']:focus,
	:where(.legacy) .inline-form button:hover,
	:where(.legacy) .inline-form button:focus,
	:where(.legacy) .inline-form .btn:hover,
	:where(.legacy) .inline-form .btn:focus {
		background: #8f8f8f;
		color: #ffffff;
	}

	:where(.legacy) .inline-search-results-form {
		width: 100%;
	}
	:where(.legacy) .inline-search-results-form fieldset,
	:where(.legacy) .inline-search-results-form .inline-container {
		position: relative;
	}
	:where(.legacy) .inline-search-results-form input[type='submit'],
	:where(.legacy) .inline-search-results-form button {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 1;
		width: auto;
	}

	/* Validation */
	:where(.legacy) .has-error {
		border-color: #a90061;
	}

	:where(.legacy) .is-valid {
		border-color: #727800;
	}

	:where(.legacy) .inputhpfield {
		display: none;
	}

	:where(.legacy) .formField-input--error {
		background-color: #f4e9f1;
	}

	/*------------------------------------*\
	$SPECIFIC FORMS
\*------------------------------------*/
	/* Search Form */
	:where(.legacy) .search-field {
		padding-right: 3em;
	}

	:where(.legacy) .inline-form .search-submit {
		background: none;
		padding: 0.78em 1em;
		border: 0;
		border-left: 1px solid #8f8f8f;
		color: #8f8f8f;
	}

	/* Form Buttons */
	:where(.legacy) .formButton-cancel {
		float: left;
	}

	:where(.legacy) .formButton-submit {
		float: right;
	}

	:where(.legacy) .feedback-form {
		padding: 1rem;
	}

	:where(.legacy) .feedback-radio .formField-optionWrapper {
		background: #8fcae7;
		padding-left: 1rem;
		padding-right: 1rem;
		margin-right: 1rem;
		color: #033054;
		font-weight: bold;
	}

	:where(.legacy) .formField-row {
		margin-top: 0;
		margin-bottom: 1.2rem;
	}
	@media (max-width: 39.9375em) {
		:where(.legacy) .formField-row {
			margin-bottom: 1.6rem;
		}
	}

	:where(.legacy) .fieldset {
		margin-bottom: 1rem;
	}

	@media (min-width: 40em) {
		:where(.legacy) .formField {
			display: flex;
			margin-left: -1rem;
			margin-right: -1rem;
		}
		:where(.legacy) .formField--fieldset {
			display: block;
		}
	}

	:where(.legacy) .formField-label {
		display: block;
		margin: 0.2em 0;
		color: #033054;
	}
	@media (min-width: 40em) {
		:where(.legacy) .formField-label {
			text-align: right;
			width: calc(33.33333% - 2 * var(--grid-item-margin-inline) - 0.01rem);
			margin-left: 1rem;
			margin-right: 1rem;
		}
		:where(.legacy) .formField-label--legend {
			float: left;
		}
	}

	:where(.legacy) .formField-wrapper {
		display: block;
		position: relative;
	}
	@media (min-width: 40em) {
		:where(.legacy) .formField-wrapper {
			width: calc(66.66667% - 2 * var(--grid-item-margin-inline) - 0.01rem);
			margin-left: 1rem;
			margin-right: 1rem;
		}
	}
	@media (min-width: 55em) {
		:where(.legacy) .formField-wrapper {
			width: calc(50% - 2 * var(--grid-item-margin-inline) - 0.01rem);
		}
	}
	:where(.legacy) .formField-wrapper .interfaceLink-text {
		margin-right: 0;
	}

	:where(.legacy) .formField-radioWrapper {
		position: relative;
	}
	@media (min-width: 40em) {
		:where(.legacy) .formField-radioWrapper {
			float: left;
			width: calc(66.66667% - 2 * var(--grid-item-margin-inline) - 0.01rem);
			margin-left: 1rem;
			margin-right: 1rem;
		}
	}

	:where(.legacy) .formField-radioInputWrapper {
		position: relative;
		display: flex;
		flex-wrap: wrap;
	}

	:where(.legacy) .formField-selectWrapper {
		position: relative;
		display: block;
		width: 100%;
	}

	:where(.legacy) .formField-optionWrapper {
		position: relative;
		display: flex;
		margin-right: 2rem;
		padding: 0;
	}

	:where(.legacy) .formField-option.formField-option {
		width: 0.85rem;
		height: 0.85rem;
		flex: 0 0 auto;
		margin-top: 0.37em;
	}

	:where(.legacy) .formField-optionLabel {
		position: relative;
		top: 1px;
	}

	:where(.legacy) .formField-inputWrapper {
		display: flex;
		position: relative;
		background-color: #f6f6f6;
		border: 1px solid #033054;
	}
	:where(.legacy) .formField-inputWrapper .select-icon-down {
		width: 1.5em;
		height: 1.5em;
		position: absolute;
		top: 0.4rem;
		right: 0.4rem;
		pointer-events: none;
		background-color: #f6f6f6;
	}

	:where(.legacy) .formField-input {
		background: none;
		flex: 1 1 auto;
		width: 100%;
		border: 0;
		padding: 0.5rem 0.65rem;
		color: #333333;
	}
	:where(.legacy) .formField-input {
		line-height: 1.2;
	}

	:where(.legacy) .formField-optional {
		background: none;
		flex: 0 0 auto;
		color: #033054;
		font-style: italic;
		padding: 0.5rem 0.9rem;
	}
	:where(.legacy) .formField-optional {
		line-height: 1.2;
	}
	:where(.legacy) .formField-optional--select {
		border-left: 1px solid #033054;
	}

	:where(.legacy) .formField-description {
		display: block;
		margin-top: 0.3rem;
		font-style: italic;
	}

	:where(.legacy) .close {
		transition: transform 0.2s ease-in-out;
		padding: 0;
		background: none;
		border: none;
		width: 2rem;
		height: 2rem;
		padding: 0.4rem;
		background: #ffffff;
		color: #033054;
		fill: #033054;
		overflow: hidden;
	}
	@media (min-width: 35em) {
		:where(.legacy) .close {
			width: 3.16rem;
			height: 3.16rem;
			padding: 0.8216rem;
		}
	}
	:where(.legacy) .close:hover,
	:where(.legacy) .close:focus,
	:where(.legacy) .close:active {
		transform: scale(1.1);
		background: #ffffff;
		color: #033054;
		fill: #033054;
	}
	:where(.legacy) .close--onDark {
		color: #ffffff;
		fill: #ffffff;
	}
	:where(.legacy) .close--onDark:hover,
	:where(.legacy) .close--onDark:focus,
	:where(.legacy) .close--onDark:active {
		color: #ffffff;
		fill: #ffffff;
	}
	:where(.legacy) .close--withText {
		width: auto;
	}
	:where(.legacy) .close--absoluteRight {
		position: absolute;
		top: 0;
		right: 1rem;
	}
	:where(.legacy) .close-text {
		position: absolute;
		left: -999em;
		top: 0;
		height: 1px;
		overflow: hidden;
	}
	:where(.legacy) .close-text:focus {
		left: 1em;
		height: auto;
	}
	:where(.legacy) .close-text-show {
		color: #033054;
		font-weight: bold;
		margin-right: 0.2rem;
	}

	:where(.legacy) .close-icon {
		width: 100%;
		height: 100%;
		fill: #033054;
		pointer-events: all;
	}
	:where(.legacy) .btn .close-icon {
		fill: inherit;
	}
	:where(.legacy) .close-icon--withText {
		width: 1.5168rem;
		height: 1.5168rem;
	}

	:where(.legacy) .backdropOverlay {
		background: none;
		border: none;
		padding: 0;
		color: inherit;
		font-weight: inherit;
		line-height: inherit;
		text-align: left;
		text-transform: none;
		text-indent: 100%;
		white-space: nowrap;
		overflow: hidden;
		position: fixed;
		background-color: rgba(3, 48, 84, 0.6);
		width: 100%;
		cursor: default;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
	@media (max-width: 39.9375em) {
		:where(.legacy) .backdropOverlay--bigOnly {
			display: none;
		}
	}

	@media (max-width: 39.9375em) {
		:where(.legacy) .eventsCard {
			background: #f6f6f6;
		}
	}

	:where(.legacy) .linkList {
		padding-left: 0;
		margin-bottom: 0;
		margin-left: 0;
		list-style: none;
		margin-left: 0;
	}
	:where(.legacy) .linkList li:before {
		content: '';
		position: relative;
		display: none;
	}

	:where(.legacy) .linkList--grey .interfaceLink {
		color: #333333;
	}
	:where(.legacy) .linkList--grey .interfaceLink:hover,
	:where(.legacy) .linkList--grey .interfaceLink:active,
	:where(.legacy) .linkList--grey .interfaceLink:focus {
		color: #0077c0;
	}

	:where(.legacy) .linkList-item {
		-moz-column-break-inside: avoid;
		break-inside: avoid;
	}

	:where(.legacy) .linkList-item--condensed {
		padding-top: 0;
		padding-bottom: 0;
	}

	:where(.legacy) .linkList-icon {
		flex: 0 0 auto;
		width: 1em;
		height: 1em;
		margin-top: 0 !important;
		/* stylelint-disable-line declaration-no-important */
		margin-right: 1em;
	}

	:where(.legacy) .linkList-title {
		margin-top: 0.5rem;
	}

	:where(.legacy) .linkList-legalMods,
	:where(.legacy) .linkList-internal {
		border-top: 3px solid #0077c0;
		margin-top: 1.5rem;
	}

	:where(.legacy) .subjectLinkList {
		margin-top: 2rem;
		margin-bottom: 1.5rem;
		order: 4;
	}
	:where(.legacy) .subjectLinkList.contact {
		margin-top: 1rem;
	}
	@media (max-width: 39.9375em) {
		:where(.legacy) .subjectLinkList.contact {
			margin-bottom: 0;
		}
	}

	:where(.legacy) .subjectLinkList-header {
		margin-top: 0;
	}
	:where(.legacy) .subjectLinkList-header .interfaceLink {
		color: #033054;
		border-bottom: none !important;
		/* stylelint-disable-line declaration-no-important */
		fill: #033054;
	}
	:where(.legacy) .subjectLinkList-header .interfaceLink:hover,
	:where(.legacy) .subjectLinkList-header .interfaceLink:focus,
	:where(.legacy) .subjectLinkList-header .interfaceLink:active {
		color: #0077c0;
		fill: #0077c0;
	}

	/* BLOCKS */
	:where(.legacy) .blockContainer {
		margin-top: 1rem;
		margin-bottom: 1rem;
	}
	:where(.legacy) .blockContainer.blockContainer--blue {
		fill: #0077c0;
	}
	:where(.legacy) .blockContainer.blockContainer--aqua {
		fill: #00857c;
	}
	:where(.legacy) .blockContainer.blockContainer--grey {
		fill: #8f8f8f;
	}
	:where(.legacy) .blockContainer.blockContainer--purple {
		fill: #42145f;
	}
	:where(.legacy) .blockContainer.blockContainer--green {
		fill: #727800;
	}
	:where(.legacy) .blockContainer.blockContainer--violet {
		fill: #a90061;
	}
	:where(.legacy) .blockContainer.blockContainer--orange {
		fill: #e27000;
	}
	:where(.legacy) .blockContainer-more {
		flex: 0 0 auto;
		padding: 1rem 1rem 0.45rem 0;
		position: relative;
	}
	:where(.legacy) .blockContainer-more {
		line-height: 1;
	}
	:where(.legacy) .blockContainer-more:before {
		content: '';
		display: block;
		border-top: 2px solid;
		height: 0.5rem;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
	}
	:where(.legacy) .blockContainer-more.blockContainer-more--blue:before {
		border-top-color: #0077c0;
	}
	:where(.legacy) .blockContainer-more.blockContainer-more--aqua:before {
		border-top-color: #00857c;
	}
	:where(.legacy) .blockContainer-more.blockContainer-more--grey:before {
		border-top-color: #8f8f8f;
	}
	:where(.legacy) .blockContainer-more.blockContainer-more--purple:before {
		border-top-color: #42145f;
	}
	:where(.legacy) .blockContainer-more.blockContainer-more--green:before {
		border-top-color: #727800;
	}
	:where(.legacy) .blockContainer-more.blockContainer-more--violet:before {
		border-top-color: #a90061;
	}
	:where(.legacy) .blockContainer-more.blockContainer-more--orange:before {
		border-top-color: #e27000;
	}
	:where(.legacy) .blockContainer-more .interfaceLink,
	:where(.legacy) .blockContainer-more .interfaceLinkStatic {
		color: #333333;
	}
	:where(.legacy) .blockContainer-more--blue .interfaceLink,
	:where(.legacy) .blockContainer-more--blue .interfaceLinkStatic {
		fill: #0077c0;
	}
	:where(.legacy) .blockContainer-more--aqua .interfaceLink,
	:where(.legacy) .blockContainer-more--aqua .interfaceLinkStatic {
		fill: #00857c;
	}
	:where(.legacy) .blockContainer-more--grey .interfaceLink,
	:where(.legacy) .blockContainer-more--grey .interfaceLinkStatic {
		fill: #8f8f8f;
	}
	:where(.legacy) .blockContainer-more--purple .interfaceLink,
	:where(.legacy) .blockContainer-more--purple .interfaceLinkStatic {
		fill: #42145f;
	}
	:where(.legacy) .blockContainer-more--green .interfaceLink,
	:where(.legacy) .blockContainer-more--green .interfaceLinkStatic {
		fill: #727800;
	}
	:where(.legacy) .blockContainer-more--violet .interfaceLink,
	:where(.legacy) .blockContainer-more--violet .interfaceLinkStatic {
		fill: #a90061;
	}
	:where(.legacy) .blockContainer-more--orange .interfaceLink,
	:where(.legacy) .blockContainer-more--orange .interfaceLinkStatic {
		fill: #e27000;
	}
	:where(.legacy) .blockContainer-more--blue .interfaceLink:hover,
	:where(.legacy) .blockContainer-more--blue .interfaceLink:focus,
	:where(.legacy) .blockContainer-more--blue .interfaceLink:active,
	:where(.legacy) .blockContainer-more--blue .interfaceLinkStatic:hover,
	:where(.legacy) .blockContainer-more--blue .interfaceLinkStatic:focus,
	:where(.legacy) .blockContainer-more--blue .interfaceLinkStatic:active {
		fill: #0077c0;
		color: #0077c0;
	}
	:where(.legacy) .blockContainer-more--aqua .interfaceLink:hover,
	:where(.legacy) .blockContainer-more--aqua .interfaceLink:focus,
	:where(.legacy) .blockContainer-more--aqua .interfaceLink:active,
	:where(.legacy) .blockContainer-more--aqua .interfaceLinkStatic:hover,
	:where(.legacy) .blockContainer-more--aqua .interfaceLinkStatic:focus,
	:where(.legacy) .blockContainer-more--aqua .interfaceLinkStatic:active {
		fill: #00857c;
		color: #00857c;
	}
	:where(.legacy) .blockContainer-more--grey .interfaceLink:hover,
	:where(.legacy) .blockContainer-more--grey .interfaceLink:focus,
	:where(.legacy) .blockContainer-more--grey .interfaceLink:active,
	:where(.legacy) .blockContainer-more--grey .interfaceLinkStatic:hover,
	:where(.legacy) .blockContainer-more--grey .interfaceLinkStatic:focus,
	:where(.legacy) .blockContainer-more--grey .interfaceLinkStatic:active {
		fill: #8f8f8f;
		color: #8f8f8f;
	}
	:where(.legacy) .blockContainer-more--purple .interfaceLink:hover,
	:where(.legacy) .blockContainer-more--purple .interfaceLink:focus,
	:where(.legacy) .blockContainer-more--purple .interfaceLink:active,
	:where(.legacy) .blockContainer-more--purple .interfaceLinkStatic:hover,
	:where(.legacy) .blockContainer-more--purple .interfaceLinkStatic:focus,
	:where(.legacy) .blockContainer-more--purple .interfaceLinkStatic:active {
		fill: #42145f;
		color: #42145f;
	}
	:where(.legacy) .blockContainer-more--green .interfaceLink:hover,
	:where(.legacy) .blockContainer-more--green .interfaceLink:focus,
	:where(.legacy) .blockContainer-more--green .interfaceLink:active,
	:where(.legacy) .blockContainer-more--green .interfaceLinkStatic:hover,
	:where(.legacy) .blockContainer-more--green .interfaceLinkStatic:focus,
	:where(.legacy) .blockContainer-more--green .interfaceLinkStatic:active {
		fill: #727800;
		color: #727800;
	}
	:where(.legacy) .blockContainer-more--violet .interfaceLink:hover,
	:where(.legacy) .blockContainer-more--violet .interfaceLink:focus,
	:where(.legacy) .blockContainer-more--violet .interfaceLink:active,
	:where(.legacy) .blockContainer-more--violet .interfaceLinkStatic:hover,
	:where(.legacy) .blockContainer-more--violet .interfaceLinkStatic:focus,
	:where(.legacy) .blockContainer-more--violet .interfaceLinkStatic:active {
		fill: #a90061;
		color: #a90061;
	}
	:where(.legacy) .blockContainer-more--orange .interfaceLink:hover,
	:where(.legacy) .blockContainer-more--orange .interfaceLink:focus,
	:where(.legacy) .blockContainer-more--orange .interfaceLink:active,
	:where(.legacy) .blockContainer-more--orange .interfaceLinkStatic:hover,
	:where(.legacy) .blockContainer-more--orange .interfaceLinkStatic:focus,
	:where(.legacy) .blockContainer-more--orange .interfaceLinkStatic:active {
		fill: #e27000;
		color: #e27000;
	}
	:where(.legacy) .blockContainer--stretchItems {
		display: flex;
		align-items: stretch;
	}

	:where(.legacy) .homeBlockMore {
		flex: 0 0 auto;
		padding: 0.75rem;
		padding-left: 0;
		border-top: 3px solid;
	}
	:where(.legacy) .homeBlockMore.homeBlockMore--blue {
		color: #0077c0;
		border-top-color: #0077c0;
	}
	:where(.legacy) .homeBlockMore.homeBlockMore--aqua {
		color: #00857c;
		border-top-color: #00857c;
	}
	:where(.legacy) .homeBlockMore.homeBlockMore--grey {
		color: #8f8f8f;
		border-top-color: #8f8f8f;
	}
	:where(.legacy) .homeBlockMore.homeBlockMore--purple {
		color: #42145f;
		border-top-color: #42145f;
	}
	:where(.legacy) .homeBlockMore.homeBlockMore--green {
		color: #727800;
		border-top-color: #727800;
	}
	:where(.legacy) .homeBlockMore.homeBlockMore--violet {
		color: #a90061;
		border-top-color: #a90061;
	}
	:where(.legacy) .homeBlockMore.homeBlockMore--orange {
		color: #e27000;
		border-top-color: #e27000;
	}
	:where(.legacy) .homeBlockMore .interfaceLink,
	:where(.legacy) .homeBlockMore .blockLink {
		color: inherit;
		font-weight: bold;
		margin-bottom: -0.55rem;
	}
	:where(.legacy) .homeBlockMore .interfaceLink .media,
	:where(.legacy) .homeBlockMore .interfaceLink .content-block,
	:where(.legacy) .homeBlockMore .blockLink .media,
	:where(.legacy) .homeBlockMore .blockLink .content-block {
		display: inline-flex;
	}

	:where(.legacy) .listBlock,
	:where(.legacy) .homeBlock,
	:where(.legacy) .promoBlock {
		display: flex;
		flex-direction: column;
		position: relative;
	}

	:where(.legacy) .moreIcon {
		height: 1.4rem;
		margin-right: 1em;
	}
	:where(.legacy) .blockContainer--blue .moreIcon {
		fill: #0077c0;
	}
	:where(.legacy) .blockContainer--aqua .moreIcon {
		fill: #00857c;
	}
	:where(.legacy) .blockContainer--grey .moreIcon {
		fill: #8f8f8f;
	}
	:where(.legacy) .blockContainer--purple .moreIcon {
		fill: #42145f;
	}
	:where(.legacy) .blockContainer--green .moreIcon {
		fill: #727800;
	}
	:where(.legacy) .blockContainer--violet .moreIcon {
		fill: #a90061;
	}
	:where(.legacy) .blockContainer--orange .moreIcon {
		fill: #e27000;
	}
	:where(.legacy) .listBlock--blue .moreIcon {
		fill: #0077c0;
	}
	:where(.legacy) .listBlock--aqua .moreIcon {
		fill: #00857c;
	}
	:where(.legacy) .listBlock--grey .moreIcon {
		fill: #8f8f8f;
	}
	:where(.legacy) .listBlock--purple .moreIcon {
		fill: #42145f;
	}
	:where(.legacy) .listBlock--green .moreIcon {
		fill: #727800;
	}
	:where(.legacy) .listBlock--violet .moreIcon {
		fill: #a90061;
	}
	:where(.legacy) .listBlock--orange .moreIcon {
		fill: #e27000;
	}
	:where(.legacy) .homeBlock--blue .moreIcon {
		fill: #0077c0;
	}
	:where(.legacy) .homeBlock--aqua .moreIcon {
		fill: #00857c;
	}
	:where(.legacy) .homeBlock--grey .moreIcon {
		fill: #8f8f8f;
	}
	:where(.legacy) .homeBlock--purple .moreIcon {
		fill: #42145f;
	}
	:where(.legacy) .homeBlock--green .moreIcon {
		fill: #727800;
	}
	:where(.legacy) .homeBlock--violet .moreIcon {
		fill: #a90061;
	}
	:where(.legacy) .homeBlock--orange .moreIcon {
		fill: #e27000;
	}

	:where(.legacy) .threadDate {
		line-height: 1.6;
	}

	:where(.legacy) .relatedDetailPagesBlock {
		margin-top: 2.5rem;
		background-color: #f3f9ff;
		border-top: 3px solid #0077c0;
		border-bottom: 1px solid #0077c0;
		color: #033054;
	}
	:where(.legacy) .relatedDetailPagesBlock .block-title {
		color: #0077c0;
		margin-top: 1rem;
	}
	:where(.legacy) .relatedDetailPagesBlock p {
		font-weight: bold;
	}
	:where(.legacy) .relatedDetailPagesBlock .subjectListTitle {
		min-height: 3rem;
		font-weight: bold;
	}

	:where(.legacy) .listBlock {
		padding: 0 1rem;
		padding-left: 0;
	}
	:where(.legacy) .listBlock--autoHeight {
		min-height: 0;
	}
	:where(.legacy) .listBlock-title {
		font-weight: normal;
		margin-top: 0;
	}
	:where(.legacy) .listBlock--blue .listBlock-title {
		color: #0077c0;
	}
	:where(.legacy) .listBlock--aqua .listBlock-title {
		color: #00857c;
	}
	:where(.legacy) .listBlock--grey .listBlock-title {
		color: #8f8f8f;
	}
	:where(.legacy) .listBlock--purple .listBlock-title {
		color: #42145f;
	}
	:where(.legacy) .listBlock--green .listBlock-title {
		color: #727800;
	}
	:where(.legacy) .listBlock--violet .listBlock-title {
		color: #a90061;
	}
	:where(.legacy) .listBlock--orange .listBlock-title {
		color: #e27000;
	}
	:where(.legacy) .listBlock-icon {
		height: 1.35rem;
		width: 1.35rem;
		margin-right: 0.5rem;
	}
	:where(.legacy) .listBlock--blue .listBlock-icon {
		fill: #0077c0;
	}
	:where(.legacy) .listBlock--aqua .listBlock-icon {
		fill: #00857c;
	}
	:where(.legacy) .listBlock--grey .listBlock-icon {
		fill: #8f8f8f;
	}
	:where(.legacy) .listBlock--purple .listBlock-icon {
		fill: #42145f;
	}
	:where(.legacy) .listBlock--green .listBlock-icon {
		fill: #727800;
	}
	:where(.legacy) .listBlock--violet .listBlock-icon {
		fill: #a90061;
	}
	:where(.legacy) .listBlock--orange .listBlock-icon {
		fill: #e27000;
	}
	:where(.legacy) .listBlock-list {
		display: flex;
		flex-flow: column wrap;
	}
	:where(.legacy) .listBlock-list,
	:where(.legacy) .listBlock-list .interfaceLink {
		line-height: 1.2;
	}
	:where(.legacy) .listBlock-list .goIcon {
		margin-top: -0.15rem;
		margin-bottom: -0.35rem;
	}
	:where(.legacy) .listBlock .interfaceLink {
		color: #333333;
	}
	:where(.legacy) .listBlock--blue .interfaceLink {
		fill: #0077c0;
	}
	:where(.legacy) .listBlock--aqua .interfaceLink {
		fill: #00857c;
	}
	:where(.legacy) .listBlock--grey .interfaceLink {
		fill: #8f8f8f;
	}
	:where(.legacy) .listBlock--purple .interfaceLink {
		fill: #42145f;
	}
	:where(.legacy) .listBlock--green .interfaceLink {
		fill: #727800;
	}
	:where(.legacy) .listBlock--violet .interfaceLink {
		fill: #a90061;
	}
	:where(.legacy) .listBlock--orange .interfaceLink {
		fill: #e27000;
	}
	:where(.legacy) .listBlock--blue .interfaceLink:hover,
	:where(.legacy) .listBlock--blue .interfaceLink:focus,
	:where(.legacy) .listBlock--blue .interfaceLink:active {
		fill: #0077c0;
		color: #0077c0;
	}
	:where(.legacy) .listBlock--aqua .interfaceLink:hover,
	:where(.legacy) .listBlock--aqua .interfaceLink:focus,
	:where(.legacy) .listBlock--aqua .interfaceLink:active {
		fill: #00857c;
		color: #00857c;
	}
	:where(.legacy) .listBlock--grey .interfaceLink:hover,
	:where(.legacy) .listBlock--grey .interfaceLink:focus,
	:where(.legacy) .listBlock--grey .interfaceLink:active {
		fill: #8f8f8f;
		color: #8f8f8f;
	}
	:where(.legacy) .listBlock--purple .interfaceLink:hover,
	:where(.legacy) .listBlock--purple .interfaceLink:focus,
	:where(.legacy) .listBlock--purple .interfaceLink:active {
		fill: #42145f;
		color: #42145f;
	}
	:where(.legacy) .listBlock--green .interfaceLink:hover,
	:where(.legacy) .listBlock--green .interfaceLink:focus,
	:where(.legacy) .listBlock--green .interfaceLink:active {
		fill: #727800;
		color: #727800;
	}
	:where(.legacy) .listBlock--violet .interfaceLink:hover,
	:where(.legacy) .listBlock--violet .interfaceLink:focus,
	:where(.legacy) .listBlock--violet .interfaceLink:active {
		fill: #a90061;
		color: #a90061;
	}
	:where(.legacy) .listBlock--orange .interfaceLink:hover,
	:where(.legacy) .listBlock--orange .interfaceLink:focus,
	:where(.legacy) .listBlock--orange .interfaceLink:active {
		fill: #e27000;
		color: #e27000;
	}

	:where(.legacy) .homeBlock {
		cursor: pointer;
		text-decoration: none;
		border-bottom: none;
		transition: color 0.2s;
	}
	:where(.legacy) .homeBlock:hover.homeBlock--blue,
	:where(.legacy) .homeBlock:focus.homeBlock--blue,
	:where(.legacy) .homeBlock:active.homeBlock--blue {
		color: #0077c0;
	}
	:where(.legacy) .homeBlock:hover.homeBlock--aqua,
	:where(.legacy) .homeBlock:focus.homeBlock--aqua,
	:where(.legacy) .homeBlock:active.homeBlock--aqua {
		color: #00857c;
	}
	:where(.legacy) .homeBlock:hover.homeBlock--grey,
	:where(.legacy) .homeBlock:focus.homeBlock--grey,
	:where(.legacy) .homeBlock:active.homeBlock--grey {
		color: #8f8f8f;
	}
	:where(.legacy) .homeBlock:hover.homeBlock--purple,
	:where(.legacy) .homeBlock:focus.homeBlock--purple,
	:where(.legacy) .homeBlock:active.homeBlock--purple {
		color: #42145f;
	}
	:where(.legacy) .homeBlock:hover.homeBlock--green,
	:where(.legacy) .homeBlock:focus.homeBlock--green,
	:where(.legacy) .homeBlock:active.homeBlock--green {
		color: #727800;
	}
	:where(.legacy) .homeBlock:hover.homeBlock--violet,
	:where(.legacy) .homeBlock:focus.homeBlock--violet,
	:where(.legacy) .homeBlock:active.homeBlock--violet {
		color: #a90061;
	}
	:where(.legacy) .homeBlock:hover.homeBlock--orange,
	:where(.legacy) .homeBlock:focus.homeBlock--orange,
	:where(.legacy) .homeBlock:active.homeBlock--orange {
		color: #e27000;
	}
	:where(.legacy) .homeBlock:hover .homeBlock-title,
	:where(.legacy) .homeBlock:focus .homeBlock-title,
	:where(.legacy) .homeBlock:active .homeBlock-title {
		color: inherit;
	}
	:where(.legacy) .homeBlock--autoHeight {
		min-height: 0;
	}
	:where(.legacy) .homeBlock-figure {
		margin: 0;
		position: relative;
		margin-bottom: 1rem;
	}
	:where(.legacy) .homeBlock-image {
		width: 100%;
		margin: 0;
	}
	:where(.legacy) .homeBlock-content {
		padding: 0 1rem;
		padding-left: 0;
	}
	:where(.legacy) .homeBlock-content:before {
		content: '';
		display: block;
		margin-top: -1px;
		padding-top: 1px;
		margin-bottom: 1rem;
	}
	:where(.legacy) .homeBlock-content:after {
		content: '';
		display: block;
		margin-bottom: -1px;
		padding-bottom: 1px;
		margin-top: 1rem;
	}
	:where(.legacy) .homeBlock-title {
		margin-top: 0;
		color: #333333;
	}

	:where(.legacy) .promoBlock {
		border-top: 3px solid;
		text-decoration: none;
		border-bottom: none;
		transition: color 0.2s;
		transform-origin: 50% 100%;
	}
	:where(.legacy) .promoBlock.promoBlock--blue {
		border-top-color: #0077c0;
	}
	:where(.legacy) .promoBlock.promoBlock--aqua {
		border-top-color: #00857c;
	}
	:where(.legacy) .promoBlock.promoBlock--grey {
		border-top-color: #8f8f8f;
	}
	:where(.legacy) .promoBlock.promoBlock--purple {
		border-top-color: #42145f;
	}
	:where(.legacy) .promoBlock.promoBlock--green {
		border-top-color: #727800;
	}
	:where(.legacy) .promoBlock.promoBlock--violet {
		border-top-color: #a90061;
	}
	:where(.legacy) .promoBlock.promoBlock--orange {
		border-top-color: #e27000;
	}
	:where(.legacy) .promoBlock:hover.promoBlock--blue,
	:where(.legacy) .promoBlock:focus.promoBlock--blue,
	:where(.legacy) .promoBlock:active.promoBlock--blue {
		color: #0077c0;
	}
	:where(.legacy) .promoBlock:hover.promoBlock--aqua,
	:where(.legacy) .promoBlock:focus.promoBlock--aqua,
	:where(.legacy) .promoBlock:active.promoBlock--aqua {
		color: #00857c;
	}
	:where(.legacy) .promoBlock:hover.promoBlock--grey,
	:where(.legacy) .promoBlock:focus.promoBlock--grey,
	:where(.legacy) .promoBlock:active.promoBlock--grey {
		color: #8f8f8f;
	}
	:where(.legacy) .promoBlock:hover.promoBlock--purple,
	:where(.legacy) .promoBlock:focus.promoBlock--purple,
	:where(.legacy) .promoBlock:active.promoBlock--purple {
		color: #42145f;
	}
	:where(.legacy) .promoBlock:hover.promoBlock--green,
	:where(.legacy) .promoBlock:focus.promoBlock--green,
	:where(.legacy) .promoBlock:active.promoBlock--green {
		color: #727800;
	}
	:where(.legacy) .promoBlock:hover.promoBlock--violet,
	:where(.legacy) .promoBlock:focus.promoBlock--violet,
	:where(.legacy) .promoBlock:active.promoBlock--violet {
		color: #a90061;
	}
	:where(.legacy) .promoBlock:hover.promoBlock--orange,
	:where(.legacy) .promoBlock:focus.promoBlock--orange,
	:where(.legacy) .promoBlock:active.promoBlock--orange {
		color: #e27000;
	}
	:where(.legacy) .webinar .promoBlock:hover,
	:where(.legacy) .webinar .promoBlock:focus,
	:where(.legacy) .webinar .promoBlock:active {
		color: #0077c0;
	}
	:where(.legacy) .promoBlock:hover .promoBlock-title,
	:where(.legacy) .promoBlock:focus .promoBlock-title,
	:where(.legacy) .promoBlock:active .promoBlock-title {
		color: inherit;
	}
	:where(.legacy) .promoBlock:hover .promoBlock-figure:after,
	:where(.legacy) .promoBlock:focus .promoBlock-figure:after,
	:where(.legacy) .promoBlock:active .promoBlock-figure:after {
		width: 100%;
		left: 0;
	}
	:where(.legacy) .promoBlock--autoHeight {
		min-height: 0;
	}
	:where(.legacy) .promoBlock-top {
		position: relative;
	}
	:where(.legacy) .promoBlock-figure {
		margin: 0;
		position: relative;
	}
	:where(.legacy) .promoBlock-figure:after {
		position: absolute;
		width: 0;
		height: 100%;
		background: rgba(0, 119, 192, 0.4);
		content: '';
		display: block;
		bottom: 0;
		left: 50%;
		transition:
			width 0.2s ease-in-out,
			left 0.2s ease-in-out;
	}
	:where(.legacy) .promoBlock-figure .interfaceLink {
		display: block;
	}
	:where(.legacy) .promoBlock--purple .promoBlock-figure:after {
		background: rgba(66, 20, 95, 0.4);
	}
	:where(.legacy) .promoBlock--violet .promoBlock-figure:after {
		background: rgba(169, 0, 97, 0.4);
	}
	:where(.legacy) .promoBlock-link {
		display: block;
	}
	:where(.legacy) .promoBlock-image {
		width: 100%;
		margin: 0;
		-o-object-fit: cover;
		object-fit: cover;
		-o-object-position: top;
		object-position: top;
		transition: transform 0.2s;
	}
	:where(.legacy) .promoBlock-image.externalImage {
		max-height: 10rem;
	}
	:where(.legacy) .promoBlock-content {
		width: 100%;
		display: inline;
	}
	:where(.legacy) .promoBlock-content .infoText {
		margin-bottom: 0.5rem;
	}
	:where(.legacy) .promoBlock-content .block-title,
	:where(.legacy) .promoBlock-content .promoBlock-title {
		margin-top: 1rem;
	}
	:where(.legacy) .promoBlock-content a {
		border-bottom: 0;
	}
	:where(.legacy) .promoBlock-title {
		margin-top: 0;
		color: #333333;
	}
	:where(.legacy) .promoBlock-title .metaInfo {
		display: inline-block;
		margin-left: 1.25rem;
		font-weight: normal;
		white-space: nowrap;
	}
	:where(.legacy) .promoBlock-label {
		position: absolute;
		bottom: 0;
		left: -0.05em;
		padding: 0.25em 0.75em;
		color: #ffffff;
		background-color: #0077c0;
	}
	:where(.legacy) .promoBlock-label {
		font-size: 0.875rem;
	}
	:where(.legacy) .promoBlock--blue .promoBlock-label {
		background-color: #0077c0;
	}
	:where(.legacy) .promoBlock--aqua .promoBlock-label {
		background-color: #00857c;
	}
	:where(.legacy) .promoBlock--grey .promoBlock-label {
		background-color: #8f8f8f;
	}
	:where(.legacy) .promoBlock--purple .promoBlock-label {
		background-color: #42145f;
	}
	:where(.legacy) .promoBlock--green .promoBlock-label {
		background-color: #727800;
	}
	:where(.legacy) .promoBlock--violet .promoBlock-label {
		background-color: #a90061;
	}
	:where(.legacy) .promoBlock--orange .promoBlock-label {
		background-color: #e27000;
	}
	:where(.legacy) .promoBlock-go {
		order: 2;
		flex: 1 0 auto;
		background-color: #0077c0;
		padding: 0 0.5rem;
	}
	:where(.legacy) .promoBlock--blue .promoBlock-go {
		background-color: #0077c0;
	}
	:where(.legacy) .promoBlock--aqua .promoBlock-go {
		background-color: #00857c;
	}
	:where(.legacy) .promoBlock--grey .promoBlock-go {
		background-color: #8f8f8f;
	}
	:where(.legacy) .promoBlock--purple .promoBlock-go {
		background-color: #42145f;
	}
	:where(.legacy) .promoBlock--green .promoBlock-go {
		background-color: #727800;
	}
	:where(.legacy) .promoBlock--violet .promoBlock-go {
		background-color: #a90061;
	}
	:where(.legacy) .promoBlock--orange .promoBlock-go {
		background-color: #e27000;
	}
	:where(.legacy) .promoBlock-go:before {
		content: '';
		display: block;
		margin-top: -1px;
		padding-top: 1px;
		margin-bottom: 0.5rem;
	}
	:where(.legacy) .promoBlock-go:after {
		content: '';
		display: block;
		margin-bottom: -1px;
		padding-bottom: 1px;
		margin-top: 0.5rem;
	}
	:where(.legacy) .promoBlock-go-title {
		font-weight: normal;
		display: flex;
		margin: 0;
		color: #ffffff;
		fill: #ffffff;
	}
	:where(.legacy) .promoBlock-go-title .goIcon {
		margin-top: -0.25rem;
		margin-bottom: -0.25rem;
	}
	:where(.legacy) .promoBlock-more {
		font-weight: bold;
	}
	:where(.legacy) .blockContainer-more--blue .promoBlock-more {
		color: #0077c0;
	}
	:where(.legacy) .blockContainer-more--aqua .promoBlock-more {
		color: #00857c;
	}
	:where(.legacy) .blockContainer-more--grey .promoBlock-more {
		color: #8f8f8f;
	}
	:where(.legacy) .blockContainer-more--purple .promoBlock-more {
		color: #42145f;
	}
	:where(.legacy) .blockContainer-more--green .promoBlock-more {
		color: #727800;
	}
	:where(.legacy) .blockContainer-more--violet .promoBlock-more {
		color: #a90061;
	}
	:where(.legacy) .blockContainer-more--orange .promoBlock-more {
		color: #e27000;
	}
	:where(.legacy) .promoBlock + .blockContainer-more {
		padding-top: 0;
	}
	:where(.legacy) .promoBlock + .blockContainer-more:before {
		display: none;
	}
	:where(.legacy) .promoBlock--story {
		border-top: 0;
	}

	@media (pointer: coarse) and (hover: none) {
		:where(.legacy) .promoBlock-figure:after {
			content: none;
		}
	}

	:where(.legacy) .block,
	:where(.legacy) .content-block {
		margin-top: 1rem;
		padding: 0 1rem;
	}
	:where(.legacy) .block:before,
	:where(.legacy) .content-block:before {
		content: '';
		display: block;
		margin-top: -1px;
		padding-top: 1px;
		margin-bottom: 1rem;
	}
	:where(.legacy) .block:after,
	:where(.legacy) .content-block:after {
		content: '';
		display: block;
		margin-bottom: -1px;
		padding-bottom: 1px;
		margin-top: 1rem;
	}
	:where(.legacy) .block.block--blue,
	:where(.legacy) .block--blue.content-block {
		background-color: #e2f0f7;
	}
	:where(.legacy) .block.block--aqua,
	:where(.legacy) .block--aqua.content-block {
		background-color: #00857c;
	}
	:where(.legacy) .block.block--grey,
	:where(.legacy) .block--grey.content-block {
		background-color: #f6f6f6;
	}
	:where(.legacy) .block.block--purple,
	:where(.legacy) .block--purple.content-block {
		background-color: #d9c7e3;
	}
	:where(.legacy) .block.block--green,
	:where(.legacy) .block--green.content-block {
		background-color: #eeeed9;
	}
	:where(.legacy) .block.block--violet,
	:where(.legacy) .block--violet.content-block {
		background-color: #f4e9f1;
	}
	:where(.legacy) .block.block--orange,
	:where(.legacy) .block--orange.content-block {
		background-color: #e27000;
	}
	:where(.legacy) .block--side {
		border-top: 3px solid #0077c0;
		padding-top: 0.5rem;
		padding-bottom: 1.5rem;
		margin-bottom: 0;
		padding-left: 0;
	}
	:where(.legacy) .block--side:last-child {
		border-bottom: none;
	}
	:where(.legacy) .block--grey {
		background-color: #f6f6f6;
	}
	:where(.legacy) .block--blue {
		background-color: #e2f0f7;
	}
	:where(.legacy) .block--blue .interfaceLink {
		color: #033054;
		fill: #033054;
	}

	:where(.legacy) .postTitle {
		display: block;
	}

	:where(.legacy) .postDate {
		font-weight: normal;
		font-style: italic;
	}
	:where(.legacy) .postDate {
		font-size: 0.75rem;
	}

	:where(.legacy) .pageBlockCallToAction {
		font-weight: bold;
		margin-bottom: 0.5rem;
	}
	:where(.legacy) .blockContainer--blue .pageBlockCallToAction {
		color: #0077c0;
	}
	:where(.legacy) .blockContainer--aqua .pageBlockCallToAction {
		color: #00857c;
	}
	:where(.legacy) .blockContainer--grey .pageBlockCallToAction {
		color: #8f8f8f;
	}
	:where(.legacy) .blockContainer--purple .pageBlockCallToAction {
		color: #42145f;
	}
	:where(.legacy) .blockContainer--green .pageBlockCallToAction {
		color: #727800;
	}
	:where(.legacy) .blockContainer--violet .pageBlockCallToAction {
		color: #a90061;
	}
	:where(.legacy) .blockContainer--orange .pageBlockCallToAction {
		color: #e27000;
	}

	:where(.legacy) .blockContainer--blue .pageBlockAnkeiler:hover,
	:where(.legacy) .blockContainer--blue .pageBlockAnkeiler:focus,
	:where(.legacy) .blockContainer--blue .pageBlockAnkeiler:active {
		color: #0077c0;
	}

	:where(.legacy) .blockContainer--aqua .pageBlockAnkeiler:hover,
	:where(.legacy) .blockContainer--aqua .pageBlockAnkeiler:focus,
	:where(.legacy) .blockContainer--aqua .pageBlockAnkeiler:active {
		color: #00857c;
	}

	:where(.legacy) .blockContainer--grey .pageBlockAnkeiler:hover,
	:where(.legacy) .blockContainer--grey .pageBlockAnkeiler:focus,
	:where(.legacy) .blockContainer--grey .pageBlockAnkeiler:active {
		color: #8f8f8f;
	}

	:where(.legacy) .blockContainer--purple .pageBlockAnkeiler:hover,
	:where(.legacy) .blockContainer--purple .pageBlockAnkeiler:focus,
	:where(.legacy) .blockContainer--purple .pageBlockAnkeiler:active {
		color: #42145f;
	}

	:where(.legacy) .blockContainer--green .pageBlockAnkeiler:hover,
	:where(.legacy) .blockContainer--green .pageBlockAnkeiler:focus,
	:where(.legacy) .blockContainer--green .pageBlockAnkeiler:active {
		color: #727800;
	}

	:where(.legacy) .blockContainer--violet .pageBlockAnkeiler:hover,
	:where(.legacy) .blockContainer--violet .pageBlockAnkeiler:focus,
	:where(.legacy) .blockContainer--violet .pageBlockAnkeiler:active {
		color: #a90061;
	}

	:where(.legacy) .blockContainer--orange .pageBlockAnkeiler:hover,
	:where(.legacy) .blockContainer--orange .pageBlockAnkeiler:focus,
	:where(.legacy) .blockContainer--orange .pageBlockAnkeiler:active {
		color: #e27000;
	}

	:where(.legacy) .blockContainer--blue .pageBlockAnkeilerTitle {
		color: #0077c0;
	}

	:where(.legacy) .blockContainer--aqua .pageBlockAnkeilerTitle {
		color: #00857c;
	}

	:where(.legacy) .blockContainer--grey .pageBlockAnkeilerTitle {
		color: #8f8f8f;
	}

	:where(.legacy) .blockContainer--purple .pageBlockAnkeilerTitle {
		color: #42145f;
	}

	:where(.legacy) .blockContainer--green .pageBlockAnkeilerTitle {
		color: #727800;
	}

	:where(.legacy) .blockContainer--violet .pageBlockAnkeilerTitle {
		color: #a90061;
	}

	:where(.legacy) .blockContainer--orange .pageBlockAnkeilerTitle {
		color: #e27000;
	}

	:where(.legacy) .pageBlockMore {
		border-top: 1px solid;
		padding-top: 0.5rem;
	}
	:where(.legacy) .blockContainer--blue .pageBlockMore {
		border-color: #0077c0;
	}
	:where(.legacy) .blockContainer--aqua .pageBlockMore {
		border-color: #00857c;
	}
	:where(.legacy) .blockContainer--grey .pageBlockMore {
		border-color: #8f8f8f;
	}
	:where(.legacy) .blockContainer--purple .pageBlockMore {
		border-color: #42145f;
	}
	:where(.legacy) .blockContainer--green .pageBlockMore {
		border-color: #727800;
	}
	:where(.legacy) .blockContainer--violet .pageBlockMore {
		border-color: #a90061;
	}
	:where(.legacy) .blockContainer--orange .pageBlockMore {
		border-color: #e27000;
	}

	:where(.legacy) .pageBlockMoreLink {
		display: flex;
		font-weight: normal;
	}
	:where(.legacy) .blockContainer--blue .pageBlockMoreLink {
		color: #0077c0;
	}
	:where(.legacy) .blockContainer--aqua .pageBlockMoreLink {
		color: #00857c;
	}
	:where(.legacy) .blockContainer--grey .pageBlockMoreLink {
		color: #8f8f8f;
	}
	:where(.legacy) .blockContainer--purple .pageBlockMoreLink {
		color: #42145f;
	}
	:where(.legacy) .blockContainer--green .pageBlockMoreLink {
		color: #727800;
	}
	:where(.legacy) .blockContainer--violet .pageBlockMoreLink {
		color: #a90061;
	}
	:where(.legacy) .blockContainer--orange .pageBlockMoreLink {
		color: #e27000;
	}
	:where(.legacy) .pageBlockMoreLink .interfaceLink-text {
		flex: none;
	}

	:where(.legacy) .show-mobile {
		display: none;
	}
	@media (max-width: 39.9375em) {
		:where(.legacy) .show-mobile {
			display: block;
		}
	}

	:where(.legacy) .show-medium {
		display: block;
	}
	@media (min-width: 45em) {
		:where(.legacy) .show-medium {
			display: none;
		}
	}

	:where(.legacy) .show-desktop {
		display: block;
	}
	@media (max-width: 39.9375em) {
		:where(.legacy) .show-desktop {
			display: none;
		}
	}

	/* HEADER */
	:where(.legacy) .brandBarBottom {
		background: #e2f0f7;
		min-height: 0.6rem;
		padding-bottom: 6px;
		position: relative;
	}
	:where(.legacy) .brandBarBottom:before {
		content: '';
		display: block;
		margin-top: -1px;
		padding-top: 1px;
		margin-bottom: 1.8rem;
	}
	:where(.legacy) .brandBarBottom:after {
		content: '';
		display: block;
		margin-bottom: -1px;
		padding-bottom: 1px;
		margin-top: 1rem;
	}
	:where(.legacy) .brandBarBottom .labelList-link {
		background-color: #ffffff;
	}

	:where(.legacy) .brandBarPageTitle {
		margin-bottom: 0;
		color: #033054;
	}
	:where(.legacy) .brandBarPageTitle {
		font-size: 1.75rem;
	}

	:where(.legacy) .logo {
		display: block;
		position: relative;
	}
	@media (min-width: 50em) {
		:where(.legacy) .logo {
			width: calc(66.66667% - 2 * var(--grid-item-margin-inline) - 0.01rem);
		}
	}

	:where(.legacy) .logo-link {
		text-decoration: none;
		border-bottom: none;
		font-weight: bold;
		color: #333333;
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
		display: block;
	}
	:where(.legacy) .logo-link:focus {
		outline-width: 1px;
		outline-style: dotted;
	}

	:where(.legacy) .logo-image {
		width: auto;
		display: block;
		height: 2.6rem;
		width: 7.8rem;
	}
	@media (min-width: 35em) {
		:where(.legacy) .logo-image {
			height: 3rem;
			width: 9rem;
		}
	}
	@media (min-width: 55em) {
		:where(.legacy) .logo-image {
			height: 3.12rem;
			width: 9.36rem;
		}
	}

	:where(.legacy) .payOff {
		padding-left: 0.5rem;
		flex: 0 0 auto;
		margin-left: auto;
		align-self: center;
	}
	@media (min-width: 50em) {
		:where(.legacy) .payOff {
			width: calc(33.33333% - 2 * var(--grid-item-margin-inline) - 0.01rem);
		}
	}

	:where(.legacy) .payOff-text {
		margin: 0 0 0.1rem 0;
		text-align: left;
		color: #696969;
		font-style: italic;
		line-height: 1.2;
	}
	@media (max-width: 34.9375em) {
		:where(.legacy) .payOff-text {
			padding-right: 0.5rem;
			font-size: 0.75rem;
		}
	}

	:where(.legacy) .payOff-text--home {
		color: #000000;
	}

	:where(.legacy) .breadcrumbs {
		padding-left: 0;
		margin-bottom: 0;
		margin-left: 0;
		list-style: none;
		margin: 0 1rem 0 0;
		padding: 0.5rem 0;
	}
	:where(.legacy) .breadcrumbs li:before {
		content: '';
		position: relative;
		display: none;
	}

	:where(.legacy) .breadcrumb {
		padding: 0;
		display: inline;
	}
	:where(.legacy) .breadcrumb {
		font-size: 0.875rem;
	}
	@media (max-width: 54.9375em) {
		:where(.legacy)
			.breadcrumb:first-child:nth-last-child(n + 3)
			.breadcrumb-link:after {
			content: '>';
			display: inline-block;
			padding-left: 0.25rem;
			color: #ffffff;
		}
		:where(.legacy) .breadcrumb:first-child:nth-last-child(n + 3):after {
			content: '…';
			display: inline-block;
			padding-right: 0.5rem;
			color: #ffffff;
		}
		:where(.legacy) .breadcrumb:nth-child(n + 2) {
			display: none;
		}
		:where(.legacy) .breadcrumb:last-child {
			display: inline;
		}
	}

	:where(.legacy) .breadcrumb-link {
		text-decoration: none;
		border-bottom: 2px solid transparent;
		display: inline;
		padding: 0 0.5rem 0 0;
		color: #ffffff;
		word-break: break-word;
	}
	:where(.legacy) .breadcrumb-link:hover,
	:where(.legacy) .breadcrumb-link:focus,
	:where(.legacy) .breadcrumb-link:active {
		color: #ffffff;
	}
	:where(.legacy) .breadcrumb-link:before {
		content: '>';
		display: inline;
		padding-right: 0.25rem;
		color: #ffffff;
	}
	:where(.legacy) .breadcrumb-link.breadcrumb-linkFirst:before {
		content: '';
		display: none;
	}

	:where(.legacy) .breadcrumb-text {
		text-decoration: none;
		border-bottom: 2px solid transparent;
	}
	:where(.legacy) .breadcrumb-text:hover,
	:where(.legacy) .breadcrumb-text:focus,
	:where(.legacy) .breadcrumb-text:active {
		text-decoration: none;
		border-bottom: 2px solid;
	}

	:where(.legacy) .textLogo {
		flex: 1 0 0;
		color: #033054;
		font-size: 0.9rem;
		font-weight: bold;
		line-height: 1.2;
	}
	@media (min-width: 35em) {
		:where(.legacy) .textLogo {
			font-size: 1.25rem;
		}
	}
	@media (min-width: 50em) {
		:where(.legacy) .textLogo {
			font-size: 1.75rem;
		}
	}

	:where(.legacy) .rijkslogo-link {
		margin-bottom: 0.8rem;
		border-bottom: 0;
		align-self: flex-start;
	}
	:where(.legacy) .rijkslogo-link svg {
		width: 30px;
		height: 53px;
	}
	@media (min-width: 35em) {
		:where(.legacy) .rijkslogo-link svg {
			width: 36px;
			height: 63px;
		}
	}
	@media (min-width: 55em) {
		:where(.legacy) .rijkslogo-link svg {
			width: 40px;
			height: 70px;
		}
	}
	@media (min-width: 81em) {
		:where(.legacy) .rijkslogo-link svg {
			width: 44px;
			height: 77px;
		}
	}

	:where(.legacy) .rijkslogo-bg {
		fill: #154273;
	}

	:where(.legacy) .rijkslogo-weapon {
		fill: #ffffff;
	}

	:where(.legacy) .search-close {
		z-index: 4;
		left: 1rem;
		position: absolute;
	}
	:where(.legacy) .search-close .close-icon {
		fill: #033054;
	}

	:where(.legacy) .mainMenu {
		display: flex;
		flex-direction: column;
		height: 100%;
		position: relative;
	}
	@media (min-width: 40em) {
		:where(.legacy) .mainMenu {
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
		}
	}
	:where(.legacy) .mainMenu-close {
		z-index: 4;
		left: 1rem;
		position: absolute;
	}
	:where(.legacy) .mainMenu-close .close-icon {
		fill: #033054;
	}
	:where(.legacy) .mainMenu-nav {
		height: 100%;
	}
	:where(.legacy) .mainMenu-nav .mainMenu-desktop {
		font-size: 0.875rem;
	}

	@media (min-width: 40em) and (min-height: 40em) {
		:where(.legacy) .mainMenu-nav {
			margin-top: 1.75rem;
			padding-left: 4rem;
		}
	}
	@media (min-width: 40em) {
		:where(.legacy) .mainMenu-nav {
			display: flex;
			flex-direction: column;
			flex: 1 1 0;
		}
	}
	@media (min-width: 40em) and (max-height: 39.9375em) {
		:where(.legacy) .mainMenu-nav {
			margin-top: 1.75rem;
			padding-left: 4rem;
		}
	}
	@media (min-width: 40em) {
		:where(.legacy) .mainMenu .list--triple {
			-moz-column-count: 2;
			column-count: 2;
		}
	}
	@media (min-width: 81em) {
		:where(.legacy) .mainMenu .list--triple {
			-moz-column-count: 3;
			column-count: 3;
		}
	}
	:where(.legacy) .mainMenu-title {
		flex: 0 0 auto;
		margin: 0;
		padding: 0.2rem 1rem;
		cursor: pointer;
		background: rgba(226, 240, 247, 0.9);
	}
	:where(.legacy) .mainMenu-title {
		line-height: 1.6;
	}
	:where(.legacy) .mainMenu-title-container {
		display: flex;
		align-items: center;
	}
	:where(.legacy) .mainMenu-title-icon {
		width: 0.7rem;
		height: 0.7rem;
		margin-right: 1rem;
		fill: #033054;
	}
	:where(.legacy) .mainMenu-title-text {
		font-weight: bold;
		margin: 0;
	}
	:where(.legacy) .no-touch .mainMenu-title:hover .mainMenu-title-text,
	:where(.legacy) .no-touch .mainMenu-title:focus .mainMenu-title-text,
	:where(.legacy) .no-touch .mainMenu-title:active .mainMenu-title-text {
		text-decoration: none;
		border-bottom: 2px solid;
	}

	@media (min-width: 40em) and (min-height: 40em) {
		:where(.legacy) .mainMenu-title {
			cursor: auto;
			margin-top: 1rem;
			padding-top: 0.1rem;
			padding-bottom: 0.1rem;
		}
		:where(.legacy) .no-touch .mainMenu-title:hover .mainMenu-title-text,
		:where(.legacy) .no-touch .mainMenu-title:focus .mainMenu-title-text,
		:where(.legacy) .no-touch .mainMenu-title:active .mainMenu-title-text {
			text-decoration: none;
			border-bottom: none;
		}
	}
	:where(.legacy) .mainMenu-button {
		background: none;
		border: none;
		padding: 0;
		color: inherit;
		font-weight: inherit;
		line-height: inherit;
		text-align: left;
		text-transform: none;
		display: inline-block;
		width: 2rem;
		height: 1.5rem;
		vertical-align: -31%;
	}

	@media (min-width: 40em) and (min-height: 40em) {
		:where(.legacy) .mainMenu-button {
			display: none;
		}
	}
	:where(.legacy) .mainMenu-content {
		flex: 0 1 auto;
		overflow: auto;
		overflow-x: hidden;
		padding: 0;
	}
	:where(.legacy) .mainMenu-content.ng-animate {
		overflow: hidden;
	}
	:where(.legacy) .mainMenu-content-background {
		background: rgba(255, 255, 255, 0.6);
	}

	@media (min-width: 40em) and (min-height: 40em) {
		:where(.legacy) .mainMenu-content-background {
			background: none;
		}
	}
	:where(.legacy) .mainMenu-linkList {
		-moz-column-gap: 0;
		column-gap: 0;
		padding: 0.2rem 0;
		margin-left: 0;
		margin-right: 0;
	}

	@media (min-width: 40em) and (min-height: 40em) {
		:where(.legacy) .mainMenu-linkList {
			padding: 0;
		}
	}
	:where(.legacy) .mainMenu-item {
		margin: 0;
		padding: 2px;
	}
	:where(.legacy) .mainMenu-item:last-child,
	:where(.legacy) .mainMenu-item:first-child {
		z-index: 3;
		background: #e2f0f7;
		position: relative;
	}
	:where(.legacy) .mainMenu-link {
		display: block;
		text-decoration: none;
		border-bottom: none;
		padding: 0.2rem 0.875rem 0.2rem 0.625rem;
		color: #0670b2;
		transition: color 0.2s;
	}
	@media (min-width: 40em) {
		:where(.legacy) .mainMenu-link {
			display: inline-block;
		}
	}
	@media (max-width: 39.9375em) {
		:where(.legacy) .mainMenu-link {
			margin-left: 1.7rem;
		}
	}
	:where(.legacy) .mainMenu-link.highlighted {
		background-color: white;
		font-weight: bold;
	}
	:where(.legacy) .mainMenu-link-icon {
		width: 1.5rem;
		height: 1.5rem;
		margin: -0.2rem 0.8rem -0.2rem 0;
		position: relative;
		left: 0;
		fill: #0670b2;
		transition: left 0.2s ease-in-out;
	}
	:where(.legacy) .mainMenu-link-icon-chevron {
		width: 0.9rem;
		height: 0.9rem;
	}
	:where(.legacy) .mainMenu-link:hover,
	:where(.legacy) .mainMenu-link:focus,
	:where(.legacy) .mainMenu-link:active {
		color: #033054;
	}
	:where(.legacy) .mainMenu-link:hover .mainMenu-link-icon,
	:where(.legacy) .mainMenu-link:focus .mainMenu-link-icon,
	:where(.legacy) .mainMenu-link:active .mainMenu-link-icon {
		left: 5px;
		fill: #033054;
	}
	@media (max-width: 39.9375em) {
		:where(.legacy) .mainMenu-desktop {
			display: none;
		}
	}
	:where(.legacy) .mainMenu-desktop-list {
		/* 
				Hack for Safari on Mac, to circumvent bug that 
				hides menu lists due to overflow-y on .MainMenu
			*/
		transform: translateZ(0);
	}
	:where(.legacy) .mainMenu-desktop-list-container:first-child .mainMenu-title {
		margin-top: 0;
	}
	:where(.legacy) .mainMenu-mobile {
		display: none;
	}
	@media (max-width: 39.9375em) {
		:where(.legacy) .mainMenu-mobile {
			display: flex;
			overflow: auto;
			height: 100%;
			flex-direction: column;
			padding-top: 4rem;
		}
	}
	:where(.legacy) .mainMenu-mobile .mainMenu-item {
		list-style-type: none;
	}
	:where(.legacy) .mainMenu-mobile .homeLink .mainMenu-link {
		margin-left: 1.7rem;
		color: #033054;
	}

	:where(.legacy) .overviewPageTitle {
		background: #0077c0;
	}
	:where(.legacy) .overviewPageTitle.overviewPageTitle--blue {
		background: #0077c0;
	}
	:where(.legacy) .overviewPageTitle.overviewPageTitle--aqua {
		background: #00857c;
	}
	:where(.legacy) .overviewPageTitle.overviewPageTitle--grey {
		background: #8f8f8f;
	}
	:where(.legacy) .overviewPageTitle.overviewPageTitle--purple {
		background: #42145f;
	}
	:where(.legacy) .overviewPageTitle.overviewPageTitle--green {
		background: #727800;
	}
	:where(.legacy) .overviewPageTitle.overviewPageTitle--violet {
		background: #a90061;
	}
	:where(.legacy) .overviewPageTitle.overviewPageTitle--orange {
		background: #e27000;
	}

	:where(.legacy) .overviewPageTitle-text {
		color: #ffffff;
		margin-top: 0.4rem;
		margin-bottom: 0.3rem;
	}
	@media (min-width: 81em) {
		:where(.legacy) .overviewPageTitle-text {
			margin-top: 0.6rem;
			margin-bottom: 0.5rem;
		}
	}

	:where(.legacy) .overviewPageTitle--transparent.overviewPageTitle--blue {
		background-color: rgba(3, 48, 84, 0.8);
	}

	:where(.legacy) .overviewPageTitle--transparent.overviewPageTitle--violet {
		background-color: rgba(169, 0, 97, 0.8);
	}

	:where(.legacy) .overviewPageTitle--transparent.overviewPageTitle--purple {
		background-color: rgba(66, 20, 95, 0.8);
	}

	:where(.legacy) .overviewPageTitle--transparent.overviewPageTitle--green {
		background-color: rgba(114, 120, 0, 0.8);
	}

	:where(.legacy) .overviewPageTitle--transparent.overviewPageTitle--grey {
		background-color: rgba(143, 143, 143, 0.8);
	}

	:where(.legacy) .pageTitle {
		display: flex;
		align-items: flex-start;
	}

	:where(.legacy) .pageTitle-mobile {
		background: #d9c7e3;
		color: #42145f;
		margin: 0;
		padding-left: 1rem;
		padding-right: 1rem;
		padding-bottom: 0.5rem;
	}

	:where(.legacy) .pageTitle-mobile-title {
		flex-grow: 1;
		padding-top: 0.5rem;
	}

	:where(.legacy) .pageContentNav,
	:where(.legacy) .readMoreBlock {
		padding: 1em;
		margin-bottom: 1em;
		display: block;
		clear: both;
	}
	:where(.legacy) .pageContentNav,
	:where(.legacy) .readMoreBlock {
		font-size: 0.875rem;
	}
	:where(.legacy) .pageContentNav--title,
	:where(.legacy) .readMoreBlock--title {
		margin-top: 0;
		margin-bottom: 0.5em;
		font-weight: normal;
	}
	:where(.legacy) .pageContentNav.block--grey .interfaceLink,
	:where(.legacy) .readMoreBlock.block--grey .interfaceLink {
		color: #0670b2;
		fill: #0670b2;
	}
	:where(.legacy) .pageContentNav.block--grey .interfaceLink:hover,
	:where(.legacy) .pageContentNav.block--grey .interfaceLink:active,
	:where(.legacy) .pageContentNav.block--grey .interfaceLink:focus,
	:where(.legacy) .readMoreBlock.block--grey .interfaceLink:hover,
	:where(.legacy) .readMoreBlock.block--grey .interfaceLink:active,
	:where(.legacy) .readMoreBlock.block--grey .interfaceLink:focus {
		color: #0670b2;
		fill: #0670b2;
	}

	:where(.legacy) .scBlock {
		position: relative;
		margin: 2rem 0;
	}
	:where(.legacy) .scBlock-main {
		background-color: #42145f;
		color: #ffffff;
		margin: 0;
		display: inline-block;
		padding: 0.5rem;
		transition: background-color 400ms;
	}
	:where(.legacy) .scBlock-main.ng-animate {
		overflow: hidden;
	}
	:where(.legacy) .scBlock-main.is-opened {
		display: block;
	}
	:where(.legacy) .scBlock-title {
		color: #ffffff;
		margin-bottom: 0;
	}
	:where(.legacy) .scBlock-title-media {
		align-items: center;
	}
	:where(.legacy) .scBlock-title-text {
		margin-left: 0.5rem;
		margin-right: 0.2rem;
		text-align: left;
	}
	:where(.legacy) .scBlock-subTitle {
		font-size: 0.875rem;
	}
	:where(.legacy) .scBlock-button {
		border: none;
		padding: 0;
		background: none;
		display: block;
	}
	:where(.legacy) .scBlock-icon {
		fill: #ffffff;
		height: 2rem;
		width: 2rem;
	}
	:where(.legacy) .scBlock-goIcon {
		fill: #ffffff;
		height: 1.2em;
		width: 1.2em;
		margin-bottom: 0.1em;
	}
	:where(.legacy) .scBlock-content {
		padding: 0.1rem 0.5rem 0.5rem 2.5rem;
	}
	:where(.legacy) .scBlock-form {
		position: relative;
	}
	:where(.legacy) .scBlock-clearIcon {
		position: absolute;
		top: 8px;
		right: 8px;
		height: 1.25rem;
		width: 1.25rem;
	}
	:where(.legacy) .scBlock-results.ng-animate {
		overflow: hidden;
	}
	:where(.legacy) .scBlock-result {
		color: #42145f;
		background-color: #d9c7e3;
		margin-top: 3px;
	}
	:where(.legacy) .scBlock-result {
		font-size: 0.875rem;
	}
	:where(.legacy) .scBlock-resultLink {
		color: #42145f;
		fill: #42145f;
		padding: 0.35em 0.35em 0.35em 3rem;
		display: flex;
	}
	:where(.legacy) .scBlock-resultLink:hover,
	:where(.legacy) .scBlock-resultLink:focus,
	:where(.legacy) .scBlock-resultLink:active {
		background-color: #5f3977;
		color: #42145f;
	}
	:where(.legacy) .scBlock-resultLink:hover .interfaceLink-text span,
	:where(.legacy) .scBlock-resultLink:focus .interfaceLink-text span,
	:where(.legacy) .scBlock-resultLink:active .interfaceLink-text span {
		text-decoration: none;
		border-bottom: 2px solid;
	}
	:where(.legacy) .scBlock-resultLink .interfaceLink-icon {
		height: 0.9rem;
		width: 0.9rem;
		margin-top: 0.15rem;
		margin-bottom: 0.15rem;
	}
	:where(.legacy) .scBlock-noResult {
		padding: 0.35em 0.35em 0.35em 5em;
		background-color: #f9dfdd;
		margin-top: 3px;
	}
	:where(.legacy) .scBlock-noResultText {
		color: #333333;
		font-style: italic;
		display: block;
		margin-bottom: 0.5em;
	}
	:where(.legacy) .scBlock-noResultText {
		font-size: 0.875rem;
	}
	:where(.legacy) .scBlock-noResultLink {
		color: #333333;
		fill: #333333;
	}
	:where(.legacy) .scBlock-noResultLink:hover,
	:where(.legacy) .scBlock-noResultLink:focus,
	:where(.legacy) .scBlock-noResultLink:active {
		color: #333333;
	}
	:where(.legacy) .scBlock-noResultLink .interfaceLink-icon {
		height: 0.9rem;
		width: 0.9rem;
		margin-top: 2px;
	}

	:where(.legacy) .counter {
		position: relative;
	}
	:where(.legacy) .counter-original {
		visibility: hidden;
		display: block;
	}
	:where(.legacy) .counter-overlay {
		speak: none;
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		text-align: right;
	}

	:where(.legacy) .anchorBlock {
		padding: 0 1rem;
		border-top: 2px solid;
		position: relative;
	}
	:where(.legacy) .anchorBlock:before {
		content: '';
		display: block;
		margin-top: -1px;
		padding-top: 1px;
		margin-bottom: 0.5rem;
	}
	:where(.legacy) .anchorBlock:after {
		content: '';
		display: block;
		margin-bottom: -1px;
		padding-bottom: 1px;
		margin-top: 0.5rem;
	}
	:where(.legacy) .anchorBlock.anchorBlock--blue {
		border-top-color: #0670b2;
		background-color: rgba(243, 249, 255, 0.9);
	}
	:where(.legacy) .anchorBlock.anchorBlock--aqua {
		border-top-color: #00857c;
		background-color: rgba(0, 133, 124, 0.9);
	}
	:where(.legacy) .anchorBlock.anchorBlock--grey {
		border-top-color: #8f8f8f;
		background-color: rgba(246, 246, 246, 0.9);
	}
	:where(.legacy) .anchorBlock.anchorBlock--purple {
		border-top-color: #310d47;
		background-color: rgba(217, 199, 227, 0.9);
	}
	:where(.legacy) .anchorBlock.anchorBlock--green {
		border-top-color: #686b00;
		background-color: rgba(238, 238, 217, 0.9);
	}
	:where(.legacy) .anchorBlock.anchorBlock--violet {
		border-top-color: #a90061;
		background-color: rgba(244, 233, 241, 0.9);
	}
	:where(.legacy) .anchorBlock.anchorBlock--orange {
		border-top-color: #e27000;
		background-color: rgba(226, 112, 0, 0.9);
	}
	:where(.legacy) .anchorBlock-title {
		margin-top: 0;
	}
	:where(.legacy) .anchorBlock--blue .anchorBlock-title {
		color: #0670b2;
	}
	:where(.legacy) .anchorBlock--aqua .anchorBlock-title {
		color: #00857c;
	}
	:where(.legacy) .anchorBlock--grey .anchorBlock-title {
		color: #8f8f8f;
	}
	:where(.legacy) .anchorBlock--purple .anchorBlock-title {
		color: #310d47;
	}
	:where(.legacy) .anchorBlock--green .anchorBlock-title {
		color: #686b00;
	}
	:where(.legacy) .anchorBlock--violet .anchorBlock-title {
		color: #a90061;
	}
	:where(.legacy) .anchorBlock--orange .anchorBlock-title {
		color: #e27000;
	}
	:where(.legacy) .anchorBlock-link {
		color: #033054;
	}
	:where(.legacy) .anchorBlock-link:hover,
	:where(.legacy) .anchorBlock-link:focus,
	:where(.legacy) .anchorBlock-link:active {
		color: #033054;
	}
	:where(.legacy) .anchorBlock--blue .interfaceLink-icon {
		fill: #0670b2;
	}
	:where(.legacy) .anchorBlock--aqua .interfaceLink-icon {
		fill: #00857c;
	}
	:where(.legacy) .anchorBlock--grey .interfaceLink-icon {
		fill: #8f8f8f;
	}
	:where(.legacy) .anchorBlock--purple .interfaceLink-icon {
		fill: #310d47;
	}
	:where(.legacy) .anchorBlock--green .interfaceLink-icon {
		fill: #686b00;
	}
	:where(.legacy) .anchorBlock--violet .interfaceLink-icon {
		fill: #a90061;
	}
	:where(.legacy) .anchorBlock--orange .interfaceLink-icon {
		fill: #e27000;
	}

	:where(.legacy) .alphabet-list {
		position: relative;
		left: -0.42em;
		color: #033054;
		font-weight: bold;
	}
	@media (min-width: 57em) {
		:where(.legacy) .alphabet-list {
			left: -0.75em;
		}
	}
	@media (min-width: 70em) {
		:where(.legacy) .alphabet-list {
			left: -0.75em;
		}
	}
	:where(.legacy) .alphabet-list-item {
		padding: 0.65em 1.1em;
		display: inline-block;
		border: 0;
		text-decoration: none;
	}
	:where(.legacy) .alphabet-list-item .underliner {
		border-bottom-color: transparent;
		transition: border-bottom-color 0.3s ease-in-out;
	}
	@media (min-width: 57em) {
		:where(.legacy) .alphabet-list-item {
			padding: 0.62em;
		}
	}
	@media (min-width: 70em) {
		:where(.legacy) .alphabet-list-item {
			padding: 0.8em;
		}
	}
	:where(.legacy) .alphabet-list-item:hover .underliner,
	:where(.legacy) .alphabet-list-item:focus .underliner,
	:where(.legacy) .alphabet-list-item:active .underliner {
		border-bottom: 2px solid #033054;
	}
	:where(.legacy) .alphabet-list--blue .alphabet-list-item:hover .underliner,
	:where(.legacy) .alphabet-list--blue .alphabet-list-item:focus .underliner,
	:where(.legacy) .alphabet-list--blue .alphabet-list-item:active .underliner {
		color: #0077c0;
		border-bottom-color: #0077c0;
	}
	:where(.legacy) .alphabet-list--aqua .alphabet-list-item:hover .underliner,
	:where(.legacy) .alphabet-list--aqua .alphabet-list-item:focus .underliner,
	:where(.legacy) .alphabet-list--aqua .alphabet-list-item:active .underliner {
		color: #00857c;
		border-bottom-color: #00857c;
	}
	:where(.legacy) .alphabet-list--grey .alphabet-list-item:hover .underliner,
	:where(.legacy) .alphabet-list--grey .alphabet-list-item:focus .underliner,
	:where(.legacy) .alphabet-list--grey .alphabet-list-item:active .underliner {
		color: #8f8f8f;
		border-bottom-color: #8f8f8f;
	}
	:where(.legacy) .alphabet-list--purple .alphabet-list-item:hover .underliner,
	:where(.legacy) .alphabet-list--purple .alphabet-list-item:focus .underliner,
	:where(.legacy)
		.alphabet-list--purple
		.alphabet-list-item:active
		.underliner {
		color: #42145f;
		border-bottom-color: #42145f;
	}
	:where(.legacy) .alphabet-list--green .alphabet-list-item:hover .underliner,
	:where(.legacy) .alphabet-list--green .alphabet-list-item:focus .underliner,
	:where(.legacy) .alphabet-list--green .alphabet-list-item:active .underliner {
		color: #727800;
		border-bottom-color: #727800;
	}
	:where(.legacy) .alphabet-list--violet .alphabet-list-item:hover .underliner,
	:where(.legacy) .alphabet-list--violet .alphabet-list-item:focus .underliner,
	:where(.legacy)
		.alphabet-list--violet
		.alphabet-list-item:active
		.underliner {
		color: #a90061;
		border-bottom-color: #a90061;
	}
	:where(.legacy) .alphabet-list--orange .alphabet-list-item:hover .underliner,
	:where(.legacy) .alphabet-list--orange .alphabet-list-item:focus .underliner,
	:where(.legacy)
		.alphabet-list--orange
		.alphabet-list-item:active
		.underliner {
		color: #e27000;
		border-bottom-color: #e27000;
	}
	:where(.legacy) .alphabet-list-item--selected {
		background-color: #ffffff;
	}
	:where(.legacy) .alphabet-list-item--selected .underliner {
		border-bottom-color: transparent !important;
		/* stylelint-disable-line declaration-no-important */
	}
	:where(.legacy) .alphabet-list-item.inactive {
		opacity: 0.25;
	}

	:where(.legacy) .lineBlock {
		padding-top: 0.75rem;
		border-top-width: 3px;
		border-top-style: solid;
		border-top-color: #033054;
	}
	:where(.legacy) .lineBlock.lineBlock--blue {
		border-top-color: #0077c0;
	}
	:where(.legacy) .lineBlock.lineBlock--aqua {
		border-top-color: #00857c;
	}
	:where(.legacy) .lineBlock.lineBlock--grey {
		border-top-color: #8f8f8f;
	}
	:where(.legacy) .lineBlock.lineBlock--purple {
		border-top-color: #42145f;
	}
	:where(.legacy) .lineBlock.lineBlock--green {
		border-top-color: #727800;
	}
	:where(.legacy) .lineBlock.lineBlock--violet {
		border-top-color: #a90061;
	}
	:where(.legacy) .lineBlock.lineBlock--orange {
		border-top-color: #e27000;
	}
	:where(.legacy) .lineBlock .h2,
	:where(.legacy) .lineBlock .menuBar--title,
	:where(.legacy) .lineBlock .search-header-title,
	:where(.legacy) .lineBlock .h3,
	:where(.legacy) .lineBlock .h4 {
		margin-top: 0;
	}
	:where(.legacy) .lineBlock:focus {
		outline: none;
	}

	:where(.legacy) .content-block {
		background-color: #d5e9f6;
		padding: 1rem;
	}
	:where(.legacy) .content-block h2,
	:where(.legacy) .content-block .h2,
	:where(.legacy) .content-block .menuBar--title,
	:where(.legacy) .content-block .search-header-title,
	:where(.legacy) .content-block h3,
	:where(.legacy) .content-block .h3,
	:where(.legacy) .content-block h4,
	:where(.legacy) .content-block .h4 {
		margin-top: 0;
	}

	:where(.legacy) .content-block--regelhulp {
		padding-top: 1.5rem;
		padding-left: 0;
		background-color: #ffffff;
		border-top: 3px solid #a90061;
	}

	:where(.legacy) .content-block--regelhulp h2,
	:where(.legacy) .content-block--regelhulp h3 {
		color: #a90061;
	}

	:where(.legacy) .content-block--regelhulp .content-block__icon-wrapper {
		width: 3rem;
		height: 3rem;
		padding: 0.5rem;
		background-color: #a90061;
		border-radius: 100%;
	}

	:where(.legacy) .content-block--regelhulp .content-block__icon {
		fill: #ffffff;
	}

	:where(.legacy) .content-block--regelhulp .content-block__content {
		padding-top: 0.5rem;
	}
	:where(.legacy) .content-block--regelhulp .content-block__content a {
		color: #a90061;
	}

	:where(.legacy) .content-block__icon-wrapper {
		width: 2.5rem;
		margin-top: -2px;
	}

	:where(.legacy) .content-block__icon {
		fill: #0077c0;
	}

	:where(.legacy) .content-block__icon-wrapper + .content-block__content {
		padding-left: 1rem;
	}

	:where(.legacy) .themaLinks li {
		margin-right: 0.5rem;
		margin-bottom: 0.5rem;
	}

	:where(.legacy) .themaLinks-link {
		padding: 0.5rem 1rem;
		border: 0;
		background-color: #8fcae7;
		border-radius: 5px;
		transition: background-color ease-in-out 0.3s;
	}
	:where(.legacy) .themaLinks-link:hover,
	:where(.legacy) .themaLinks-link:focus,
	:where(.legacy) .themaLinks-link:active {
		background-color: #2d526c;
	}
	:where(.legacy) .themaLinks-link:hover .themaLinks-text,
	:where(.legacy) .themaLinks-link:focus .themaLinks-text,
	:where(.legacy) .themaLinks-link:active .themaLinks-text {
		color: #ffffff;
	}

	:where(.legacy) .themaLinks-text {
		color: #2d526c;
		transition: color ease-in-out 0.3s;
	}

	:where(.legacy) .more {
		font-weight: bold;
		line-height: 1.2;
		color: #e47237;
		fill: #e47237;
		margin: 0;
		text-decoration: none;
		border-bottom: none;
	}

	:where(.legacy) .more-icon {
		width: 1.2rem;
		vertical-align: middle;
		margin-left: 0.4rem;
	}

	:where(.legacy) .previous-button {
		flex: 1 1 auto;
		fill: white;
	}
	:where(.legacy) .previous-button {
		line-height: 1.2;
	}

	@-webkit-keyframes load {
		80% {
			height: 0;
		}
		100% {
			height: 8px;
		}
	}

	@keyframes load {
		80% {
			height: 0;
		}
		100% {
			height: 8px;
		}
	}

	:where(.legacy) .homepageHeader-container {
		padding-top: 2rem;
		padding-bottom: 1.5rem;
	}

	:where(.legacy) .homepageHeader--has-image .homepageHeader-container {
		min-height: 20rem;
		padding-top: 0;
		padding-bottom: 0;
	}

	:where(.legacy) .homepageHeader--has-image .topTasks {
		width: 100%;
	}
	@media (min-width: 70em) {
		:where(.legacy) .homepageHeader--has-image .topTasks {
			width: calc(66.66667% - 2 * var(--grid-item-margin-inline) - 0.01rem);
		}
	}

	:where(.legacy) .homepageHeader-topTasks {
		position: relative;
		width: calc(100% - 2 * var(--grid-item-margin-inline) - 0.01rem);
	}
	:where(.legacy) .homepageHeader-topTasks:before {
		content: '';
		display: block;
		margin-top: -1px;
		padding-top: 1px;
		margin-bottom: 1.5rem;
	}
	:where(.legacy) .homepageHeader-topTasks:after {
		content: '';
		display: block;
		margin-bottom: -1px;
		padding-bottom: 1px;
		margin-top: 1.5rem;
	}
	@media (min-width: 40em) {
		:where(.legacy) .homepageHeader-topTasks {
			padding-bottom: 3rem;
		}
		:where(.legacy) .homepageHeader-topTasks:after {
			content: '';
			display: block;
			margin-bottom: -1px;
			padding-bottom: 1px;
			margin-top: 2.5rem;
		}
	}
	@media (min-width: 40em) {
		:where(.legacy) .homepageHeader-topTasks {
			width: calc(66.66667% - 2 * var(--grid-item-margin-inline) - 0.01rem);
		}
	}

	:where(.legacy) .homepageHeader-background {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		overflow: hidden;
	}
	:where(.legacy) .homepageHeader-background-image {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 66% 90%;
	}
	@media (max-width: 49.9375em) {
		:where(.legacy) .homepageHeader-background-image {
			left: 0rem;
		}
	}
	@media (max-width: 39.9375em) {
		:where(.legacy) .homepageHeader-background-image:after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background-image: linear-gradient(
				to bottom,
				rgba(255, 255, 255, 0.5),
				rgba(255, 255, 255, 0) 44%
			);
		}
	}
	@media (max-width: 34.9375em) {
		:where(.legacy) .homepageHeader-background-image {
			left: 0rem;
		}
	}

	:where(.legacy) .homeHeading {
		margin-top: 1.5rem;
		margin-bottom: 2rem;
		color: #ffffff;
		align-self: flex-start;
		text-shadow: 0 0 10px #000000;
	}

	:where(.legacy) .homeTopTasksWrapper {
		align-self: flex-end;
	}

	:where(.legacy) .homeHeroContainer,
	:where(.legacy) .homeHeroForeground {
		position: relative;
		min-height: 40vw;
	}
	@media (min-width: 50em) {
		:where(.legacy) .homeHeroContainer,
		:where(.legacy) .homeHeroForeground {
			min-height: 30vw;
		}
	}
	@media (min-width: 57em) {
		:where(.legacy) .homeHeroContainer,
		:where(.legacy) .homeHeroForeground {
			min-height: 25vw;
		}
	}

	:where(.legacy) .homeHeroContainer {
		background-color: #e2f0f7;
	}

	:where(.legacy) .homeHeroImage:after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-image: linear-gradient(
			to top,
			rgba(0, 0, 0, 0.4),
			rgba(0, 0, 0, 0) 50%
		);
	}

	:where(.legacy) .region-search {
		background-color: #d6d7b2;
	}
	:where(.legacy) .region-search.region-search--blue {
		background-color: rgba(243, 249, 255, 0.9);
	}
	:where(.legacy) .region-search.region-search--aqua {
		background-color: rgba(0, 133, 124, 0.9);
	}
	:where(.legacy) .region-search.region-search--grey {
		background-color: rgba(246, 246, 246, 0.9);
	}
	:where(.legacy) .region-search.region-search--purple {
		background-color: rgba(217, 199, 227, 0.9);
	}
	:where(.legacy) .region-search.region-search--green {
		background-color: rgba(238, 238, 217, 0.9);
	}
	:where(.legacy) .region-search.region-search--violet {
		background-color: rgba(244, 233, 241, 0.9);
	}
	:where(.legacy) .region-search.region-search--orange {
		background-color: rgba(226, 112, 0, 0.9);
	}
	:where(.legacy) .region-search-intro {
		margin-top: 1rem;
		margin-bottom: 1rem;
	}
	:where(.legacy) .region-search-box {
		display: flex;
		align-items: center;
		margin-top: 1rem;
		padding-bottom: 0.75rem;
	}
	@media (max-width: 39.9375em) {
		:where(.legacy) .region-search-box {
			display: block;
		}
	}
	:where(.legacy) .region-search-box-input {
		width: 100%;
	}
	@media (min-width: 40em) {
		:where(.legacy) .region-search-box-input {
			width: 66%;
		}
	}
	@media (min-width: 50em) {
		:where(.legacy) .region-search-box-input {
			width: 50%;
		}
	}
	:where(.legacy) .region-search-box-title {
		margin: 0;
		margin-right: 1rem;
		padding-bottom: 0;
		font-weight: bold;
		color: #033054;
	}

	@media (min-width: 40em) {
		:where(.legacy) .region-list {
			-moz-column-count: 2;
			column-count: 2;
		}
	}

	@media (min-width: 50em) {
		:where(.legacy) .region-list {
			-moz-column-count: 3;
			column-count: 3;
		}
	}

	:where(.legacy) .region-list-group {
		padding-top: 1rem;
		border-top-width: 3px;
		border-top-style: solid;
	}
	:where(.legacy) .region-list-group.region-list-group--blue {
		border-top-color: #0670b2;
	}
	:where(.legacy) .region-list-group.region-list-group--aqua {
		border-top-color: #00857c;
	}
	:where(.legacy) .region-list-group.region-list-group--grey {
		border-top-color: #8f8f8f;
	}
	:where(.legacy) .region-list-group.region-list-group--purple {
		border-top-color: #310d47;
	}
	:where(.legacy) .region-list-group.region-list-group--green {
		border-top-color: #686b00;
	}
	:where(.legacy) .region-list-group.region-list-group--violet {
		border-top-color: #a90061;
	}
	:where(.legacy) .region-list-group.region-list-group--orange {
		border-top-color: #e27000;
	}

	:where(.legacy) .region-list-title {
		margin: 0;
		margin-bottom: 0;
		color: #0077c0;
	}
	:where(.legacy) .region-list-title.region-list-title--blue {
		color: #0670b2;
	}
	:where(.legacy) .region-list-title.region-list-title--aqua {
		color: #00857c;
	}
	:where(.legacy) .region-list-title.region-list-title--grey {
		color: #8f8f8f;
	}
	:where(.legacy) .region-list-title.region-list-title--purple {
		color: #310d47;
	}
	:where(.legacy) .region-list-title.region-list-title--green {
		color: #686b00;
	}
	:where(.legacy) .region-list-title.region-list-title--violet {
		color: #a90061;
	}
	:where(.legacy) .region-list-title.region-list-title--orange {
		color: #e27000;
	}

	:where(.legacy) .region-list-item {
		display: inline-flex;
		width: 100%;
		-moz-column-break-inside: avoid;
		break-inside: avoid;
		margin-bottom: 1.5rem;
	}
	:where(.legacy) .region-list-item button,
	:where(.legacy) .region-list-item .headingLink {
		background: none;
		border: none;
		padding: 0;
		color: inherit;
		font-weight: inherit;
		line-height: inherit;
		text-align: left;
		text-transform: none;
		align-items: flex-start;
		font-weight: bold;
		width: auto;
	}
	:where(.legacy) .region-list-item button:hover,
	:where(.legacy) .region-list-item button:focus,
	:where(.legacy) .region-list-item button:active,
	:where(.legacy) .region-list-item .headingLink:hover,
	:where(.legacy) .region-list-item .headingLink:focus,
	:where(.legacy) .region-list-item .headingLink:active {
		color: #0077c0;
		fill: #0077c0;
	}
	:where(.legacy) .region-list-item button .goIcon:first-child,
	:where(.legacy) .region-list-item .headingLink .goIcon:first-child {
		margin-right: 1rem;
	}

	:where(.legacy) .region-backButton {
		background: none;
		border: none;
		padding: 0;
		color: inherit;
		font-weight: inherit;
		line-height: inherit;
		text-align: left;
		text-transform: none;
		margin-bottom: 1.5rem;
	}
	:where(.legacy) .region-backButton .goIcon {
		margin-right: 0.5rem;
	}

	:where(.legacy) .region-logo {
		margin-right: 1rem;
		margin-top: 0.18rem;
		width: 5rem;
		height: 5rem;
		padding: 0.5rem;
		border: 1px solid #39870c;
	}
	@supports ((-o-object-fit: contain) or (object-fit: contain)) {
		:where(.legacy) .region-logo-image {
			-o-object-fit: contain;
			object-fit: contain;
			height: 100%;
			width: 100%;
		}
	}

	@media (max-width: 39.9375em) {
		:where(.legacy) .region-contact-block {
			margin-top: 1rem;
		}
	}

	:where(.legacy) .eventMeetingAsideBlock {
		margin-top: 2rem;
	}
	@media (max-width: 69.9375em) {
		:where(.legacy) .eventMeetingAsideBlock {
			width: calc(100% - 2 * var(--grid-item-margin-inline) - 0.01rem);
		}
	}

	:where(.legacy) .eventMeetingAside.eventMeetingAside--blue {
		color: #0077c0;
		fill: #0077c0;
	}

	:where(.legacy) .eventMeetingAside.eventMeetingAside--aqua {
		color: #00857c;
		fill: #00857c;
	}

	:where(.legacy) .eventMeetingAside.eventMeetingAside--grey {
		color: #8f8f8f;
		fill: #8f8f8f;
	}

	:where(.legacy) .eventMeetingAside.eventMeetingAside--purple {
		color: #42145f;
		fill: #42145f;
	}

	:where(.legacy) .eventMeetingAside.eventMeetingAside--green {
		color: #727800;
		fill: #727800;
	}

	:where(.legacy) .eventMeetingAside.eventMeetingAside--violet {
		color: #a90061;
		fill: #a90061;
	}

	:where(.legacy) .eventMeetingAside.eventMeetingAside--orange {
		color: #e27000;
		fill: #e27000;
	}

	:where(.legacy) .eventMeetingAside-interfaceLinks {
		margin-top: 1rem;
		font-weight: bold;
	}

	:where(.legacy) .eventMeetingAside-availability {
		margin-top: 1rem;
		color: #d52b1e;
		font-weight: bold;
	}

	:where(.legacy) .eventMeetingAside-registerButton {
		margin: 1rem 0 0 0;
		align-self: flex-start;
		font-weight: bold;
	}
	:where(.legacy) .eventMeetingAside--blue .eventMeetingAside-registerButton {
		background-color: #0077c0;
	}
	:where(.legacy) .eventMeetingAside--aqua .eventMeetingAside-registerButton {
		background-color: #00857c;
	}
	:where(.legacy) .eventMeetingAside--grey .eventMeetingAside-registerButton {
		background-color: #8f8f8f;
	}
	:where(.legacy) .eventMeetingAside--purple .eventMeetingAside-registerButton {
		background-color: #42145f;
	}
	:where(.legacy) .eventMeetingAside--green .eventMeetingAside-registerButton {
		background-color: #727800;
	}
	:where(.legacy) .eventMeetingAside--violet .eventMeetingAside-registerButton {
		background-color: #a90061;
	}
	:where(.legacy) .eventMeetingAside--orange .eventMeetingAside-registerButton {
		background-color: #e27000;
	}
	:where(.legacy) .eventMeetingAside-registerButton:hover,
	:where(.legacy) .eventMeetingAside-registerButton:focus,
	:where(.legacy) .eventMeetingAside-registerButton:active {
		color: white;
	}
	:where(.legacy)
		.eventMeetingAside--blue
		.eventMeetingAside-registerButton:hover,
	:where(.legacy)
		.eventMeetingAside--blue
		.eventMeetingAside-registerButton:focus,
	:where(.legacy)
		.eventMeetingAside--blue
		.eventMeetingAside-registerButton:active {
		background-color: #8fcae7;
	}
	:where(.legacy)
		.eventMeetingAside--aqua
		.eventMeetingAside-registerButton:hover,
	:where(.legacy)
		.eventMeetingAside--aqua
		.eventMeetingAside-registerButton:focus,
	:where(.legacy)
		.eventMeetingAside--aqua
		.eventMeetingAside-registerButton:active {
		background-color: #00857c;
	}
	:where(.legacy)
		.eventMeetingAside--grey
		.eventMeetingAside-registerButton:hover,
	:where(.legacy)
		.eventMeetingAside--grey
		.eventMeetingAside-registerButton:focus,
	:where(.legacy)
		.eventMeetingAside--grey
		.eventMeetingAside-registerButton:active {
		background-color: #dddddd;
	}
	:where(.legacy)
		.eventMeetingAside--purple
		.eventMeetingAside-registerButton:hover,
	:where(.legacy)
		.eventMeetingAside--purple
		.eventMeetingAside-registerButton:focus,
	:where(.legacy)
		.eventMeetingAside--purple
		.eventMeetingAside-registerButton:active {
		background-color: #5f3977;
	}
	:where(.legacy)
		.eventMeetingAside--green
		.eventMeetingAside-registerButton:hover,
	:where(.legacy)
		.eventMeetingAside--green
		.eventMeetingAside-registerButton:focus,
	:where(.legacy)
		.eventMeetingAside--green
		.eventMeetingAside-registerButton:active {
		background-color: #d6d7b2;
	}
	:where(.legacy)
		.eventMeetingAside--violet
		.eventMeetingAside-registerButton:hover,
	:where(.legacy)
		.eventMeetingAside--violet
		.eventMeetingAside-registerButton:focus,
	:where(.legacy)
		.eventMeetingAside--violet
		.eventMeetingAside-registerButton:active {
		background-color: #e5b2cf;
	}
	:where(.legacy)
		.eventMeetingAside--orange
		.eventMeetingAside-registerButton:hover,
	:where(.legacy)
		.eventMeetingAside--orange
		.eventMeetingAside-registerButton:focus,
	:where(.legacy)
		.eventMeetingAside--orange
		.eventMeetingAside-registerButton:active {
		background-color: #e27000;
	}

	:where(.legacy) .eventMeetingAside--blue .h4 {
		color: #0077c0;
	}

	:where(.legacy) .eventMeetingAside--aqua .h4 {
		color: #00857c;
	}

	:where(.legacy) .eventMeetingAside--grey .h4 {
		color: #8f8f8f;
	}

	:where(.legacy) .eventMeetingAside--purple .h4 {
		color: #42145f;
	}

	:where(.legacy) .eventMeetingAside--green .h4 {
		color: #727800;
	}

	:where(.legacy) .eventMeetingAside--violet .h4 {
		color: #a90061;
	}

	:where(.legacy) .eventMeetingAside--orange .h4 {
		color: #e27000;
	}

	:where(.legacy) .eventBlock {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: stretch;
	}

	:where(.legacy) .meetingResults {
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
	}
	@media (min-width: 40em) {
		:where(.legacy) .meetingResults {
			flex: 0 1 auto;
		}
	}

	:where(.legacy) .meetingResult {
		border-top: 3px solid;
		position: relative;
	}
	:where(.legacy) .meetingResult.meetingResult--blue {
		border-top-color: #0077c0;
	}
	:where(.legacy) .meetingResult.meetingResult--aqua {
		border-top-color: #00857c;
	}
	:where(.legacy) .meetingResult.meetingResult--grey {
		border-top-color: #8f8f8f;
	}
	:where(.legacy) .meetingResult.meetingResult--purple {
		border-top-color: #42145f;
	}
	:where(.legacy) .meetingResult.meetingResult--green {
		border-top-color: #727800;
	}
	:where(.legacy) .meetingResult.meetingResult--violet {
		border-top-color: #a90061;
	}
	:where(.legacy) .meetingResult.meetingResult--orange {
		border-top-color: #e27000;
	}
	@media (min-width: 40em) {
		:where(.legacy) .meetingResult {
			border-top-width: 1px;
		}
	}
	:where(.legacy) .meetingResult-link {
		transition: background-color 400ms;
		display: block;
		border: none;
		color: inherit;
	}
	:where(.legacy) .meetingResult-link:hover,
	:where(.legacy) .meetingResult-link:focus,
	:where(.legacy) .meetingResult-link:active {
		background-color: #d9c7e3;
		color: inherit;
	}
	@media (max-width: 39.9375em) {
		:where(.legacy) .meetingResult-spacedLink {
			padding-bottom: 3.9rem;
		}
	}
	@media (min-width: 40em) {
		:where(.legacy) .meetingResult-spacedLink {
			display: flex;
			align-items: flex-start;
			min-height: 4.4rem;
			padding-right: 11.2rem;
		}
	}
	@media (min-width: 40em) {
		:where(.legacy) .meetingResult-dateTime {
			width: 30%;
			min-width: 6rem;
			padding-right: 0.5rem;
			flex: 0 0 auto;
		}
		:where(.legacy) .meetingResult-dateTime-item {
			display: block;
		}
	}
	:where(.legacy) .meetingResult-title {
		display: block;
		margin-top: 0.1rem;
	}
	:where(.legacy) .meetingResult-title {
		font-size: 1.1rem;
	}
	:where(.legacy) .meetingResult--blue .meetingResult-title {
		color: #0077c0;
	}
	:where(.legacy) .meetingResult--aqua .meetingResult-title {
		color: #00857c;
	}
	:where(.legacy) .meetingResult--grey .meetingResult-title {
		color: #8f8f8f;
	}
	:where(.legacy) .meetingResult--purple .meetingResult-title {
		color: #42145f;
	}
	:where(.legacy) .meetingResult--green .meetingResult-title {
		color: #727800;
	}
	:where(.legacy) .meetingResult--violet .meetingResult-title {
		color: #a90061;
	}
	:where(.legacy) .meetingResult--orange .meetingResult-title {
		color: #e27000;
	}
	@media (min-width: 40em) {
		:where(.legacy) .meetingResult-title {
			padding-right: 0.5rem;
			padding-left: 0.5rem;
			flex: 1 0 auto;
		}
	}
	@media (min-width: 40em) {
		:where(.legacy) .meetingResult-availability {
			padding-right: 0.5rem;
			padding-left: 0.5rem;
			flex: 0 0 auto;
			width: 30%;
		}
	}
	:where(.legacy) .meetingResult-signup {
		position: absolute;
	}
	@media (max-width: 39.9375em) {
		:where(.legacy) .meetingResult-signup {
			left: 1rem;
			bottom: 1rem;
		}
	}
	@media (min-width: 40em) {
		:where(.legacy) .meetingResult-signup {
			right: 1rem;
			top: 1rem;
		}
	}

	:where(.legacy) .sector-overzicht-pageIntro {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	:where(.legacy) .sector-overzicht-list-item {
		border-bottom: none;
	}
	:where(.legacy) .sector-overzicht-list-item .promoBlock-figure:after {
		background: rgba(66, 20, 95, 0.4);
	}
	:where(.legacy) .sector-overzicht-list-item:hover .promoBlock-figure:after,
	:where(.legacy) .sector-overzicht-list-item:focus .promoBlock-figure:after,
	:where(.legacy) .sector-overzicht-list-item:active .promoBlock-figure:after {
		width: 100%;
		left: 0;
	}
	:where(.legacy) .sector-overzicht-list-item-image {
		height: 10rem;
		overflow: hidden;
		position: relative;
	}
	:where(.legacy) .sector-overzicht-list-item-image .sector-image {
		width: 100%;
	}
	@supports ((-o-object-fit: cover) or (object-fit: cover)) {
		:where(.legacy) .sector-overzicht-list-item-image .sector-image {
			-o-object-fit: cover;
			object-fit: cover;
			height: 100%;
		}
	}
	:where(.legacy) .sector-overzicht-list-item-link {
		flex: 1 1 auto;
		color: #42145f;
		fill: #42145f;
		font-weight: bold;
		display: flex;
		margin-top: 0.6rem;
		padding-right: 2rem;
	}
	:where(.legacy) .sector-overzicht-list-item-link .goIcon {
		margin-right: 1rem;
	}
	:where(.legacy) .sector-overzicht-list-item-link-moreInfo {
		position: absolute;
		right: 0;
		bottom: 0;
		background: none;
		border: none;
		padding: 0;
		color: inherit;
		font-weight: inherit;
		line-height: inherit;
		text-align: left;
		text-transform: none;
		background-color: #ffffff;
		border-radius: 50%;
		fill: #42145f;
		transition: transform 200ms;
		padding: 6px;
	}
	:where(.legacy) .sector-overzicht-list-item-link-moreInfo:hover,
	:where(.legacy) .sector-overzicht-list-item-link-moreInfo:focus,
	:where(.legacy) .sector-overzicht-list-item-link-moreInfo:active {
		transform: scale(1.1);
		transition: transform 200ms;
	}
	:where(.legacy) .sector-overzicht-list-item-link-moreInfo svg {
		width: 1rem;
		height: 1rem;
	}
	@media (max-width: 44.9375em) {
		:where(.legacy) .sector-overzicht-list-item-link-moreInfo {
			bottom: -4px;
			padding: 0.6rem;
		}
	}

	:where(.legacy) .sector-overzicht-list-sector {
		margin-bottom: 1.5rem;
		position: relative;
	}
	:where(.legacy) .sector-overzicht-list-sector .interfaceLink:hover,
	:where(.legacy) .sector-overzicht-list-sector .interfaceLink:focus,
	:where(.legacy) .sector-overzicht-list-sector .interfaceLink:active {
		color: #0077c0;
	}
	:where(.legacy) .featured .sector-overzicht-list-sector {
		border-top: 5px solid #42145f;
	}
	:where(.legacy) .sector-overzicht-list-sector .promoBlock-goLink {
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
	}

	:where(.legacy) .sector-overzicht-list-popUp {
		background-color: white;
		border: 1px solid #42145f;
	}

	:where(.legacy) .sector-overzicht-bg {
		background-color: #d9c7e3;
		margin-bottom: 1rem;
	}

	:where(.legacy)
		.sector-overzicht-nonFeatured
		.sector-overzicht-list-item-link-moreInfo {
		fill: #0077c0;
		border: 1px solid #0077c0;
		padding: 0.25rem;
	}
	:where(.legacy)
		.sector-overzicht-nonFeatured
		.sector-overzicht-list-item-link-moreInfo:hover,
	:where(.legacy)
		.sector-overzicht-nonFeatured
		.sector-overzicht-list-item-link-moreInfo:focus,
	:where(.legacy)
		.sector-overzicht-nonFeatured
		.sector-overzicht-list-item-link-moreInfo:active {
		fill: #ffffff;
	}
	@media (max-width: 44.9375em) {
		:where(.legacy)
			.sector-overzicht-nonFeatured
			.sector-overzicht-list-item-link-moreInfo {
			bottom: 0;
			padding: 0.5rem;
		}
	}

	:where(.legacy) .sector-overzicht-nonFeatured .interfaceLink {
		color: #033054;
	}

	:where(.legacy) .sectorDetailPage h2 {
		color: #42145f;
	}

	:where(.legacy) .situatie .accordion {
		margin-top: 1rem;
	}

	:where(.legacy) .situatie-storyOrImage.solo {
		height: 12rem;
		width: 17rem;
		overflow: hidden;
		margin-bottom: 1rem;
	}

	@media (max-width: 49.9375em) {
		:where(.legacy) .situatie-storyOrImage {
			width: calc(100% - 2 * var(--grid-item-margin-inline) - 0.01rem);
			margin-bottom: 1rem;
		}
	}

	@media (max-width: 49.9375em) {
		:where(.legacy) .situatie .pageIntro {
			width: calc(100% - 2 * var(--grid-item-margin-inline) - 0.01rem);
		}
	}

	:where(.legacy) .date-display__container {
		margin-right: 1rem;
		position: relative;
		font-variant-numeric: lining-nums;
	}

	:where(.legacy) .date-display__icon {
		width: 4rem;
		height: 4rem;
	}

	:where(.legacy) .date-display__date {
		width: 2.4rem;
		height: 2rem;
		background-color: #ffffff;
		position: absolute;
		top: 1.7rem;
		left: 0.8rem;
		font-weight: bold;
		font-family: 'Arial Black', 'Arial Bold', Arial, sans-serif;
		text-align: center;
		font-variant-numeric: lining-nums;
	}
	:where(.legacy) .date-display__date {
		line-height: 1;
	}
	:where(.legacy) .date-display__date {
		font-size: 0.5rem;
	}
	:where(.legacy) .date-display__date.date-display__date--blue {
		color: #0077c0;
	}
	:where(.legacy) .date-display__date.date-display__date--aqua {
		color: #00857c;
	}
	:where(.legacy) .date-display__date.date-display__date--grey {
		color: #8f8f8f;
	}
	:where(.legacy) .date-display__date.date-display__date--purple {
		color: #42145f;
	}
	:where(.legacy) .date-display__date.date-display__date--green {
		color: #727800;
	}
	:where(.legacy) .date-display__date.date-display__date--violet {
		color: #a90061;
	}
	:where(.legacy) .date-display__date.date-display__date--orange {
		color: #e27000;
	}
	:where(.legacy) .date-display__date__day {
		display: block;
	}
	:where(.legacy) .date-display__date__day {
		font-size: 1rem;
	}
	:where(.legacy) .date-display__date__month {
		display: block;
	}
	:where(.legacy) .date-display__date__month {
		font-size: 0.75rem;
	}

	:where(.legacy) .social-link {
		display: block;
		height: 2rem;
		width: 2rem;
		text-decoration: none;
		border-bottom: none;
		margin: 0 0.5rem;
		transition: all 0.4s ease-in-out;
	}
	:where(.legacy) .social-link:hover,
	:where(.legacy) .social-link:focus,
	:where(.legacy) .social-link:active {
		transform: scale(1.1);
	}
	:where(.legacy) .social-link--facebook:hover .social-figure-background,
	:where(.legacy) .social-link--facebook:focus .social-figure-background,
	:where(.legacy) .social-link--facebook:active .social-figure-background {
		fill: #3b5998;
	}
	:where(.legacy) .social-link--xdotcom:hover .social-figure-background,
	:where(.legacy) .social-link--xdotcom:focus .social-figure-background,
	:where(.legacy) .social-link--xdotcom:active .social-figure-background {
		fill: #5ea9dd;
	}
	:where(.legacy) .social-link--linkedin:hover .social-figure-background,
	:where(.legacy) .social-link--linkedin:focus .social-figure-background,
	:where(.legacy) .social-link--linkedin:active .social-figure-background {
		fill: #0274b3;
	}
	:where(.legacy) .social-link--googleplus:hover .social-figure-background,
	:where(.legacy) .social-link--googleplus:focus .social-figure-background,
	:where(.legacy) .social-link--googleplus:active .social-figure-background {
		fill: #da4835;
	}
	:where(.legacy) .social-link--youtube:hover .social-figure-background,
	:where(.legacy) .social-link--youtube:focus .social-figure-background,
	:where(.legacy) .social-link--youtube:active .social-figure-background {
		fill: #cc181e;
	}
	:where(.legacy) .social-link--email:hover .social-figure-background,
	:where(.legacy) .social-link--email:focus .social-figure-background,
	:where(.legacy) .social-link--email:active .social-figure-background,
	:where(.legacy) .social-link--email-2012:hover .social-figure-background,
	:where(.legacy) .social-link--email-2012:focus .social-figure-background,
	:where(.legacy) .social-link--email-2012:active .social-figure-background {
		fill: #0077c0;
	}
	:where(.legacy) .social-link:hover .social-figure-foreground,
	:where(.legacy) .social-link:focus .social-figure-foreground,
	:where(.legacy) .social-link:active .social-figure-foreground {
		fill: #ffffff;
	}
	:where(.legacy) .social--share .social-link:hover .social-figure-background,
	:where(.legacy) .social--share .social-link:focus .social-figure-background,
	:where(.legacy) .social--share .social-link:active .social-figure-background {
		fill: #ffffff;
	}
	:where(.legacy) .social--share .social-link:hover .social-figure-foreground,
	:where(.legacy) .social--share .social-link:focus .social-figure-foreground,
	:where(.legacy) .social--share .social-link:active .social-figure-foreground {
		fill: #0077c0;
	}

	:where(.legacy) .social-figure {
		height: 2rem;
		width: 2rem;
		max-width: none;
		display: block;
		position: relative;
	}
	:where(.legacy) .social-figure-background,
	:where(.legacy) .social-figure-foreground {
		transition: fill 400ms;
	}
	:where(.legacy) .social-figure-background {
		fill: #aa418c;
		width: 100%;
		height: 100%;
	}
	:where(.legacy) .social-figure-background.social-figure-background--blue {
		fill: #0077c0;
	}
	:where(.legacy) .social-figure-background.social-figure-background--aqua {
		fill: #00857c;
	}
	:where(.legacy) .social-figure-background.social-figure-background--grey {
		fill: #8f8f8f;
	}
	:where(.legacy) .social-figure-background.social-figure-background--purple {
		fill: #42145f;
	}
	:where(.legacy) .social-figure-background.social-figure-background--green {
		fill: #727800;
	}
	:where(.legacy) .social-figure-background.social-figure-background--violet {
		fill: #a90061;
	}
	:where(.legacy) .social-figure-background.social-figure-background--orange {
		fill: #e27000;
	}
	:where(.legacy) .social-figure-foreground {
		width: 1.2rem;
		height: 1.2rem;
		position: absolute;
		top: 0.4rem;
		left: 0.4rem;
		fill: #ffffff;
	}
	:where(.legacy) .footer .social-figure-foreground {
		fill: #baddef;
	}
	:where(.legacy) .social--share .social-figure-background {
		fill: #e2f0f7;
	}
	:where(.legacy) .social--share .social-figure-foreground {
		fill: #0077c0;
	}
	:where(.legacy) .footer .social--share .social-figure-foreground {
		fill: #baddef;
	}

	:where(.legacy) .social--share {
		margin-left: 0;
		padding-right: 1.8rem;
	}
	@media (min-width: 35em) {
		:where(.legacy) .social--share {
			padding-right: 2.7rem;
		}
	}

	:where(.legacy) .social--follow {
		margin: 0.5rem 0 1rem;
	}

	:where(.legacy) .tabs {
		padding-left: 0;
		margin-bottom: 0;
		margin-left: 0;
		list-style: none;
		position: relative;
		z-index: 3;
	}
	:where(.legacy) .tabs li:before {
		content: '';
		position: relative;
		display: none;
	}
	:where(.legacy) .tabs {
		line-height: 1.2;
	}
	:where(.legacy) .tabs {
		font-size: 0.875rem;
	}
	@media (min-width: 40em) {
		:where(.legacy) .tabs {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: flex-end;
		}
	}

	:where(.legacy) .tab {
		padding: 0;
	}
	@media (min-width: 40em) {
		:where(.legacy) .tab + .tab {
			margin-left: 0.35rem;
		}
	}
	:where(.legacy) .tab-button {
		background: none;
		border: none;
		padding: 0;
		color: inherit;
		font-weight: inherit;
		line-height: inherit;
		text-align: left;
		text-transform: none;
		display: block;
		width: 100%;
	}
	:where(.legacy) .tab-link {
		text-decoration: none;
		border-bottom: none;
		display: block;
		padding: 0.6rem 0.8rem;
		color: #ffffff;
		fill: #ffffff;
		position: relative;
		z-index: 2;
	}
	:where(.legacy) .tab-link:before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: #033054;
		transition:
			top 0.1s ease-in-out,
			background-color 0.1s ease-in-out;
	}
	@media (min-width: 40em) {
		:where(.legacy) .tab-link:before {
			background-image: linear-gradient(
				to top,
				rgba(0, 0, 0, 0.15) 0.25rem,
				transparent 0.25rem
			);
		}
	}
	@media (min-width: 40em) {
		:where(.legacy) .tab-link {
			transform-origin: 50% 100%;
		}
	}
	@media (max-width: 39.9375em) {
		:where(.legacy) .tab-link:hover,
		:where(.legacy) .tab-link:focus,
		:where(.legacy) .tab-link:active {
			color: #ffffff;
			fill: #ffffff;
		}
	}
	@media (min-width: 40em) {
		:where(.legacy) .tab-link:hover,
		:where(.legacy) .tab-link:focus,
		:where(.legacy) .tab-link:active {
			color: #ffffff;
			fill: #ffffff;
		}
	}
	@media (max-width: 39.9375em) {
		:where(.legacy) .tab-link:hover:before,
		:where(.legacy) .tab-link:focus:before,
		:where(.legacy) .tab-link:active:before {
			background-color: #0077c0;
		}
	}
	@media (min-width: 40em) {
		:where(.legacy) .tab-link:hover:before,
		:where(.legacy) .tab-link:focus:before,
		:where(.legacy) .tab-link:active:before {
			top: -0.15rem;
			background-image: linear-gradient(
				to top,
				rgba(0, 0, 0, 0.15),
				rgba(0, 0, 0, 0.15)
			);
		}
	}
	@media (max-width: 39.9375em) {
		:where(.legacy) .tab-link--small {
			display: block;
		}
	}
	@media (min-width: 40em) {
		:where(.legacy) .tab-link--small {
			display: none;
		}
	}
	@media (max-width: 39.9375em) {
		:where(.legacy) .tab-link--large {
			display: none;
		}
	}
	@media (min-width: 40em) {
		:where(.legacy) .tab-link--large {
			display: block;
		}
	}
	:where(.legacy) .tab-link--dropdown {
		width: 100%;
		margin-top: 2px;
		background-color: #e2f0f7;
		border: 0;
		color: #033054;
		fill: #033054;
		text-align: left;
	}
	@media (min-width: 35em) {
		:where(.legacy) .tab-link--dropdown {
			padding: 0.6rem 2.8rem;
		}
	}
	:where(.legacy) .tab-link--dropdown:hover,
	:where(.legacy) .tab-link--dropdown:focus,
	:where(.legacy) .tab-link--dropdown:active {
		color: #033054;
		fill: #033054;
	}
	:where(.legacy) .tab-link--dropdown:before {
		display: none;
	}
	:where(.legacy) .tab-link.is-active {
		z-index: 3;
		font-weight: bold;
	}
	@media (max-width: 39.9375em) {
		:where(.legacy) .tab-link.is-active {
			box-shadow:
				0 -4px 0 0 rgba(0, 0, 0, 0.15),
				0 4px 0 0 rgba(0, 0, 0, 0.15);
		}
		:where(.legacy) .tab-link.is-active:before {
			left: -0.25rem;
			right: -0.25rem;
		}
		:where(.legacy) .tab-link.is-active.tab-link--dropdown {
			box-shadow: none;
		}
	}
	@media (min-width: 40em) {
		:where(.legacy) .tab-link.is-active {
			color: #033054;
			fill: #033054;
		}
		:where(.legacy) .tab-link.is-active:before {
			top: -0.25rem;
			background-color: #ffffff;
			background-image: none;
		}
	}
	@media (min-width: 40em) {
		:where(.legacy) .tab-link--content.is-active:before {
			background-color: #f6f6f6;
		}
	}
	:where(.legacy) .tab-link--openClose,
	:where(.legacy) .tab-link--openClose.is-active {
		box-shadow: none;
	}
	:where(.legacy) .tab-icon {
		position: relative;
		width: 1.3em;
		height: 1.3em;
		margin-top: 0;
		margin-right: 0.5em;
	}
	:where(.legacy) .tab-text {
		position: relative;
	}
	:where(.legacy) .tab-openClose {
		position: relative;
	}
	:where(.legacy) .tab-openClose:before {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background-color: #f6f6f6;
		box-shadow: 0 0 0 1px #8f8f8f inset;
	}
	:where(.legacy) .tab-openClose.is-active {
		z-index: 3;
		box-shadow:
			0 -4px 0 0 rgba(0, 0, 0, 0.15),
			0 4px 0 0 rgba(0, 0, 0, 0.15);
	}
	:where(.legacy) .tab-openClose.is-active:before {
		content: '';
	}
	@media (max-width: 39.9375em) {
		:where(.legacy) .tab-openClose.is-active:before {
			left: -0.25rem;
			right: -0.25rem;
		}
	}
	:where(.legacy) .tab-openClose-link.is-opened {
		color: #033054;
		fill: #033054;
	}
	:where(.legacy) .tab-openClose-link.is-opened:before {
		top: -0.25rem;
		background-color: transparent;
		background-image: none;
	}
	:where(.legacy) .tab-openClose-content {
		position: relative;
		overflow: hidden;
	}
	:where(.legacy) .tab-openClose-icon {
		position: relative;
		width: 1.8em;
		height: 1.8em;
		margin: -0.3em 0 -0.3em 0.3em;
		transition: transform 400ms;
		transform: rotate(0deg);
	}
	:where(.legacy) .tab-openClose-icon.is-opened {
		transform: rotate(-180deg);
	}

	:where(.legacy) .dropdownContainer .tab-link {
		color: #0077c0;
		fill: #0077c0;
	}
	@media (min-width: 35em) {
		:where(.legacy) .dropdownContainer .tab-link {
			padding: 0.6rem 2.8rem;
		}
	}
	:where(.legacy) .dropdownContainer .tab-link:before {
		background-color: #f3f9ff;
	}

	:where(.legacy) .contentIndex {
		padding: 1rem 0;
	}
	:where(.legacy) .contentIndex-item {
		padding: 0;
	}
	:where(.legacy) .contentIndex-link.is-active {
		fill: #033054;
		color: #033054;
	}
	:where(.legacy) .contentIndex-icon.is-active {
		transform: rotate(90deg);
	}

	:where(.legacy) .contentIndexInTab-item {
		padding: 0;
		margin: 0 1px;
		border-top: 1px solid #dddddd;
	}

	:where(.legacy) .contentIndexInTab-link {
		text-decoration: none;
		border-bottom: none;
		display: block;
		color: #0670b2;
		padding: 0.8rem 0.8rem;
	}
	:where(.legacy) .contentIndexInTab-link.is-active {
		color: #033054;
	}
	:where(.legacy) .contentIndexInTab-link:hover,
	:where(.legacy) .contentIndexInTab-link:focus,
	:where(.legacy) .contentIndexInTab-link:active {
		color: #033054;
	}

	:where(.legacy) .blockContact {
		border-top: 3px solid #42145f;
		padding-top: 0.5rem;
	}

	:where(.legacy) .blockContact-header {
		padding-bottom: 0.5rem;
		border-bottom-width: 1px;
		border-bottom-style: solid;
	}

	:where(.legacy) .blockContact-title {
		margin-top: 0;
		margin-bottom: 0.25rem;
		padding-top: 0.5rem;
		color: #42145f;
	}

	:where(.legacy) .blockContact-organisation {
		display: inline-block;
	}

	:where(.legacy) .blockContact-item {
		margin: 0;
		padding: 0.5rem 0;
		display: flex;
		align-items: center;
		min-height: 3rem;
	}

	:where(.legacy) .blockContact-icon {
		width: 1.55rem;
		height: 1.55rem;
		fill: #42145f;
	}
	:where(.legacy) .blockContact-icon + .media-text,
	:where(.legacy) .blockContact-icon + .content-block__content {
		margin-left: 1rem;
	}

	:where(.legacy) .blockContact-icon--link,
	:where(.legacy) .blockContact-icon--social {
		padding-left: 0.3rem;
	}

	:where(.legacy) .blockContact-link {
		display: block;
	}

	:where(.legacy) .blockContact-emphasis {
		color: #42145f;
	}

	:where(.legacy) .blockContact-phone-number {
		display: inline-block;
	}
	:where(.legacy) .blockContact-phone-number {
		font-size: 1.2em;
	}

	:where(.legacy) .labelList {
		padding-left: 0;
		margin-bottom: 0;
		margin-left: 0;
		list-style: none;
		margin: 1rem 0 1.5rem 0;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
	}
	:where(.legacy) .labelList li:before {
		content: '';
		position: relative;
		display: none;
	}
	:where(.legacy) .homepageHeader .labelList {
		margin-bottom: 0;
	}
	:where(.legacy) .labelList-title {
		margin-top: 1rem;
		font-weight: bold;
	}
	:where(.legacy) .labelList-item {
		margin: 0 0.5rem 0.5rem 0;
		padding: 0;
		display: inline-block;
	}
	:where(.legacy) .labelList-link {
		text-decoration: none;
		border-bottom: none;
		padding: 0.2rem 0.7rem 0.3rem 1rem;
		display: inherit;
		background-color: #ffffff;
		color: #0670b2;
		fill: #0670b2;
		transition-property: color, transform;
		transition-duration: 0.2s;
		transition-timing-function: ease-in-out;
	}
	:where(.legacy) .labelList-link {
		line-height: 1.6;
	}
	:where(.legacy) .labelList-link:hover,
	:where(.legacy) .labelList-link:focus,
	:where(.legacy) .labelList-link:active {
		color: #033054;
		fill: #033054;
	}
	:where(.legacy) .labelList-link:hover .labelList-text,
	:where(.legacy) .labelList-link:focus .labelList-text,
	:where(.legacy) .labelList-link:active .labelList-text {
		border-bottom: 1px solid;
	}
	:where(.legacy) .labelList-text {
		flex: 1 1 0;
	}
	:where(.legacy) .labelList-text {
		line-height: 1.3;
	}

	:where(.legacy) .noDropDown {
		margin-top: 5rem;
	}

	:where(.legacy) .headerStory {
		width: 15rem;
		margin: 0;
		padding: 0.6rem 1.5rem;
		display: block;
		position: absolute;
		right: 1rem;
		bottom: 1rem;
		background: rgba(255, 255, 255, 0.75);
		text-decoration: none;
		border-bottom: none;
		transform-origin: 100% 100%;
		transition: transform 200ms;
		text-align: center;
	}
	:where(.legacy) .headerStory {
		line-height: 1.2;
	}
	:where(.legacy) .headerStory:before {
		width: 0;
		height: 0;
		position: absolute;
		left: -1.1rem;
		content: '';
		border-top: 10px solid transparent;
		border-bottom: 10px solid transparent;
		border-right: 22px solid rgba(255, 255, 255, 0.75);
	}
	@media (max-width: 49.9375em) {
		:where(.legacy) .headerStory {
			width: 13.1rem;
			bottom: 0.4rem;
			right: 1.6rem;
		}
		:where(.legacy) .headerStory {
			line-height: 1;
		}
		:where(.legacy) .headerStory:before {
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-bottom: 22px solid rgba(255, 255, 255, 0.75);
			left: 1.8rem;
			top: -1.9rem;
		}
	}
	@media (max-width: 39.9375em) {
		:where(.legacy) .headerStory {
			display: none;
		}
	}
	:where(.legacy) .headerStory-intro {
		margin: 0;
		font-weight: normal;
		font-style: italic;
		color: #000000;
	}
	:where(.legacy) .headerStory-intro {
		font-size: 0.875rem;
	}
	:where(.legacy) .headerStory-blockquote {
		margin-bottom: 0.8rem;
		padding: 0;
		color: #000000;
		border: none;
		font-weight: bold;
	}
	:where(.legacy) .headerStory-button {
		background: none;
		border: none;
		padding: 0;
		color: inherit;
		font-weight: inherit;
		line-height: inherit;
		text-align: left;
		text-transform: none;
		padding: 9px;
		text-transform: none;
		background-color: #a90061;
		color: #ffffff;
	}
	:where(.legacy) .headerStory-button {
		font-size: 0.75rem;
	}
	@media (max-width: 49.9375em) {
		:where(.legacy) .headerStory-button {
			text-align: center;
		}
	}
	:where(.legacy) .headerStory-button:hover,
	:where(.legacy) .headerStory-button:focus,
	:where(.legacy) .headerStory-button:active {
		background-color: #900052;
		color: #ffffff;
	}
	:where(.legacy) .headerStory-citation {
		margin-bottom: 0.5rem;
		font-style: italic;
	}
	:where(.legacy) .headerStory-citation {
		font-size: 0.875rem;
	}

	:where(.legacy) .biographyBlock {
		padding-top: 0;
	}
	:where(.legacy) .biographyBlock-image {
		width: 5rem;
		height: 5rem;
		margin-right: 1rem;
		max-width: none;
	}
	:where(.legacy) .biographyBlock-text {
		margin: 1rem 1rem 1rem 0;
	}
	:where(.legacy) .biographyBlock-social {
		display: block;
		margin: 0.5rem 0;
	}
	:where(.legacy) .biographyBlock-social-figure {
		width: 2rem;
		height: 2rem;
		position: relative;
		display: inline-block;
	}

	:where(.legacy) .mediaOverview .mediaOverview-section:first-child {
		background-color: #f3f9ff;
	}

	:where(.legacy) .showCaseMedia-header {
		padding-top: 5rem;
		padding-bottom: 1rem;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		background-position: center center;
		background-size: cover;
		color: #ffffff;
	}
	:where(.legacy) .showCaseMedia-header .playButton {
		visibility: hidden;
	}
	:where(.legacy) .showCaseMedia-header .overviewPageTitle {
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}
	:where(.legacy) .showCaseMedia-header:before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-image: linear-gradient(
			0deg,
			rgba(3, 48, 84, 0.95),
			rgba(3, 48, 84, 0.1)
		);
	}
	@media (min-width: 50em) {
		:where(.legacy) .showCaseMedia-header {
			height: 75vh;
			padding-top: 0;
			padding-bottom: 5vh;
		}
		:where(.legacy) .showCaseMedia-header .playButton {
			visibility: visible;
		}
	}

	:where(.legacy) .showCaseMedia-header--blue:before {
		background-image: linear-gradient(
			0deg,
			rgba(3, 48, 84, 0.95),
			rgba(3, 48, 84, 0.1)
		);
	}

	:where(.legacy) .showCaseMedia-header--purple:before {
		background-image: linear-gradient(
			0deg,
			rgba(66, 20, 95, 0.95),
			rgba(66, 20, 95, 0.1)
		);
	}

	:where(.legacy) .showCaseMedia-header--grey:before {
		background-image: linear-gradient(
			0deg,
			rgba(143, 143, 143, 0.95),
			rgba(143, 143, 143, 0.1)
		);
	}

	:where(.legacy) .showCaseMedia-header--green:before {
		background-image: linear-gradient(
			0deg,
			rgba(114, 120, 0, 0.95),
			rgba(114, 120, 0, 0.1)
		);
	}

	:where(.legacy) .showCaseMedia-header--violet:before {
		background-image: linear-gradient(
			0deg,
			rgba(169, 0, 97, 0.95),
			rgba(169, 0, 97, 0.1)
		);
	}

	:where(.legacy) .showCaseMedia-link-wrapper {
		border: 0;
	}
	:where(.legacy) .showCaseMedia-link-wrapper:hover,
	:where(.legacy) .showCaseMedia-link-wrapper:focus,
	:where(.legacy) .showCaseMedia-link-wrapper:active {
		color: #ffffff;
	}

	:where(.legacy) .showCaseMedia-content-wrapper {
		position: relative;
	}

	:where(.legacy) .showCaseMedia-label {
		background: none;
		border: none;
		padding: 0;
		color: inherit;
		font-weight: inherit;
		line-height: inherit;
		text-align: left;
		text-transform: none;
		width: 6rem;
		margin-bottom: 0.5rem;
		padding: 0.5rem 1rem;
		background-color: #ffffff;
		font-weight: bold;
		text-align: center;
	}
	:where(.legacy) .showCaseMedia-header--blue .showCaseMedia-label {
		color: #0077c0;
	}
	:where(.legacy) .showCaseMedia-header--aqua .showCaseMedia-label {
		color: #00857c;
	}
	:where(.legacy) .showCaseMedia-header--grey .showCaseMedia-label {
		color: #8f8f8f;
	}
	:where(.legacy) .showCaseMedia-header--purple .showCaseMedia-label {
		color: #42145f;
	}
	:where(.legacy) .showCaseMedia-header--green .showCaseMedia-label {
		color: #727800;
	}
	:where(.legacy) .showCaseMedia-header--violet .showCaseMedia-label {
		color: #a90061;
	}
	:where(.legacy) .showCaseMedia-header--orange .showCaseMedia-label {
		color: #e27000;
	}
	:where(.legacy) .showCaseMedia-label:hover,
	:where(.legacy) .showCaseMedia-label:focus,
	:where(.legacy) .showCaseMedia-label:active {
		background-color: #ffffff;
	}

	:where(.legacy) .showCaseMedia-person-info {
		margin-bottom: 0.5rem;
	}
	:where(.legacy) .showCaseMedia-person-info .social-link,
	:where(.legacy) .showCaseMedia-person-info .social-figure {
		width: 1.5rem;
		height: 1.5rem;
	}
	:where(.legacy) .showCaseMedia-person-info .social-link {
		margin: 0 0.5rem 0 0;
	}
	:where(.legacy) .showCaseMedia-person-info .social-figure-foreground {
		width: 0.8rem;
		height: 0.8rem;
		top: 0.35rem;
		left: 0.35rem;
	}

	:where(.legacy) .showCaseMedia-person-name,
	:where(.legacy) .showCaseMedia-person-age {
		font-weight: bold;
	}
	:where(.legacy) .showCaseMedia-person-name,
	:where(.legacy) .showCaseMedia-person-age {
		font-size: 1.1rem;
	}
	:where(.legacy) .showCaseMedia-person-name,
	:where(.legacy) .showCaseMedia-person-age {
		line-height: 1;
	}

	:where(.legacy) .showCaseMedia-title {
		position: relative;
		color: #ffffff;
	}
	:where(.legacy) .showCaseMedia-title.hasQuotes:before {
		content: '\201C';
		position: relative;
		margin-right: 0.1em;
	}
	:where(.legacy) .showCaseMedia-title.hasQuotes:before {
		line-height: 1;
	}
	:where(.legacy) .showCaseMedia-title.hasQuotes:after {
		content: '\201D';
		position: relative;
		margin-left: 0.1em;
	}
	:where(.legacy) .showCaseMedia-title.hasQuotes:after {
		line-height: 1;
	}
	@media (min-width: 50em) {
		:where(.legacy) .showCaseMedia-title.hasQuotes {
			padding-left: 1em;
		}
		:where(.legacy) .showCaseMedia-title.hasQuotes:before {
			margin-right: 0;
			position: absolute;
			left: 0;
		}
		:where(.legacy) .showCaseMedia-title.hasQuotes:before {
			font-size: 2em;
		}
		:where(.legacy) .showCaseMedia-title.hasQuotes:after {
			height: 0.5em;
			margin-left: 0;
			padding-left: 0.1em;
			position: absolute;
			bottom: 0.2em;
			right: auto;
		}
		:where(.legacy) .showCaseMedia-title.hasQuotes:after {
			font-size: 2em;
		}
	}

	@media (min-width: 57em) {
		:where(.legacy) .showCaseMedia-intro {
			width: 75%;
		}
	}

	:where(.legacy) .showCaseMedia-link {
		font-weight: bold;
	}

	:where(.legacy) .showCaseMedia-meta-info {
		margin-left: 1rem;
		font-weight: normal;
	}

	:where(.legacy) .showCaseMedia-body {
		padding-top: 3rem;
	}
	:where(.legacy) .showCaseMedia-body h2:first-child,
	:where(.legacy) .showCaseMedia-body h3:first-child {
		margin-top: 0;
	}

	:where(.legacy) .showCaseMedia-footer {
		margin-top: 1rem;
		margin-bottom: 2rem;
		border-top: 1px solid #8fcae7;
	}
	:where(.legacy) .showCaseMedia-footer h2:first-child,
	:where(.legacy) .showCaseMedia-footer h3:first-child {
		margin-top: 1.5rem;
	}

	:where(.legacy) .showCaseMedia-related-quote {
		margin-top: 0.75rem;
	}
	:where(.legacy) .showCaseMedia-related-quote:before {
		content: '\201C';
	}
	:where(.legacy) .showCaseMedia-related-quote:after {
		content: '\201D';
	}

	:where(.legacy) .showCaseMedia-related-link {
		border-bottom: 0;
	}

	:where(.legacy) .playButton {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 5rem;
		height: 5rem;
		margin-left: -2.5rem;
		margin-top: -2.5rem;
		transition: all 0.25s ease-in-out;
	}
	:where(.legacy) .promoBlock .playButton {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 3rem;
		height: 3rem;
		margin-left: -1.5rem;
		margin-top: -1.5rem;
	}
	:where(.legacy) .promoBlock--blue .playButton {
		fill: #0077c0;
	}
	:where(.legacy) .promoBlock--aqua .playButton {
		fill: #00857c;
	}
	:where(.legacy) .promoBlock--grey .playButton {
		fill: #8f8f8f;
	}
	:where(.legacy) .promoBlock--purple .playButton {
		fill: #42145f;
	}
	:where(.legacy) .promoBlock--green .playButton {
		fill: #727800;
	}
	:where(.legacy) .promoBlock--violet .playButton {
		fill: #a90061;
	}
	:where(.legacy) .promoBlock--orange .playButton {
		fill: #e27000;
	}
	:where(.legacy) .showCaseMedia-header--blue .playButton {
		fill: #0077c0;
	}
	:where(.legacy) .showCaseMedia-header--aqua .playButton {
		fill: #00857c;
	}
	:where(.legacy) .showCaseMedia-header--grey .playButton {
		fill: #8f8f8f;
	}
	:where(.legacy) .showCaseMedia-header--purple .playButton {
		fill: #42145f;
	}
	:where(.legacy) .showCaseMedia-header--green .playButton {
		fill: #727800;
	}
	:where(.legacy) .showCaseMedia-header--violet .playButton {
		fill: #a90061;
	}
	:where(.legacy) .showCaseMedia-header--orange .playButton {
		fill: #e27000;
	}
	:where(.legacy) .playButton .playButtonBg {
		opacity: 0.75;
		transition: all 0.25s ease-in-out;
	}
	:where(.legacy) .showCaseMedia-header:hover .playButton .playButtonBg {
		opacity: 0.9;
	}
	:where(.legacy) .showCaseMedia-header:hover .playButton {
		transform: scale(1.05);
	}

	:where(.legacy) .cbsBlockIframeWrapper {
		width: 100%;
		position: relative;
		height: 0;
		padding-bottom: 71.42857%;
	}

	:where(.legacy) .cbsBlockIframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	:where(.legacy) .coenInfoGraphicContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0;
	}
	@media (min-width: 40em) {
		:where(.legacy) .coenInfoGraphicContainer {
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-around;
			align-items: flex-start;
		}
	}

	:where(.legacy) .coenInfoGraphic {
		width: 90%;
		margin: 1rem 0;
	}
	@media (min-width: 40em) {
		:where(.legacy) .coenInfoGraphic {
			width: 40%;
			margin-left: 5%;
			margin-right: 5%;
		}
		:where(.legacy) .coenInfoGraphic:first-child {
			width: 50%;
		}
	}

	:where(.legacy) .oldCoenReportDescription {
		margin-left: 1rem;
	}

	:where(.legacy) .diapositive {
		color: #ffffff;
	}

	:where(.legacy) .diapositive-link:hover,
	:where(.legacy) .diapositive-link:focus,
	:where(.legacy) .diapositive-link:active {
		color: #ffffff;
	}

	:where(.legacy) .media,
	:where(.legacy) .content-block {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	:where(.legacy) .media--alignTop,
	:where(.legacy) .content-block {
		align-items: flex-start;
	}
	:where(.legacy) .media--bordered {
		margin-bottom: 0.75rem;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		border-width: 1px;
		border-style: solid;
		border-left: 0;
		border-right: 0;
	}
	:where(.legacy) .media.media--blue,
	:where(.legacy) .media--blue.content-block {
		border-color: #0077c0;
	}
	:where(.legacy) .media.media--aqua,
	:where(.legacy) .media--aqua.content-block {
		border-color: #00857c;
	}
	:where(.legacy) .media.media--grey,
	:where(.legacy) .media--grey.content-block {
		border-color: #8f8f8f;
	}
	:where(.legacy) .media.media--purple,
	:where(.legacy) .media--purple.content-block {
		border-color: #42145f;
	}
	:where(.legacy) .media.media--green,
	:where(.legacy) .media--green.content-block {
		border-color: #727800;
	}
	:where(.legacy) .media.media--violet,
	:where(.legacy) .media--violet.content-block {
		border-color: #a90061;
	}
	:where(.legacy) .media.media--orange,
	:where(.legacy) .media--orange.content-block {
		border-color: #e27000;
	}

	:where(.legacy) .inlineMedia {
		display: inline-flex;
		flex-direction: row;
		align-items: center;
	}

	:where(.legacy) .media-text,
	:where(.legacy) .content-block__content {
		flex: 1 1 auto;
	}

	:where(.legacy) .media-figure,
	:where(.legacy) .content-block__icon-wrapper {
		max-width: none;
		flex: 0 0 auto;
	}

	:where(.legacy) .accHide {
		position: absolute;
		left: -999em;
		top: 0;
		height: 1px;
		overflow: hidden;
	}
	:where(.legacy) .accHide:focus {
		left: 1em;
		height: auto;
	}

	:where(.legacy) .themedBackground.themedBackground--blue {
		background: #e2f0f7;
	}

	:where(.legacy) .themedBackground.themedBackground--aqua {
		background: #00857c;
	}

	:where(.legacy) .themedBackground.themedBackground--grey {
		background: #f6f6f6;
	}

	:where(.legacy) .themedBackground.themedBackground--purple {
		background: #d9c7e3;
	}

	:where(.legacy) .themedBackground.themedBackground--green {
		background: #eeeed9;
	}

	:where(.legacy) .themedBackground.themedBackground--violet {
		background: #f4e9f1;
	}

	:where(.legacy) .themedBackground.themedBackground--orange {
		background: #e27000;
	}

	:where(.legacy) .themedDarkBackground.themedDarkBackground--blue {
		background: #0077c0;
	}

	:where(.legacy) .themedDarkBackground.themedDarkBackground--aqua {
		background: #00857c;
	}

	:where(.legacy) .themedDarkBackground.themedDarkBackground--grey {
		background: #8f8f8f;
	}

	:where(.legacy) .themedDarkBackground.themedDarkBackground--purple {
		background: #42145f;
	}

	:where(.legacy) .themedDarkBackground.themedDarkBackground--green {
		background: #727800;
	}

	:where(.legacy) .themedDarkBackground.themedDarkBackground--violet {
		background: #a90061;
	}

	:where(.legacy) .themedDarkBackground.themedDarkBackground--orange {
		background: #e27000;
	}

	:where(.legacy) .themedSideBackground {
		position: relative;
	}
	:where(.legacy) .themedSideBackground.themedSideBackground--blue {
		background: #e2f0f7;
	}
	:where(.legacy) .themedSideBackground.themedSideBackground--aqua {
		background: #00857c;
	}
	:where(.legacy) .themedSideBackground.themedSideBackground--grey {
		background: #f6f6f6;
	}
	:where(.legacy) .themedSideBackground.themedSideBackground--purple {
		background: #d9c7e3;
	}
	:where(.legacy) .themedSideBackground.themedSideBackground--green {
		background: #eeeed9;
	}
	:where(.legacy) .themedSideBackground.themedSideBackground--violet {
		background: #f4e9f1;
	}
	:where(.legacy) .themedSideBackground.themedSideBackground--orange {
		background: #e27000;
	}
	:where(.legacy) .themedSideBackground:before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		right: 100%;
		width: 9999px;
		width: 100vw;
	}
	:where(.legacy) .themedSideBackground.themedSideBackground--blue:before {
		background: #e2f0f7;
	}
	:where(.legacy) .themedSideBackground.themedSideBackground--aqua:before {
		background: #00857c;
	}
	:where(.legacy) .themedSideBackground.themedSideBackground--grey:before {
		background: #f6f6f6;
	}
	:where(.legacy) .themedSideBackground.themedSideBackground--purple:before {
		background: #d9c7e3;
	}
	:where(.legacy) .themedSideBackground.themedSideBackground--green:before {
		background: #eeeed9;
	}
	:where(.legacy) .themedSideBackground.themedSideBackground--violet:before {
		background: #f4e9f1;
	}
	:where(.legacy) .themedSideBackground.themedSideBackground--orange:before {
		background: #e27000;
	}

	:where(.legacy) .tabButton {
		background: none;
		border: none;
		padding: 0;
		color: inherit;
		font-weight: inherit;
		line-height: inherit;
		text-align: left;
		text-transform: none;
		display: block;
		padding-left: 1rem;
		padding-right: 1rem;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		font-weight: bold;
		color: black;
		fill: #0077c0;
	}
	:where(.legacy) .tabButton:hover .tabButton-icon,
	:where(.legacy) .tabButton:focus .tabButton-icon,
	:where(.legacy) .tabButton:active .tabButton-icon {
		position: relative;
		transform: translateY(2px);
	}
	@media (min-width: 45em) {
		:where(.legacy) .tabButton {
			margin-right: 1rem;
		}
	}

	:where(.legacy) .tabButton-icon {
		width: 1.5rem;
		height: 1.5rem;
		top: 0;
		transition: transform 200ms;
	}

	:where(.legacy) .tabButton--opened {
		background: #ffffff;
	}
	:where(.legacy) .tabButton--opened:hover .tabButton-icon,
	:where(.legacy) .tabButton--opened:focus .tabButton-icon,
	:where(.legacy) .tabButton--opened:active .tabButton-icon {
		transform: translateY(-2px);
	}

	:where(.legacy) .tabButton-text {
		white-space: nowrap;
	}

	:where(.legacy) .checkBox {
		display: flex;
		position: relative;
		margin-right: 0.5rem;
		margin-bottom: 0.5rem;
	}
	:where(.legacy) .checkBox-label {
		display: flex;
		cursor: pointer;
		padding-bottom: 0;
		background: #e2f0f7;
		transition: background 700ms;
	}
	:where(.legacy) .filters--blue .checkBox-label.selected {
		color: #e2f0f7;
	}
	:where(.legacy) .filters--aqua .checkBox-label.selected {
		color: #00857c;
	}
	:where(.legacy) .filters--grey .checkBox-label.selected {
		color: #f6f6f6;
	}
	:where(.legacy) .filters--purple .checkBox-label.selected {
		color: #d9c7e3;
	}
	:where(.legacy) .filters--green .checkBox-label.selected {
		color: #eeeed9;
	}
	:where(.legacy) .filters--violet .checkBox-label.selected {
		color: #f4e9f1;
	}
	:where(.legacy) .filters--orange .checkBox-label.selected {
		color: #e27000;
	}
	:where(.legacy) .filters--blue .checkBox-label.selected {
		background: #0077c0;
	}
	:where(.legacy) .filters--aqua .checkBox-label.selected {
		background: #00857c;
	}
	:where(.legacy) .filters--grey .checkBox-label.selected {
		background: #8f8f8f;
	}
	:where(.legacy) .filters--purple .checkBox-label.selected {
		background: #42145f;
	}
	:where(.legacy) .filters--green .checkBox-label.selected {
		background: #727800;
	}
	:where(.legacy) .filters--violet .checkBox-label.selected {
		background: #a90061;
	}
	:where(.legacy) .filters--orange .checkBox-label.selected {
		background: #e27000;
	}
	:where(.legacy) .checkBox-input:hover,
	:where(.legacy) .checkBox-input:focus,
	:where(.legacy) .checkBox-input:active {
		left: -999em;
	}
	:where(.legacy) .checkBox-input:focus + .checkBox-label {
		outline-color: #4d91fe;
		outline-style: solid;
		outline-width: 2px;
		outline-offset: -2px;
	}
	:where(.legacy) .checkBox-text {
		padding: 0.5rem;
	}
	:where(.legacy) .checkBox .close {
		width: 2.6rem;
		height: 2.6rem;
	}
	:where(.legacy) .filters--blue .checkBox .close {
		background: #0077c0;
	}
	:where(.legacy) .filters--aqua .checkBox .close {
		background: #00857c;
	}
	:where(.legacy) .filters--grey .checkBox .close {
		background: #8f8f8f;
	}
	:where(.legacy) .filters--purple .checkBox .close {
		background: #42145f;
	}
	:where(.legacy) .filters--green .checkBox .close {
		background: #727800;
	}
	:where(.legacy) .filters--violet .checkBox .close {
		background: #a90061;
	}
	:where(.legacy) .filters--orange .checkBox .close {
		background: #e27000;
	}
	:where(.legacy) .checkBox .close-icon {
		fill: white;
	}

	:where(.legacy) .radio {
		display: flex;
		position: relative;
		margin-right: 0.5rem;
		margin-bottom: 0.5rem;
	}
	:where(.legacy) .radio-label {
		display: flex;
		cursor: pointer;
		padding-bottom: 0;
		background: #e2f0f7;
		transition: background 700ms;
	}
	:where(.legacy) .filters--blue .radio-label.selected {
		color: #e2f0f7;
	}
	:where(.legacy) .filters--aqua .radio-label.selected {
		color: #00857c;
	}
	:where(.legacy) .filters--grey .radio-label.selected {
		color: #f6f6f6;
	}
	:where(.legacy) .filters--purple .radio-label.selected {
		color: #d9c7e3;
	}
	:where(.legacy) .filters--green .radio-label.selected {
		color: #eeeed9;
	}
	:where(.legacy) .filters--violet .radio-label.selected {
		color: #f4e9f1;
	}
	:where(.legacy) .filters--orange .radio-label.selected {
		color: #e27000;
	}
	:where(.legacy) .filters--blue .radio-label.selected {
		background: #0077c0;
	}
	:where(.legacy) .filters--aqua .radio-label.selected {
		background: #00857c;
	}
	:where(.legacy) .filters--grey .radio-label.selected {
		background: #8f8f8f;
	}
	:where(.legacy) .filters--purple .radio-label.selected {
		background: #42145f;
	}
	:where(.legacy) .filters--green .radio-label.selected {
		background: #727800;
	}
	:where(.legacy) .filters--violet .radio-label.selected {
		background: #a90061;
	}
	:where(.legacy) .filters--orange .radio-label.selected {
		background: #e27000;
	}
	:where(.legacy) .radio-input:hover,
	:where(.legacy) .radio-input:focus,
	:where(.legacy) .radio-input:active {
		left: -999em;
	}
	:where(.legacy) .radio-input:focus + .radio-label {
		outline-color: #4d91fe;
		outline-style: solid;
		outline-width: 2px;
		outline-offset: -2px;
	}
	:where(.legacy) .radio-text {
		padding: 0.5rem;
	}
	:where(.legacy) .radio .close {
		width: 2.6rem;
		height: 2.6rem;
	}
	:where(.legacy) .filters--blue .radio .close {
		background: #0077c0;
	}
	:where(.legacy) .filters--aqua .radio .close {
		background: #00857c;
	}
	:where(.legacy) .filters--grey .radio .close {
		background: #8f8f8f;
	}
	:where(.legacy) .filters--purple .radio .close {
		background: #42145f;
	}
	:where(.legacy) .filters--green .radio .close {
		background: #727800;
	}
	:where(.legacy) .filters--violet .radio .close {
		background: #a90061;
	}
	:where(.legacy) .filters--orange .radio .close {
		background: #e27000;
	}
	:where(.legacy) .radio .close-icon {
		fill: white;
	}

	:where(.legacy) .articleOverview {
		width: 100%;
	}

	:where(.legacy) .articleOverview--grey .articleOverview-acc-warning {
		color: #696969;
		font-style: italic;
	}

	:where(.legacy) .articleOverview-acc-title {
		margin: 0;
		color: #0077c0;
		line-height: inherit;
		font-weight: bold;
	}
	:where(.legacy) .articleOverview-acc-title {
		font-size: inherit;
	}

	:where(.legacy) .articleOverview-acc-warning {
		color: #a90061;
		font-style: italic;
	}

	:where(.legacy) .articleOverview-acc-list {
		margin-left: 0;
		padding-left: 0;
		margin-bottom: 0;
		margin-left: 0;
		list-style: none;
	}
	:where(.legacy) .articleOverview-acc-list li:before {
		content: '';
		position: relative;
		display: none;
	}

	:where(.legacy) .articleOverview-acc-item {
		margin-bottom: 1rem;
	}
	:where(.legacy) .articleOverview-acc-item > a,
	:where(.legacy) .articleOverview-acc-item > * > a {
		display: block;
		text-decoration: none;
		border-bottom: none;
	}
	:where(.legacy) .articleOverview-acc-item:hover .articleOverview-acc-title,
	:where(.legacy) .articleOverview-acc-item:focus .articleOverview-acc-title,
	:where(.legacy) .articleOverview-acc-item:active .articleOverview-acc-title {
		text-decoration: underline;
	}

	:where(.legacy) .highLightedLabel {
		background: #f4e9f1;
		color: #a90061;
		padding: 0.5rem 1rem;
		display: inline-block;
		font-style: italic;
	}

	:where(.legacy) .menuBarContainer {
		position: fixed;
		left: 0;
		top: 0;
		bottom: 0;
		transition: transform 400ms cubic-bezier(0.02, 0.64, 0.15, 1.1);
		z-index: 5;
	}
	@media (min-width: 55em) {
		:where(.legacy) .menuBarContainer {
			transform: translateX(0rem);
		}
	}
	@media (min-width: 70em) {
		:where(.legacy) .menuBarContainer {
			transform: none;
		}
	}
	@media (min-width: 150em) {
		:where(.legacy) .menuBarContainer {
			left: 50%;
			margin-left: -60rem;
		}
	}

	:where(.legacy) .is-menuBarContainer-opened {
		transform: translateX(100vw) translateX(-1rem);
	}
	@media (min-width: 35em) {
		:where(.legacy) .is-menuBarContainer-opened {
			transform: translateX(100vw) translateX(-1rem);
		}
	}
	@media (min-width: 55em) {
		:where(.legacy) .is-menuBarContainer-opened {
			transform: translateX(100vw) translateX(-1rem);
		}
	}
	@media (min-width: 70em) {
		:where(.legacy) .is-menuBarContainer-opened {
			transform: translateX(100vw) translateX(-1rem);
		}
	}
	@media (min-width: 81em) {
		:where(.legacy) .is-menuBarContainer-opened {
			transform: translateX(52rem);
		}
	}

	:where(.legacy) .is-menuOpened {
		overflow: hidden;
	}

	:where(.legacy) .container {
		width: 100%;
	}
	@media (min-width: 40em) {
		:where(.legacy) .container {
			min-height: 100vh;
		}
	}

	:where(.legacy) .menuBar {
		position: relative;
		height: 100%;
		width: 0rem;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		z-index: 3;
	}
	@media (min-width: 35em) {
		:where(.legacy) .menuBar {
			width: 0rem;
			padding-top: 0.05882rem;
			padding-bottom: 0.05882rem;
		}
	}
	@media (min-width: 55em) {
		:where(.legacy) .menuBar {
			width: 0rem;
			padding-top: 0.05556rem;
			padding-bottom: 0.05556rem;
		}
	}
	@media (min-width: 81em) {
		:where(.legacy) .menuBar {
			width: 0rem;
		}
	}
	:where(.legacy) .menuBar:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		width: 100%;
		transition: width 0.4s;
		background: rgba(143, 202, 231, 0.9);
		background: linear-gradient(
			rgba(143, 202, 231, 0.9),
			rgba(118, 210, 182, 0.9)
		);
	}
	:where(.legacy) .menuBar:after {
		content: '';
		position: absolute;
		display: block;
		background: #e2f0f7;
		top: 0;
		bottom: 0;
		right: 100%;
		width: 2000px;
		width: 100vw;
	}
	@media (min-width: 81em) {
		:where(.legacy) .menuBar:after {
			background: #f6f6f6;
		}
	}

	:where(.legacy) .menu-backgroundClose {
		position: fixed;
		display: block;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		z-index: 7;
		background: none;
		border: none;
		padding: 0;
		color: inherit;
		font-weight: inherit;
		line-height: inherit;
		text-align: left;
		text-transform: none;
		text-indent: 100%;
		white-space: nowrap;
		overflow: hidden;
		background-color: rgba(3, 48, 84, 0.6);
		width: 100%;
		cursor: default;
	}

	@media (max-width: 34.9375em) {
		:where(.legacy) .is-menuBar-retracted {
			background: rgba(255, 255, 255, 0.9);
			box-shadow: inset -1px 0 0 0 #dddddd;
		}
		:where(.legacy) .is-menuBar-retracted:before {
			content: '';
			width: 0.25rem;
		}
		:where(.legacy) .is-menuBar-retracted .menuBar-icon {
			fill: #2b95ca;
		}
	}

	:where(.legacy) .menuBar-button {
		width: 0rem;
		height: 0rem;
		margin-top: 1rem;
		margin-bottom: 1rem;
		padding: 0rem;
		display: block;
		position: relative;
		left: 0;
		overflow: hidden;
		background: none;
		border: none;
		color: #033054;
		transition:
			background-color,
			color 200ms ease-in-out;
	}
	@media (min-width: 35em) {
		:where(.legacy) .menuBar-button {
			width: 0rem;
			height: 0rem;
			padding: 0rem;
			margin-top: 0.58824rem;
			margin-bottom: 0.58824rem;
		}
	}
	@media (min-width: 55em) {
		:where(.legacy) .menuBar-button {
			width: 0rem;
			height: 0rem;
			margin-top: 0.66667rem;
			margin-bottom: 0.66667rem;
		}
	}
	@media (min-width: 81em) {
		:where(.legacy) .menuBar-button {
			width: 0rem;
			margin-top: 0.9rem;
			margin-bottom: 0.9rem;
		}
	}
	:where(.legacy) .menuBar-button:hover,
	:where(.legacy) .menuBar-button:focus,
	:where(.legacy) .menuBar-button:active {
		background-color: #e2f0f7;
		color: inherit;
	}
	:where(.legacy) .menuBar-button:hover .menuBar-icon,
	:where(.legacy) .menuBar-button:focus .menuBar-icon,
	:where(.legacy) .menuBar-button:active .menuBar-icon {
		fill: #000000;
	}
	@media (pointer: coarse) {
		:where(.legacy) .menuBar-button:hover {
			background-color: rgba(143, 202, 231, 0.9);
		}
	}
	:where(.legacy) .menuBar-button.is-opened {
		background-color: #e2f0f7;
		color: #000000;
	}
	:where(.legacy) .menuBar-button.is-opened .menuBar-icon {
		fill: #000000;
		transform: scale(1.1);
	}
	@media (max-height: 28.9375em) {
		:where(.legacy) .menuBar-button {
			margin-top: 0.225rem;
			margin-bottom: 0.225rem;
		}
	}

	@media (max-height: 28.9375em) and (min-width: 24em) and (max-width: 56.9375em) {
		:where(.legacy) .menuBar-button {
			height: 0rem;
			padding-top: 0rem;
			padding-bottom: 0rem;
		}
	}

	@media (max-height: 28.9375em) and (min-width: 55em) {
		:where(.legacy) .menuBar-button {
			height: 0rem;
			padding-top: 0rem;
			padding-bottom: 0rem;
		}
	}

	:where(.legacy) .menuBar-button--context.is-opened {
		background-color: #0077c0;
		color: #ffffff;
	}
	:where(.legacy) .menuBar-button--context.is-opened .menuBar-icon {
		fill: #ffffff;
	}

	:where(.legacy) .menuBar-button--toTop {
		background-color: rgba(226, 240, 247, 0.5);
		margin-bottom: 0;
	}

	:where(.legacy) .menuBar-button-inner {
		margin: 0 auto;
	}
	@media (min-width: 55em) {
		:where(.legacy) .menuBar-button-inner {
			display: flex;
			flex-direction: row;
			align-items: center;
		}
	}

	:where(.legacy) .menuBar-button-text {
		position: relative;
	}
	@media (max-width: 54.9375em) {
		:where(.legacy) .menuBar-button-text {
			position: absolute;
			left: -999em;
			top: 0;
			height: 1px;
			overflow: hidden;
		}
		:where(.legacy) .menuBar-button-text:focus {
			left: 1em;
			height: auto;
		}
	}
	@media (min-width: 55em) {
		:where(.legacy) .menuBar-button-text {
			text-align: right;
			order: 1;
			flex: 1 1 auto;
			padding-right: 0rem;
		}
		:where(.legacy) .menuBar-button-text {
			font-size: 0.9rem;
		}
	}
	@media (min-width: 70em) {
		:where(.legacy) .menuBar-button-text {
			padding-right: 0.5rem;
		}
	}

	:where(.legacy) .menuBar-icon {
		width: 0rem;
		height: 0rem;
		fill: #033054;
		transition: all 0.2s ease-in-out;
		opacity: 0.9;
	}
	@media (min-width: 35em) {
		:where(.legacy) .menuBar-icon {
			width: 0rem;
			height: 0rem;
		}
	}
	@media (min-width: 55em) {
		:where(.legacy) .menuBar-icon {
			order: 2;
			flex: 0 0 auto;
		}
	}
	:where(.legacy) .no-touch .menuBar-button:hover .menuBar-icon {
		fill: #000000;
		transform: scale(1.1);
	}

	:where(.legacy) .menuBar-overlayContent {
		position: absolute;
		bottom: 0;
		left: 100%;
		background: rgba(0, 119, 192, 0.98);
		color: #ffffff;
		fill: #ffffff;
		width: 25rem;
		max-height: calc(100vh - 2.01rem);
		transform-origin: 0 100%;
		transform-origin: 0 calc(100% - 1.58rem);
		min-height: 0rem;
		z-index: 2;
		max-width: calc(100vw - (0rem + 1rem));
	}
	@media (min-width: 35em) {
		:where(.legacy) .menuBar-overlayContent {
			max-width: calc(100vw - 0rem);
			min-height: 0rem;
		}
	}
	@media (min-width: 55em) {
		:where(.legacy) .menuBar-overlayContent {
			max-width: calc(100vw - 0rem);
		}
	}
	@media (min-width: 81em) {
		:where(.legacy) .menuBar-overlayContent {
			max-width: calc(100vw - 0rem);
		}
	}
	:where(.legacy) .menuBar-overlayContent .formField {
		display: block;
	}
	:where(.legacy) .menuBar-overlayContent .formField-label {
		color: #ffffff;
		text-align: left;
		width: 100%;
		margin: 0;
		padding-left: 1rem;
		padding-right: 3.5rem;
		padding-bottom: 0.5rem;
	}
	@media (max-width: 39.9375em) {
		:where(.legacy) .menuBar-overlayContent .formField-label {
			padding-left: 0;
			margin-bottom: 0.5rem;
		}
	}
	:where(.legacy) .menuBar-overlayContent input {
		color: #333333;
	}

	@media (max-height: 28.9375em) {
		:where(.legacy) .menuBar-overlayContent,
		:where(.legacy) .menuBar-overlayContent-scroll {
			max-height: calc(100vh - 3rem);
		}
	}

	@media (min-height: 29em) and (max-height: 39.9375em) {
		:where(.legacy) .menuBar-overlayContent,
		:where(.legacy) .menuBar-overlayContent-scroll {
			max-height: calc(100vh - 5rem);
		}
	}

	@media (min-height: 40em) and (max-height: 44.9375em) {
		:where(.legacy) .menuBar-overlayContent,
		:where(.legacy) .menuBar-overlayContent-scroll {
			max-height: calc(100vh - 6rem);
		}
	}

	:where(.legacy) .menuBar-overlayContent-scroll {
		padding: 0.55rem 1rem;
		overflow-y: auto;
	}

	:where(.legacy) .menuBar-overlayContent-wrapper {
		position: relative;
	}

	:where(.legacy) .menuBar-sideContent {
		bottom: 0;
		z-index: 10;
		background: #e2f0f7;
		min-width: 15rem;
		height: 100%;
		z-index: 4;
		position: absolute;
		top: 0;
		right: 100%;
		width: calc(100vw - (1rem));
	}
	@media (min-width: 35em) {
		:where(.legacy) .menuBar-sideContent {
			width: calc(100vw - 1rem);
		}
	}
	@media (min-width: 55em) {
		:where(.legacy) .menuBar-sideContent {
			width: calc(100vw - 1rem);
		}
	}
	@media (min-width: 70em) {
		:where(.legacy) .menuBar-sideContent {
			width: calc(100vw - 1rem);
		}
	}
	@media (min-width: 81em) {
		:where(.legacy) .menuBar-sideContent {
			width: 52rem;
		}
	}

	:where(.legacy) .menuBar-context {
		margin-top: auto;
		transform: translateY(0rem);
		transition: transform 0.2s;
	}
	@media (min-width: 35em) {
		:where(.legacy) .menuBar-context {
			transform: translateY(0rem);
		}
	}
	@media (min-width: 35em) and (max-height: 28.9375em) {
		:where(.legacy) .menuBar-context {
			transform: translateY(2rem);
		}
	}

	:where(.legacy) .menuBar-context--showBackToTop {
		transform: none;
	}

	:where(.legacy) .feedback-textField {
		margin-top: 1rem;
	}
	:where(.legacy) .feedback-textField .formField-label {
		position: absolute;
		left: -999em;
		top: 0;
		height: 1px;
		overflow: hidden;
	}
	:where(.legacy) .feedback-textField .formField-label:focus {
		left: 1em;
		height: auto;
	}
	:where(.legacy) .feedback-textField .formField-wrapper {
		display: block;
		width: calc(100% - 2 * var(--grid-item-margin-inline) - 0.01rem);
	}
	:where(.legacy) .feedback-textField .formField-optional {
		display: block;
		text-align: right;
	}
	:where(.legacy) .feedback-textField .formField-inputWrapper {
		display: block;
		width: 100%;
	}

	:where(.legacy) .feedback-textField label {
		padding-bottom: 0;
	}

	:where(.legacy) .feedback-radio > p {
		margin-bottom: 0;
	}

	:where(.legacy) .feedback-saved {
		padding-right: 3.5rem;
	}

	:where(.legacy) .feedback-saved-title {
		margin-top: 0;
		color: inherit;
	}

	:where(.legacy) .feedback-saved-text {
		margin: 0;
	}

	:where(.legacy) .feedback a:hover,
	:where(.legacy) .feedback a:focus,
	:where(.legacy) .feedback a:active {
		color: #ffffff;
		fill: #ffffff;
	}

	:where(.legacy) .feedback-textWrapper:not(:empty) + .feedback-contactmessage {
		margin-top: 1rem;
	}

	:where(.legacy) .search-header {
		flex: 0 0 auto;
		margin-top: 3.3rem;
	}
	@media (min-width: 40em) {
		:where(.legacy) .search-header {
			margin-top: 4.8rem;
		}
	}

	:where(.legacy) .search-header-title {
		margin-top: 0;
	}

	:where(.legacy) .search-gutter {
		margin-left: 3rem;
		margin-right: 3rem;
	}
	@media (max-width: 39.9375em) {
		:where(.legacy) .search-gutter {
			margin-left: 1rem;
			margin-right: 1rem;
		}
	}

	:where(.legacy) .search-interface.search-interface {
		position: relative;
		margin-bottom: 2rem;
		align-items: stretch;
		max-width: 40rem;
	}

	:where(.legacy) .search-input {
		padding-left: 1rem;
		border: 1px solid #033054;
	}
	:where(.legacy) .search-input::-webkit-search-cancel-button,
	:where(.legacy) .search-input::-webkit-search-decoration {
		appearance: auto;
	}
	:where(.legacy) .search-input::-ms-clear {
		display: block;
	}

	:where(.legacy) .search-clear-input {
		position: absolute;
		width: 1.5rem;
		height: 1.5rem;
		top: 11px;
		right: 18%;
	}
	@media (max-width: 39.9375em) {
		:where(.legacy) .search-clear-input {
			display: none;
		}
	}

	:where(.legacy) .search-button {
		width: 20%;
		padding: 10px 20px;
		background-color: #a90061;
		border: none;
		border-top: 3px solid #85004d;
		color: #ffffff;
		text-align: center;
		width: auto;
	}
	:where(.legacy) .search-button:hover,
	:where(.legacy) .search-button:focus,
	:where(.legacy) .search-button:active {
		background-color: #85004d;
		color: #ffffff;
	}
	@media (max-width: 49.9375em) {
		:where(.legacy) .search-button {
			width: auto;
		}
	}

	:where(.legacy) .search-button-icon {
		display: none;
	}

	:where(.legacy) .search-suggestions {
		background-color: #ffffff;
		border: 1px solid #033054;
		border-top: none;
		padding-left: 1rem;
	}

	:where(.legacy) .search-suggestions-list {
		padding-left: 0;
		margin-bottom: 0;
		margin-left: 0;
		list-style: none;
	}
	:where(.legacy) .search-suggestions-list li:before {
		content: '';
		position: relative;
		display: none;
	}

	:where(.legacy) .search-suggestion-link {
		text-decoration: none;
		border: none;
		display: flex;
	}

	:where(.legacy) .search-suggestion-icon {
		transform: rotate(45deg);
	}

	@media (max-width: 39.9375em) {
		:where(.legacy) .search-tabs {
			display: none;
		}
	}

	:where(.legacy) .search-tabs.tabs {
		margin-left: 3rem;
	}

	:where(.legacy) .search-tabs-item {
		background: none;
		border: none;
		padding: 0;
		color: inherit;
		font-weight: inherit;
		line-height: inherit;
		text-align: left;
		text-transform: none;
		width: 100%;
	}
	:where(.legacy) .search-tabs-item {
		line-height: 1.5;
	}

	:where(.legacy) .search-tab {
		background-color: #0077c0;
		color: #ffffff;
		transition: all 0.3s ease-in-out;
		display: flex;
	}
	:where(.legacy) .search-tab:hover {
		background-color: #00619c;
	}
	:where(.legacy) .search-tab .tab-link {
		padding: 0.3rem 1.4rem;
		transition: none;
	}
	:where(.legacy) .search-tab .tab-link:before {
		position: relative;
		background: none;
	}
	:where(.legacy) .search-tab .tab-link:hover {
		color: #ffffff;
		fill: #ffffff;
	}
	:where(.legacy) .search-tab .tab-link .tab-icon {
		margin-left: -0.8rem;
		margin-right: 1rem;
		font-size: 1.4rem;
	}

	:where(.legacy) .search-tab-text,
	:where(.legacy) .search-tab-subtext {
		display: block;
	}

	:where(.legacy) .search-tab-subtext {
		font-size: 0.75rem;
		font-weight: normal;
	}

	:where(.legacy) .search-tab-total {
		margin-left: 1.4rem;
	}

	:where(.legacy) .search-tab-active:hover {
		background-color: #ffffff;
	}

	:where(.legacy) .search-tab-active .tab-link {
		border-top: 3px solid #00619c;
		font-weight: bold;
		color: #033054;
		fill: #033054;
		background-color: #ffffff;
		border-bottom: none;
		padding-top: 0.48rem;
	}
	:where(.legacy) .search-tab-active .tab-link:hover,
	:where(.legacy) .search-tab-active .tab-link:focus,
	:where(.legacy) .search-tab-active .tab-link:active {
		color: #033054;
		fill: #033054;
	}
	:where(.legacy) .search-tab-active .tab-link:before {
		position: relative;
		background: none;
	}

	@media (max-width: 39.9375em) {
		:where(.legacy) .search-filters {
			background: white;
			border-bottom: 1px solid #e5e5e5;
		}
	}

	:where(.legacy) .search-filters-mobile {
		display: none;
		position: relative;
		margin-left: 1rem;
		margin-right: 1rem;
	}
	@media (max-width: 39.9375em) {
		:where(.legacy) .search-filters-mobile {
			display: flex;
		}
		:where(.legacy) .search-filters-mobile .filter-icon {
			width: 2rem;
			height: 1.5rem;
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			margin-top: auto;
			margin-bottom: auto;
		}
		:where(.legacy) .search-filters-mobile .select-icon {
			width: 2rem;
			height: 2rem;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			margin-top: auto;
			margin-bottom: auto;
			fill: #033054;
		}
	}

	:where(.legacy) .search-filters-mobile-select {
		width: 100%;
		font-weight: bold;
		color: #0077c0;
		padding-top: 0.8rem;
		padding-bottom: 0.8rem;
		padding-left: 2rem;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		background: none;
		border: none;
		position: relative;
	}

	:where(.legacy) .search-filters-mobile-option {
		color: #033054;
	}
	:where(.legacy) .search-filters-mobile-option:checked {
		font-weight: bold;
	}

	:where(.legacy) .search-results {
		background-color: #ffffff;
		padding-bottom: 3rem;
		position: relative;
		top: 0;
		flex: 1 1 0;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
	}

	:where(.legacy) .search-results-list {
		transition-property: opacity;
		transition-duration: 200ms;
		list-style: none;
		padding-top: 2rem;
		padding-left: 0;
	}
	:where(.legacy) .search-results-list li:before {
		content: '';
	}

	:where(.legacy) .search-results-message {
		padding-top: 2rem;
	}

	:where(.legacy) .search-results-item {
		display: flex;
	}

	:where(.legacy) .search-results-item-link {
		display: block;
		margin-left: 1rem;
		border-bottom: 0;
	}
	@media (max-width: 44.9375em) {
		:where(.legacy) .search-results-item-link {
			margin-left: 0;
		}
	}

	:where(.legacy) .search-results-item-icon {
		flex: 0 0 auto;
	}
	:where(.legacy) .search-results-item-icon svg {
		height: 2rem;
		width: 2rem;
		fill: #033054;
	}
	@media (max-width: 44.9375em) {
		:where(.legacy) .search-results-item-icon {
			display: none;
		}
	}

	:where(.legacy) .search-results-item-title {
		margin-top: 0.5rem;
	}

	:where(.legacy) .search-results-item-target {
		font-style: italic;
		display: block;
		color: #0077c0;
		word-break: break-all;
		word-break: break-word;
	}

	:where(.legacy) .search-results-more-button {
		margin-left: 5.6rem;
		margin-bottom: 2rem;
		background-color: #a90061;
		border: none;
		border-top: 3px solid #85004d;
	}
	:where(.legacy) .search-results-more-button:hover {
		background-color: #85004d;
	}
	@media (max-width: 44.9375em) {
		:where(.legacy) .search-results-more-button {
			margin-left: 1rem;
		}
	}

	:where(.legacy) .search-results-more-button-icon {
		-webkit-animation-name: rotate;
		animation-name: rotate;
		-webkit-animation-duration: 1.5s;
		animation-duration: 1.5s;
		-webkit-animation-timing-function: ease-in-out;
		animation-timing-function: ease-in-out;
		-webkit-animation-iteration-count: infinite;
		animation-iteration-count: infinite;
		fill: #ffffff;
		margin-left: 0.5em;
	}

	:where(.legacy) .search-results-amount {
		margin-top: 1rem;
		min-height: 3rem;
	}
	@media (max-width: 44.9375em) {
		:where(.legacy) .search-results-amount {
			display: none;
		}
	}

	:where(.legacy) .search-results-amount-number {
		color: #033054;
	}

	:where(.legacy) .search-results-query {
		font-weight: bold;
	}
	:where(.legacy) .search-results-query:before {
		content: '“';
		font-weight: normal;
	}
	:where(.legacy) .search-results-query:after {
		content: '”';
		font-weight: normal;
	}

	:where(.legacy) .search-facetSelectorIcon {
		width: 1rem;
		height: 1rem;
		display: none;
	}
	@media (max-width: 44.9375em) {
		:where(.legacy) .search-tab-active .search-facetSelectorIcon {
			display: block;
		}
	}

	:where(.legacy) .search-error {
		margin-bottom: 1rem;
	}

	:where(.legacy) .search-errorIcon {
		width: 1.5rem;
		height: 1.5rem;
		fill: #d52b1e;
	}

	:where(.legacy) .search-errorText {
		margin-left: 0.2em;
		margin-top: 0.1em;
		color: #d52b1e;
		font-weight: bold;
	}
}
